/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import {
    Button,
    DisplayHtmlContent,
    Icon,
    ModalActions,
    StyledModal,
} from 'sarsaparilla';
import { errorForFetchingModalSection } from '../utilities/errorMessages';
import { getLocationDisplayName } from '../utilities/locations';
import {
    CAMPGROUND,
    PERMIT,
    TICKET_FACILITY,
    TIMED_ENTRY,
    VEHICLE_PERMIT,
} from '../utilities/locationConstants';
import LineLoader from '../components/LineLoader';
import { runFacilityModalFetches, setShouldOpenModal } from '../actions/facilityInfo';

const propTypes = {
    buttonText: PropTypes.string,
    selectedRole: PropTypes.object,
    fetchedLocation: PropTypes.object,
    runFacilityModalFetches: PropTypes.func,
    hideButton: PropTypes.bool,
    openOnMount: PropTypes.bool,
    openOnUpdateLocation: PropTypes.bool,
    setShouldOpenModal: PropTypes.func,
    shouldOpenFacilityInfoModal: PropTypes.bool,
    hideMargin: PropTypes.bool,

    barMandatory: PropTypes.shape({
        result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        success: PropTypes.bool,
    }),
    barExternal: PropTypes.shape({
        result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        success: PropTypes.bool,
    }),
    barOptional: PropTypes.shape({
        result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        success: PropTypes.bool,
    }),
    barAdditional: PropTypes.shape({
        result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        success: PropTypes.bool,
    }),
    barSeasons: PropTypes.shape({
        result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        success: PropTypes.bool,
    }),
    barRules: PropTypes.shape({
        result: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        success: PropTypes.bool,
    }),
};

const linkMap = {
    [CAMPGROUND]: {
        facility: `${process.env.SITE_ROOT}/camping/campgrounds/:location_id`,
        factType: 'camping',
    },
    [PERMIT]: {
        facility: `${process.env.SITE_ROOT}/permits/:location_id`,
        factType: 'permits',
    },
    [TICKET_FACILITY]: {
        facility: `${process.env.SITE_ROOT}/ticket/facility/:location_id`,
        factType: 'ticket',
    },
    [TIMED_ENTRY]: {
        facility: `${process.env.SITE_ROOT}/timed-entry/facility/:location_id`,
        factType: 'ticket',
    },
    [VEHICLE_PERMIT]: {
        facility: `${process.env.SITE_ROOT}/vehiclepermits/:location_id`,
        factType: 'vehiclepermits',
    },
};

const isValidLocation = (loc) => {
    return loc && Object.keys(linkMap).indexOf(loc.location_type) > -1;
};

export class FacilityInfoModal extends React.Component {
    static defaultProps = {
        buttonText: 'Call Center Notes',
    };

    static propTypes = propTypes;

    constructor(props) {
        super(props);

        const selectedLocation = this.props.selectedRole
            ? this.props.selectedRole.location
            : null;
        this.state = {
            isModalOpen: false,
            location: this.props.fetchedLocation || selectedLocation,
        };

        this.openNotesModal = this.openNotesModal.bind(this);
        this.closeNotesModal = this.closeNotesModal.bind(this);
    }

    componentDidMount() {
        if (this.props.openOnMount) {
            this.setState({ isModalOpen: true });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const oldSelect = prevState.location ? prevState.location : {};
        const newSelect = nextProps.selectedRole ? nextProps.selectedRole.location : {};

        if (
            isValidLocation(newSelect) &&
            oldSelect.location_id !== newSelect.location_id
        ) {
            nextProps.runFacilityModalFetches(newSelect);
            return { location: newSelect };
        }

        const newLoc = nextProps.fetchedLocation;
        const locationChanged = oldSelect !== newLoc;

        if (isValidLocation(newLoc)) {
            const newState = { location: newLoc };
            if (nextProps.openOnUpdateLocation && locationChanged) {
                newState.isModalOpen = true;
            }

            return newState;
        }

        return null;
    }

    getRenderRules() {
        const location = this.state.location;
        const map = linkMap[location.location_type];
        const linkData = isValidLocation(this.state.location)
            ? {
                  result: [
                      {
                          title: 'Public Facing Facility Page',
                          url: map.facility.replace(':location_id', location.location_id),
                      },
                  ],
                  success: true,
              }
            : [];

        const rules = [
            {
                title: 'Facility Links',
                icon: <Icon iconName="link" />,
                iconFill: 'ia-icon-black',
                data: linkData,
                renderList: (list) => {
                    return (
                        <div>
                            {list.map((link, i) => (
                                <div key={`link-item_${i}`}>
                                    <a
                                        href={link.url}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {link.title}
                                    </a>
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                title: 'Mandatory Call Center Read',
                icon: <Icon iconName="warning" />,
                iconFill: 'ia-icon-yellow',
                data: this.props.barMandatory,
                renderList: (list) => (
                    <ul>
                        {list.map((note, i) => (
                            <li key={`man-note-item_${i}`}>
                                <DisplayHtmlContent html={note.note} />
                            </li>
                        ))}
                    </ul>
                ),
            },
            {
                title: 'External Banners',
                icon: <Icon iconName="chat" />,
                iconFill: 'ia-icon-darkblue',
                data: this.props.barExternal,
                renderList: (list) => {
                    return (
                        <div>
                            {list.map((alert, i) => (
                                <div
                                    key={`banner-item_${i}`}
                                    className={
                                        alert.alert_level === 'WARNING'
                                            ? 'ia-block rec-notification-warning'
                                            : 'ia-block rec-notification-info'
                                    }
                                >
                                    <DisplayHtmlContent html={alert.body} />
                                    {alert.notes !== undefined &&
                                        alert.notes.length > 0 && (
                                            <div>
                                                <i>NOTE: {alert.notes}</i>
                                            </div>
                                        )}
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                title: 'Call Center Notes',
                icon: <Icon iconName="announcement" />,
                iconFill: 'ia-icon-blue',
                data: this.props.barOptional,
                renderList: (list) => {
                    const sortedNotes = sortBy(list, (n) => n.seq_num);
                    return (
                        <ul>
                            {sortedNotes.map((note, i) => (
                                <li key={`opt-note-item_${i}`}>
                                    <DisplayHtmlContent html={note.note} />
                                </li>
                            ))}
                        </ul>
                    );
                },
            },
            {
                title: 'Additional Information',
                icon: <Icon iconName="info" />,
                iconFill: 'ia-icon-green',
                data: this.props.barAdditional,
                renderList: (list) => {
                    if (
                        this.state.location.location_type === TICKET_FACILITY ||
                        this.state.location.location_type === TIMED_ENTRY
                    ) {
                        return list.map((obj, i) => (
                            <DisplayHtmlContent
                                key={`additional-item_${i}`}
                                html={obj.description}
                            />
                        ));
                    }

                    if (this.state.location.location_type === CAMPGROUND) {
                        return (
                            <ul>
                                {list.map((obj, i) => (
                                    <li key={`additional-item_${i}`}>
                                        <DisplayHtmlContent html={obj.notice_text} />
                                    </li>
                                ))}
                            </ul>
                        );
                    }

                    return (
                        <ul>
                            {list.map((additional, i) => (
                                <li key={`additional-item_${i}`}>{additional}</li>
                            ))}
                        </ul>
                    );
                },
            },
        ];

        if (this.state.location.location_type === CAMPGROUND) {
            rules.push({
                title: 'Facility Seasons',
                icon: null,
                data: this.props.barSeasons,
                renderList: (list) => (
                    <table>
                        <thead>
                            <tr>
                                <th>Season</th>
                                <th>Dates</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((season, i) => (
                                <tr key={`season-row_${i}`}>
                                    <td>{season.season_type}</td>
                                    <td>
                                        {`${moment(season.season_start).format('MMMM DD, YYYY')} - ${moment(
                                            season.season_end
                                        ).format('MMMM DD, YYYY')}`}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ),
            });
        }

        return rules;
    }

    openNotesModal = () => {
        this.props.setShouldOpenModal(true);
        this.setState({ isModalOpen: true });
    };

    closeNotesModal = () => {
        this.props.setShouldOpenModal(false);
        this.setState({ isModalOpen: false });
    };

    renderRule(rule) {
        if (!rule.data.success) {
            return (
                <div className="ia-facmodal-loading">
                    <LineLoader
                        errorText={
                            rule.data.success === false
                                ? errorForFetchingModalSection(rule.title)
                                : null
                        }
                    />
                </div>
            );
        }

        if (
            !rule.data ||
            (Array.isArray(rule?.data?.result) && rule?.data?.result?.length === 0)
        ) {
            return <div className="ia-facmodal-empty">No Results.</div>;
        }

        return rule.renderList(rule.data.result);
    }

    render() {
        if (!isValidLocation(this.state.location)) {
            return null;
        }

        const rules = this.getRenderRules();
        const modalHeader = this.props.selectedRole
            ? `${getLocationDisplayName(this.state.location)} Facility Details`
            : 'Facility Details';

        return (
            <div>
                <Button
                    appearance="tertiary"
                    className={`${this.props.hideMargin ? '' : 'mt-2'} facility-info-modal-button ia-facmodal-open-button ${this.props.hideButton ? 'hidden' : ''}`}
                    onClick={this.openNotesModal}
                >
                    {this.props.buttonText}
                </Button>
                <StyledModal
                    isOpen={
                        this.state.isModalOpen && this.props.shouldOpenFacilityInfoModal
                    }
                    heading={modalHeader}
                    size="lg"
                    onRequestClose={this.closeNotesModal}
                >
                    <div className="ia-facmodal">
                        {rules.map((rule, i) => (
                            <div className="ia-facmodal-section" key={i}>
                                <div className="ia-facmodal-title">
                                    {rule.icon !== undefined && (
                                        <span className={`icon-holder ${rule.iconFill}`}>
                                            {rule.icon}
                                        </span>
                                    )}
                                    <span>{rule.title}</span>
                                </div>
                                <div className="ia-facmodal-body">
                                    {rule.data !== undefined &&
                                        Array.isArray(rule.data.result) &&
                                        this.renderRule(rule)}
                                    {rule.data !== undefined &&
                                        !Array.isArray(rule.data.result) && (
                                            <DisplayHtmlContent html={rule.data.result} />
                                        )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <ModalActions>
                        <Button onClick={this.closeNotesModal}>Close</Button>
                    </ModalActions>
                </StyledModal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        shouldOpenFacilityInfoModal: state.facilityInfo?.shouldOpenModal,
        selectedRole: state.selectedLocationRole.role,
        fetchedLocation: state.facilityInfo.location,

        barMandatory: state.facilityInfo.modalInfo.mandatory,
        barExternal: state.facilityInfo.modalInfo.external,
        barOptional: state.facilityInfo.modalInfo.optional,
        barAdditional: state.facilityInfo.modalInfo.additional,
        barSeasons: state.facilityInfo.modalInfo.seasons,
        barRules: state.facilityInfo.modalInfo.rules,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShouldOpenModal: (shouldOpen) => dispatch(setShouldOpenModal(shouldOpen)),
        runFacilityModalFetches: (location) =>
            dispatch(runFacilityModalFetches(location)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityInfoModal);
