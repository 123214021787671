/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    Button,
    ButtonGroup,
    ExternalLink,
    FlexCol,
    FlexRow,
    Text,
    useFlags,
} from 'sarsaparilla';

type LoginMethodsProps = {
    handleHubButton: () => void;
    handleOidcButton: () => void;
};

export default function LoginMethods({
    handleHubButton,
    handleOidcButton,
}: LoginMethodsProps) {
    const { iaEnableMfa, loginGovButtonEnabled } = useFlags();

    return (
        <div className="login-btn-wrap">
            <FlexRow>
                <FlexCol>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            onClick={handleHubButton}
                            appearance="tertiary"
                            type="button"
                            id="hub-method"
                            gaTrackingId="819335029303"
                            className="mb-2"
                        >
                            Use Hub Login
                        </Button>
                    </ButtonGroup>
                </FlexCol>
                <FlexCol>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            onClick={handleOidcButton}
                            appearance="tertiary"
                            type="button"
                            id="oidc-method"
                            gaTrackingId="819335029303"
                            className="mb-2"
                            isDisabled={!loginGovButtonEnabled}
                        >
                            Use Login.gov
                        </Button>
                    </ButtonGroup>
                </FlexCol>
            </FlexRow>
            <FlexRow>
                <FlexCol className="mb-3">
                    <Text className="left">
                        Use your Hub account email
                        {iaEnableMfa
                            ? ', password, and an MFA TOTP device (e.g. Google authentication, MS authenticator, Duo)'
                            : ' and password'}{' '}
                        to sign in.
                    </Text>
                </FlexCol>
                <FlexCol className="mb-3">
                    {loginGovButtonEnabled && (
                        <Text className="left">
                            Use the same username and password to log into all
                            participating .gov websites. You will need your{' '}
                            <ExternalLink
                                url="https://login.gov"
                                gaTrackingId="819335029303"
                            >
                                Login.gov
                            </ExternalLink>{' '}
                            account email, password, and a secondary authentication
                            method; PIV, CAC, LincPass, or secure key device to sign in.
                            Your{' '}
                            <ExternalLink
                                url="https://login.gov"
                                gaTrackingId="819335029303"
                            >
                                Login.gov
                            </ExternalLink>{' '}
                            account email must match Hub account email for first time
                            linking.
                        </Text>
                    )}
                    {!loginGovButtonEnabled && (
                        <Text className="left">Login.gov support is coming soon.</Text>
                    )}
                </FlexCol>
            </FlexRow>
        </div>
    );
}
