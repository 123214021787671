/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Alert, TYPES } from 'sarsaparilla';
import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchRangerAppTokens } from '../actions/rangerAppToken';
import PageHeader from '../components/PageHeader';
import RangerTokenPage from '../components/rangerAppToken/RangerTokenPage';
import SiteWrapper from './SiteWrapper';

const propTypes = {
    history: TYPES.HISTORY,
};

const tabDict = Object.freeze({
    active: 0,
    expired: 1,
});

const defaultTokens = { active: [], expired: [] };

function RangerTokenManagementPage({ history }) {
    const [tabIndex, setTabIndex] = React.useState(tabDict.active);
    const [message, setMessage] = React.useState(null);
    const [tokens, setTokens] = React.useState(defaultTokens);
    const dispatch = useDispatch();

    const fetchTokens = React.useCallback(
        (id, type = 'LOCATION') => {
            try {
                dispatch(fetchRangerAppTokens({ type, location_id: id })).then((data) => {
                    const { active_tokens, expired_tokens } = data;
                    const expiredUpdatedTokens = expired_tokens?.map((item) => {
                        const created_by_name = item.created_by_name
                            .split(', ')
                            .reverse()
                            .join(' ');
                        return { ...item, created_by_name };
                    }, []);

                    setMessage(null);
                    setTokens({ expired: expiredUpdatedTokens, active: active_tokens });
                });
            } catch (error) {
                setMessage({ type: 'error', text: error.message });
                setTokens(defaultTokens);
            }
        },
        [dispatch]
    );

    const firstLoad = React.useCallback(() => {
        fetchTokens(null, 'CREATOR');
    }, [fetchTokens]);

    React.useEffect(() => {
        firstLoad();
    }, [firstLoad]);

    const tabs = [
        {
            title: 'Active',
            onClick: () => {
                setTabIndex(tabDict.active);
                history.push('/internal/account/device-tokens');
            },
        },
        {
            title: 'Devices with Expired Tokens',
            onClick: () => {
                setTabIndex(tabDict.expired);
                history.push('/internal/account/device-tokens');
            },
        },
    ];

    const renderTokenTab = () => (
        <RangerTokenPage
            tokens={tokens}
            setTokens={setTokens}
            fetchTokens={fetchTokens}
            setMessage={setMessage}
            expired={tabIndex === tabDict.expired}
        />
    );

    return (
        <SiteWrapper>
            <div className="page-title">
                <PageHeader
                    title="Token Management"
                    tabs={tabs}
                    selectedTabIndex={tabIndex}
                />
            </div>
            <div id="TokenManagement" className="page-content wrapper">
                {message && message.type === 'success' && (
                    <Alert
                        onCloseButtonClick={() => setMessage(null)}
                        type="success"
                        showCloseButton
                    >
                        {message.text}
                    </Alert>
                )}

                {message && message.type === 'error' && (
                    <Alert
                        onCloseButtonClick={() => setMessage(null)}
                        type="error"
                        showCloseButton
                    >
                        {message.text}
                    </Alert>
                )}

                {renderTokenTab()}
            </div>
        </SiteWrapper>
    );
}

RangerTokenManagementPage.propTypes = propTypes;

export default RangerTokenManagementPage;
