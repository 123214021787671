/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import classNames from 'classnames';
import { Heading, Button, HelmetWrapperInternal, Icon } from 'sarsaparilla';
import { HorizontalNav, HorizontalNavLink, HorizontalNavLinkExternal } from 'shared-ui';

interface SubTabBase {
    title?: string;
    label?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
}

interface SubTabInternal extends SubTabBase {
    to: string; // for react-router intra-service links
    href?: never;
}

interface SubTabExternal extends SubTabBase {
    to?: never;
    href: string; // for external links outside the service
}

type SubTab = SubTabInternal | SubTabExternal;

interface Tab {
    title: string;
    ariaLabel: string;
    onClick: (i: number) => void;
    selectedSubTabIndex: number;
    subTabs: SubTab[];
}

interface PageHeaderProps {
    children: React.ReactNode;
    title: string;
    selectedTabIndex: number;
    navBack?: string;
    breadcrumbs?: object[];
    tabs: Tab[];
}

export function PageHeader({
    children,
    title,
    selectedTabIndex,
    navBack,
    breadcrumbs,
    tabs,
}: PageHeaderProps) {
    const navigate = useNavigate();

    const onNavBackClick = () => {
        const url = navBack;

        if (!url) {
            navigate(-1);
        } else {
            navigate(url);
        }
    };

    const onTabClick = (index: number) => {
        const tab = tabs[index];
        if (tab.onClick) {
            tab.onClick(index);
        }
    };

    const createTab = (tab: Tab, index: number) => {
        const key = `page-header-tab-${index}`;

        const isSelectedTab = index === selectedTabIndex;
        const classes = classNames({
            'rec-tab': true,
            'rec-tab-active': isSelectedTab,
        });

        return (
            <li key={key} className={classes}>
                <button
                    type="button"
                    className="rec-tab-link"
                    aria-label={tab.ariaLabel}
                    onClick={() => onTabClick(index)}
                >
                    {tab.title}
                </button>
            </li>
        );
    };

    const tabsUI = () => {
        const tabCheck = tabs && selectedTabIndex ? tabs[selectedTabIndex] : null;
        const classes = classNames({
            'rec-tab-list': true,
            'ia-rec-tab-list': true,
            'ia-rec-tab-list-with-subs': tabCheck && tabCheck.subTabs,
        });
        if (tabs && tabs.length > 0) {
            return (
                <div className={classes}>
                    <ul className="rec-tabs site-nav-tabs">{tabs.map(createTab)}</ul>
                </div>
            );
        }

        return null;
    };

    const subTabsUI = () => {
        if (tabs && tabs.length > selectedTabIndex) {
            const selectedTab = tabs[selectedTabIndex];

            if (selectedTab.subTabs) {
                return (
                    <div className="mt-3 pl-4">
                        <HorizontalNav aria-label="Sub Tabs">
                            {selectedTab.subTabs.map((subTab, index) => {
                                if (subTab.to) {
                                    return (
                                        <HorizontalNavLink key={index} to={subTab.to} end>
                                            {subTab.title}
                                        </HorizontalNavLink>
                                    );
                                }
                                return (
                                    <HorizontalNavLinkExternal
                                        key={index}
                                        href={subTab.href}
                                    >
                                        {subTab.title}
                                    </HorizontalNavLinkExternal>
                                );
                            })}
                        </HorizontalNav>
                    </div>
                );
            }
        }

        return null;
    };

    const renderBreadcrumbs = () => {
        if (!breadcrumbs) {
            return null;
        }

        const crumbs: JSX.Element[] = [];
        breadcrumbs.forEach((crumb: SubTabBase, i: number) => {
            if (crumb.onClick) {
                crumbs.push(
                    <Button
                        size="xs"
                        appearance="link"
                        className="button-as-anchor"
                        onClick={crumb.onClick}
                        key={`crumb_${i}`}
                    >
                        {crumb.label}
                    </Button>
                );
            } else {
                crumbs.push(<span key={`crumb_${i}`}>{crumb.label}</span>);
            }

            if (i + 1 !== breadcrumbs.length) {
                crumbs.push(<span className="breadcrumb-spacer" key={`spacer_${i}`} />);
            }
        });

        return <div className="page-breadcrumbs">{crumbs}</div>;
    };

    const renderHeaderTitle = () => {
        let headerToRender;
        if (navBack) {
            headerToRender = (
                <Button
                    appearance="link"
                    className="page-header-nav-back mr-2"
                    screenReaderTextBefore="Go Back to Previous Page"
                    onClick={onNavBackClick}
                    iconBeforeElement={<Icon iconName="arrow-back" />}
                />
            );
        }

        return (
            <div className="page-header-back-title-grid my-5">
                {headerToRender}
                <Heading headingLevel={1} appearance="h3">
                    {title}
                </Heading>
            </div>
        );
    };

    return (
        <div className="page-header-wrapper">
            <HelmetWrapperInternal title={title} />
            <div className="page-header">
                <div className="page-header-left">
                    {renderBreadcrumbs()}
                    {renderHeaderTitle()}
                    {tabsUI()}
                </div>
                {children}
            </div>
            {subTabsUI()}
        </div>
    );
}

export default PageHeader;
