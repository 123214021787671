/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { InternalSecurityUtils } from 'ui-internal-account/index';
import axios from 'axios';
import * as globals from 'ui-internal-account/src/constants/globals';
import { Location, Role, User } from '../constants/types';
import { locationIsUnder, locationsAreEqual } from './locations';
import * as lc from './locationConstants';

// importing & exporting these constants that were in the wrong file, but still have lots of references to them in this file
// TODO: hunt down and replace any usages of these constants with the locationConstants ones

type RoleTypes = {
    PMO: string;
    BAH_ADMIN: string;
    ATR: string;
    FACILITY_MANAGER: string;
    FACILITY_OPERATOR: string;
    ATTENDANT: string;
    HOST: string;
    COOPERATOR: string;
    USGS_ROLE: string;
    FINANCE_ADMIN: string;
    FINANCE_USER: string;
    CSR1: string;
    CSR2: string;
    CSR_SUPERVISOR: string;
    SUPER_USER: string;
    BAH_FINANCE: string;
    PASS_OPERATOR: string;
    REPORT_ONLY: string;
};

type LeafTypes = {
    VEHICLE_PERMIT: string;
    PERMIT: string;
    TICKET_FACILITY: string;
    TIMED_ENTRY: string;
    CAMPGROUND: string;
    ACTIVITY_PASS: string;
    VENUE_RESERVATIONS: string;
};

type RoleTypesMap = {
    ROLE_PMO: string;
    ROLE_BAH_ADMIN: string;
    ROLE_ATR: string;
    ROLE_FACILITY_MANAGER: string;
    ROLE_FACILITY_OPERATOR: string;
    ROLE_ATTENDANT: string;
    ROLE_HOST: string;
    ROLE_COOPERATOR: string;
    ROLE_USGS: string;
    ROLE_FINANCE_ADMIN: string;
    ROLE_FINANCE_USER: string;
    ROLE_CSR: string;
    ROLE_CSR2: string;
    ROLE_CSR_SUPERVISOR: string;
    ROLE_SUPER_USER: string;
    ROLE_SYSTEM_USER: string;
    ROLE_BAH_FINANCE: string;
    ROLE_PASS_OPERATOR: string;
    ROLE_REPORT_ONLY: string;
    ROLE_PARK_RANGER: string;
};

type LeafTypesMap = {
    PERMIT: string;
    TICKET_FACILITY: string;
    CAMPGROUND: string;
    ACTIVITY_PASS: string;
    VENUE_RESERVATIONS: string;
};

type DropdownOption = {
    value: string;
    label: string;
    order?: number;
};

export const VEHICLE_PERMIT: string = lc.VEHICLE_PERMIT;
export const PERMIT: string = lc.PERMIT;
export const TICKET_FACILITY: string = lc.TICKET_FACILITY;
export const TIMED_ENTRY: string = lc.TIMED_ENTRY;
export const CAMPGROUND: string = lc.CAMPGROUND;

export const PMO = 'ROLE_PMO';
export const BAH_ADMIN = 'ROLE_BAH_ADMIN';
export const ATR = 'ROLE_ATR';
export const FACILITY_MANAGER = 'ROLE_FACILITY_MANAGER';
export const FACILITY_OPERATOR = 'ROLE_FACILITY_OPERATOR';
export const ATTENDANT = 'ROLE_ATTENDANT';
export const HOST = 'ROLE_HOST';
export const COOPERATOR = 'ROLE_COOPERATOR';
export const USGS_ROLE = 'ROLE_USGS';
export const FINANCE_ADMIN = 'ROLE_FINANCE_ADMIN';
export const FINANCE_USER = 'ROLE_FINANCE_USER';
export const CSR2 = 'ROLE_CSR2';
export const CSR1 = 'ROLE_CSR';
export const SUPER_USER = 'ROLE_SUPER_USER';
export const SYSTEM_USER = 'ROLE_SYSTEM_USER';
export const BAH_FINANCE = 'ROLE_BAH_FINANCE';
export const PASS_OPERATOR = 'ROLE_PASS_OPERATOR';
export const REPORT_ONLY = 'ROLE_REPORT_ONLY';
export const CSR_SUPERVISOR = 'ROLE_CSR_SUPERVISOR';
export const ROLE_PARK_RANGER = 'ROLE_PARK_RANGER';
export const INVALID = 'ROLE_INVALID';

export const ALL_ROLES: string[] = [
    PMO,
    BAH_ADMIN,
    ATR,
    FACILITY_MANAGER,
    FACILITY_OPERATOR,
    ATTENDANT,
    HOST,
    COOPERATOR,
    USGS_ROLE,
    FINANCE_ADMIN,
    FINANCE_USER,
    CSR1,
    BAH_FINANCE,
    PASS_OPERATOR,
    ROLE_PARK_RANGER,
];
export const ALL_ROLES_ORDERED: string[] = [
    SUPER_USER,
    PMO,
    BAH_ADMIN,
    CSR_SUPERVISOR,
    ATR,
    FACILITY_MANAGER,
    FACILITY_OPERATOR,
    ATTENDANT,
    HOST,
    PASS_OPERATOR,
    COOPERATOR,
    FINANCE_ADMIN,
    FINANCE_USER,
    CSR2,
    CSR1,
    USGS_ROLE,
    BAH_FINANCE,
    REPORT_ONLY,
    ROLE_PARK_RANGER,
];
export const COMM_EMAIL_ROLES: string[] = [
    FACILITY_MANAGER,
    FACILITY_OPERATOR,
    ATTENDANT,
    HOST,
];
export const LOCATION_LEAF_TYPES: string[] = [PERMIT, TICKET_FACILITY, CAMPGROUND];

export const AllRoleTypesObj: RoleTypes = {
    PMO,
    BAH_ADMIN,
    ATR,
    FACILITY_MANAGER,
    FACILITY_OPERATOR,
    ATTENDANT,
    HOST,
    COOPERATOR,
    USGS_ROLE,
    FINANCE_ADMIN,
    FINANCE_USER,
    CSR1,
    CSR2,
    CSR_SUPERVISOR,
    SUPER_USER,
    BAH_FINANCE,
    PASS_OPERATOR,
    REPORT_ONLY,
};

export const AllLeafTypesObj: LeafTypes = {
    VEHICLE_PERMIT,
    PERMIT,
    TICKET_FACILITY,
    TIMED_ENTRY,
    CAMPGROUND,
    ACTIVITY_PASS: lc.ACTIVITY_PASS,
    VENUE_RESERVATIONS: lc.VENUE_RESERVATIONS,
};

export const roleTypeToDisplayMap: RoleTypesMap = {
    ROLE_PMO: 'PMO',
    ROLE_BAH_ADMIN: 'BAH Admin',
    ROLE_ATR: 'APM',
    ROLE_FACILITY_MANAGER: 'Facility Management',
    ROLE_FACILITY_OPERATOR: 'Facility Operations',
    ROLE_ATTENDANT: 'Attendant',
    ROLE_HOST: 'Host',
    ROLE_COOPERATOR: 'Cooperator',
    ROLE_USGS: 'USGS',
    ROLE_FINANCE_ADMIN: 'Finance Admin',
    ROLE_FINANCE_USER: 'Finance User',
    ROLE_CSR: 'CSR',
    ROLE_CSR2: 'CSR2',
    ROLE_CSR_SUPERVISOR: 'CSR Supervisor',
    ROLE_SUPER_USER: 'Super User',
    ROLE_SYSTEM_USER: 'System User',
    ROLE_BAH_FINANCE: 'Booz Allen Finance',
    ROLE_PASS_OPERATOR: 'Pass Operations',
    ROLE_REPORT_ONLY: 'Report Only',
    ROLE_PARK_RANGER: 'Park Ranger',
};

export const leafTypeToDisplayMap: LeafTypesMap = {
    PERMIT: 'Permit',
    TICKET_FACILITY: 'Ticket Facility',
    CAMPGROUND: 'Campground',
    ACTIVITY_PASS: 'Activity Pass',
    VENUE_RESERVATIONS: 'Venue Reservations',
};

export const PMOorHigherRoleForComms: { [r: string]: boolean } = {
    [SUPER_USER]: true,
    [ATR]: true,
    [BAH_ADMIN]: true,
    [PMO]: true,
    [CSR_SUPERVISOR]: true,
    [FACILITY_MANAGER]: true,
};

export const isRolePMOorHigher = (role: Role): boolean =>
    !!PMOorHigherRoleForComms[role?.role_type];

export const userIsPMOorHigher = (user: User): boolean =>
    user?.roles?.some((role: Role) => isRolePMOorHigher(role));

export const optionForExistingRole = (
    options: DropdownOption[],
    assignedLocation: Location,
    existingRoles: Role[],
    isEditingSelf: boolean
): DropdownOption | string => {
    let result: DropdownOption | string = '';

    // Check each existing role to find one at this location
    for (const role of existingRoles) {
        if (
            role.location.location_id === assignedLocation.location_id &&
            role.location.location_type === assignedLocation.location_type
        ) {
            // Check each option to find the one that equals this role type
            for (const option of options) {
                if (option.value === role.role_type) {
                    // This is the option for assigned role at this location
                    result = option;
                }
            }

            if (isEditingSelf && !result && role.role_type === ATR) {
                result = { value: ATR, label: 'Level 1 - APM' };
            }

            break;
        }
    }

    return result;
};

export const roleIsUnder = (childRole: Role, parentRole: Role): boolean => {
    if (parentRole.role_type === SUPER_USER) {
        return true;
    }

    switch (childRole.role_type) {
        case CSR1:
            return parentRole.role_type === CSR1;
        case CSR2:
            return parentRole.role_type === CSR2;
        case PMO:
            // a PMO has authority over other PMOs.
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case CSR_SUPERVISOR:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case ATR:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case FINANCE_ADMIN:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case FINANCE_USER:
            return (
                parentRole.role_type === FINANCE_ADMIN ||
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case FACILITY_MANAGER:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2 ||
                parentRole.role_type === FACILITY_MANAGER
            );
        case FACILITY_OPERATOR:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === FACILITY_MANAGER ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case ATTENDANT:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === FACILITY_MANAGER ||
                parentRole.role_type === FACILITY_OPERATOR ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case HOST:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === FACILITY_MANAGER ||
                parentRole.role_type === FACILITY_OPERATOR ||
                parentRole.role_type === ATTENDANT ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case COOPERATOR:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === FACILITY_MANAGER ||
                parentRole.role_type === FACILITY_OPERATOR ||
                parentRole.role_type === ATTENDANT ||
                parentRole.role_type === CSR1 ||
                parentRole.role_type === CSR2
            );
        case BAH_FINANCE:
            // Can only be created by Super User, so can only be edited by them.
            // just being explicit.
            return false;
        case PASS_OPERATOR:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === FACILITY_MANAGER ||
                parentRole.role_type === CSR2
            );
        case USGS_ROLE:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === CSR2 ||
                parentRole.role_type === SUPER_USER
            );
        case REPORT_ONLY:
            return (
                parentRole.role_type === PMO ||
                parentRole.role_type === BAH_ADMIN ||
                parentRole.role_type === ATR ||
                parentRole.role_type === FACILITY_MANAGER ||
                parentRole.role_type === CSR2
            );
        default:
            return false;
    }
};

export const findRole = (loadedRoles: Role[], location: Location): Role | null =>
    loadedRoles.filter(
        (role: Role): boolean => role.location_id === location.location_id
    )[0] || null;

export const findHighestRoleType = (roles: Role[]): string => {
    let knownHighestRoleType = INVALID;
    let knownLowestRoleIndex = 100000;

    for (const role of roles) {
        const roleIndex = ALL_ROLES_ORDERED.indexOf(role.role_type);
        if (roleIndex === -1) {
            // role is not in the list
            continue;
        }
        if (roleIndex < knownLowestRoleIndex) {
            knownHighestRoleType = role.role_type;
            knownLowestRoleIndex = roleIndex;
        }
    }

    return knownHighestRoleType;
};

export const highestRoleAtLocation = (managerRoles: Role[], location: Location): Role => {
    const managingRolesForLocation = [];

    for (const managerRole of managerRoles) {
        if (
            locationIsUnder(location, managerRole.location) ||
            locationsAreEqual(managerRole.location, location)
        ) {
            managingRolesForLocation.push(managerRole);
        }
    }

    if (managingRolesForLocation.length === 0) {
        return managerRoles[0];
    }

    let highestRole = managingRolesForLocation[0];
    for (const managingRole of managingRolesForLocation) {
        if (
            managingRole.role_type === highestRole.role_type &&
            locationIsUnder(highestRole.location, managingRole.location)
        ) {
            highestRole = managingRole;
        } else {
            const isUnder = roleIsUnder(highestRole, managingRole);
            if (isUnder) {
                highestRole = managingRole;
            }
        }
    }

    return highestRole;
};

export const displayStringForRoleType = (roleType: string): string => {
    // @ts-ignore
    let displayString: any = roleTypeToDisplayMap[roleType];

    if (!displayString) {
        displayString = 'N/A';
    }

    return displayString;
};

export const displayLevelAndRoleString = (role: string): string => {
    switch (role) {
        case SUPER_USER:
            return 'Super User';
        case PMO:
            return displayStringForRoleType(PMO);
        case ATR:
            return 'Level 1 - APM';
        case FACILITY_MANAGER:
            return 'Level 2 - Facility Management';
        case FACILITY_OPERATOR:
            return 'Level 3 - Facility Operations';
        case ATTENDANT:
            return 'Level 4 - Attendant';
        case HOST:
            return 'Level 5 - Host';
        default:
            return '';
    }
};

export const roleDropdownOptionsForLocation = async (
    location: any,
    userId: any
): Promise<DropdownOption[]> => {
    const url = `${globals.API_URL}/role-types/location/${encodeURIComponent(location.location_id)}?user_id=${encodeURIComponent(userId)}`;
    const { data } = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: InternalSecurityUtils.getAuthHeader(),
        },
    });

    return data.role_types;
};

export const doesUserHaveRoleType = (user: User, roleType: string): boolean => {
    for (const userRole of user.roles) {
        if (userRole.role_type === roleType) {
            return true;
        }
    }

    return false;
};

export const userCanEditUser = (actor: User, user: User): boolean => {
    let canEdit = false;

    if (user && user.roles && actor && actor.roles) {
        for (const actorRole of actor.roles) {
            if (actorRole.role_type === SUPER_USER) {
                return doesUserHaveRoleType(actor, SUPER_USER);
            }

            if (actorRole.role_type === CSR1) {
                return false;
            }

            for (const userRole of user.roles) {
                const locationUnder: boolean = locationIsUnder(
                    userRole.location,
                    actorRole.location
                );
                const locationEqual: boolean = locationsAreEqual(
                    userRole.location,
                    actorRole.location
                );
                const locCheck: boolean =
                    locationUnder ||
                    (locationEqual &&
                        userRole.role_type !== PMO &&
                        userRole.role_type !== BAH_ADMIN &&
                        userRole.role_type !== ATR);

                if (roleIsUnder(userRole, actorRole) && locCheck) {
                    canEdit = true;
                }
            }
        }
    }

    return canEdit;
};

export const simplifyRolesLocationObject = (roles: Role[]): Role[] => {
    if (roles) {
        return roles.map((role: Role) => {
            return {
                ...role,
                location: {
                    location_id: role.location.location_id,
                    location_type: role.location.location_type,
                    has_lottery: role.location.has_lottery,
                    has_cooperators: role.location.has_cooperators,
                },
            };
        });
    }
    return roles;
};
