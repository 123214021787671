/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    Box,
    Button,
    ButtonGroup,
    FlexCol,
    FlexRow,
    FormattedDateTime,
    Icon,
    Select,
    SelectOption,
    Text,
    TextFieldStateless,
    useFlags,
} from 'sarsaparilla';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactTableWithLayout } from 'shared-ui';
import {
    generateQrCodeImageRangerApp,
    generateQrCodeTokenRangerApp,
    setQrCodeTokenRangerApp,
    setRangerAppTokenUsername,
} from '../../actions/rangerAppToken';
import { highestRoleAtLocation, isRolePMOorHigher } from '../../utilities/roles';
import LocationPicker from '../LocationPicker';
import ViewTokenDetailsModal from './ViewTokenDetailsModal';
import ViewQrCodeTokenModal from './ViewQrCodeTokenModal';
import LocationDisplay from '../tableUtil/LocationDisplay';
import TextHighlight from '../tableUtil/TextHighlight';
import ConfirmExpirationTokenModal from './ConfirmExpirationTokenModal';
import CreateRangerTokenModal from './CreateRangerTokenModal';

const propTypes = {
    tokens: PropTypes.object,
    setTokens: PropTypes.func,
    fetchTokens: PropTypes.func,
    setMessage: PropTypes.func,
    expired: PropTypes.bool,
};

const filterTypes = Object.freeze({
    myTokens: 'VIEW_MY_TOKENS',
    byLocation: 'VIEW_BY_LOCATION',
    allLocations: 'VIEW_ALL_LOCATIONS',
});

function renderActionsField(expired, onClickDetails, onClickQrCode) {
    return (
        <ButtonGroup isStretchedToFit>
            <Button
                className="view-token-modal"
                appearance="tertiary"
                size="xxs"
                onClick={onClickDetails}
            >
                Details
            </Button>
            {!expired && (
                <Button size="xxs" onClick={onClickQrCode}>
                    Show QR Code
                </Button>
            )}
        </ButtonGroup>
    );
}

function renderCreatedField(row) {
    return (
        <div className="actions-container justify-content-between">
            <FormattedDateTime date={new Date(row.original.created_at)} format={'L'} />
        </div>
    );
}

function renderExpirationField(expired, row, onClick) {
    return (
        <div className="actions-container justify-content-between">
            <FormattedDateTime date={new Date(row.original.expire_at)} format={'L'} />
            {!expired && (
                <Button appearance="tertiary" size="xxs" onClick={onClick}>
                    Force Expiration
                </Button>
            )}
        </div>
    );
}

function renderLocationsField(row, setTokenDetailsModal) {
    if (row.original?.roles?.length === 0) return null;

    const locationNames = Object.values(row.original.roles).map(
        (item) => item.location_name
    );
    return (
        <LocationDisplay
            locationNames={locationNames}
            onMoreClick={() =>
                setTokenDetailsModal({ show: true, details: row.original })
            }
        />
    );
}

function renderTokenIDField(searchTerm, body, onClick) {
    return (
        <Button
            appearance="link"
            iconAfterElement={<Icon iconName="clipboard" size="md" />}
            onClick={onClick}
        >
            <TextHighlight searchTerm={searchTerm} body={body} />
        </Button>
    );
}

function renderDeviceField(text, deviceID) {
    return <TextHighlight searchTerm={text} body={deviceID} />;
}

function renderNicknameField(
    expired,
    row,
    editDeviceNickname,
    onChange,
    onKeyDown,
    onClick
) {
    if (editDeviceNickname[row.original.token_id]) {
        return (
            <div className="actions-container">
                <TextFieldStateless
                    label=""
                    isLabelVisible={false}
                    isDisabled={expired}
                    id={`device_nickname_${row.original.token_id}`}
                    value={row.original.device_nickname}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
                <Button
                    className="edit-button"
                    size="xs"
                    iconAfterElement={<Icon iconName="save" />}
                    onClick={onClick}
                />
            </div>
        );
    }

    return (
        <div className="actions-container justify-content-between">
            {row.original.device_nickname}
            {!expired && (
                <Button
                    className="edit-nickname-btn"
                    appearance="link"
                    iconAfterElement={
                        <Icon
                            iconName="edit"
                            size={row.original.device_nickname ? 'md' : 'sm'}
                        />
                    }
                    onClick={onClick}
                >
                    {!row.original.device_nickname ? 'Add nickname' : ''}
                </Button>
            )}
        </div>
    );
}

export default function RangerTokenPage({
    tokens,
    setTokens,
    fetchTokens,
    setMessage,
    expired,
}) {
    const { iaTokenManagementLocationRestricted } = useFlags();
    const [editDeviceNickname, setEditDeviceNickname] = React.useState({});
    const [filters, setFilters] = React.useState({
        location: null,
        text: '',
        type: filterTypes.myTokens,
    });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isExpireModalOpen, setIsExpireModalOpen] = React.useState({
        show: false,
        token: null,
    });
    const [tokenDetailsModal, setTokenDetailsModal] = React.useState({
        show: false,
        details: null,
    });
    const [tokenQrCodeModal, setTokenQrCodeModal] = React.useState({
        show: false,
        token: null,
        qrCode: null,
    });
    const loggedInUser = useSelector((state) => state?.login?.user);
    const managerRoles = useSelector((state) => state?.selectedLocationRole?.role);
    const location = useSelector((state) => state?.selectedLocationRole?.role?.location);
    const dispatch = useDispatch();

    const setActiveTokens = (list) => setTokens({ ...tokens, active: list });
    const setExpiredTokens = (list) => setTokens({ ...tokens, expired: list });

    const tokenKey = expired ? 'expired' : 'active';

    const reloadTokens = () => {
        if (filters.type === filterTypes.myTokens) {
            fetchTokens(null, 'CREATOR');
        } else if (filters.location) {
            fetchTokens(filters.location.location_id);
        }
    };

    const addToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                setMessage({
                    type: 'success',
                    text: 'Token ID copied to clipboard successfully',
                });
            },
            () => {
                setMessage({ type: 'error', text: 'Failed to copy clipboard' });
            }
        );
    };

    const filterData = () => {
        const textFilter = (input) => input?.includes(filters.text);

        return (
            tokens[tokenKey]?.filter(
                (t) =>
                    textFilter(t.token_id) ||
                    textFilter(t.created_by_name) ||
                    textFilter(t.device_id) ||
                    textFilter(t.device_nickname)
            ) ?? []
        );
    };

    const renderFilters = () => {
        const filterOptions = [
            <SelectOption key="my-tokens-option" value={filterTypes.myTokens}>
                View My Tokens
            </SelectOption>,
            <SelectOption key="location-tokens-option" value={filterTypes.byLocation}>
                Choose Location
            </SelectOption>,
        ];

        return (
            <FlexRow className="token-list-table-header-wrap">
                <FlexCol sm={6} md={2}>
                    <FlexRow alignItems="center">
                        <FlexCol xs={6} sm="auto">
                            <Select
                                label="Filter"
                                value={filters.type}
                                onChange={(e) => {
                                    const newFilter = { type: e.target.value };
                                    if (
                                        filters.type === filterTypes.byLocation &&
                                        e.target.value !== filterTypes.byLocation
                                    ) {
                                        newFilter.location = null;
                                    }
                                    setFilters({ ...filters, ...newFilter });
                                    if (newFilter.type === filterTypes.myTokens) {
                                        fetchTokens(null, 'CREATOR');
                                    }
                                }}
                                isLabelVisible={false}
                                id="filter-select"
                            >
                                {filterOptions}
                            </Select>
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
                {filters.type === filterTypes.byLocation && (
                    <FlexCol sm={6} md={2}>
                        <FlexRow alignItems="center">
                            <FlexCol xs={6} sm="auto">
                                <LocationPicker
                                    selectedLocation={filters.location}
                                    placeholder="Pick a Location"
                                    locationSelect={(loc) => {
                                        setFilters({
                                            ...filters,
                                            location: loc,
                                        });
                                        fetchTokens(loc.location_id);
                                    }}
                                />
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                )}
                <FlexCol sm={12} md={5} xl={6}>
                    <FlexRow alignItems="center">
                        <FlexCol xs={12} sm="auto">
                            <TextFieldStateless
                                label=""
                                isLabelVisible={false}
                                placeholder="Search by Token ID, Device nickname, Device ID and Created By"
                                onChange={(e) =>
                                    setFilters({ ...filters, text: e.target.value })
                                }
                                handleClearButtonClick={() =>
                                    setFilters({ ...filters, text: '' })
                                }
                                hasClearButton
                                iconElement={<Icon iconName="search" />}
                                id="filter-text-search"
                                value={filters.text}
                            />
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
            </FlexRow>
        );
    };

    const toggleEditNickname = (tokenID, toggle = true) =>
        setEditDeviceNickname({ ...editDeviceNickname, [tokenID]: toggle });

    const rangerTokenTable = () => {
        const classes = classNames({
            'token-table': true,
            'has-data': tokens[tokenKey]?.length > 0,
        });

        const highestRole = highestRoleAtLocation(loggedInUser?.roles, location);

        let device_nickname = '';
        const nicknameOnChange = (e) => {
            device_nickname = e.target.value;
        };
        const nicknameOnKeyDown = (e, row) => {
            if (e.key === 'Escape') toggleEditNickname(row.original.token_id, false);
        };
        const nicknameOpenOnClick = (row) =>
            toggleEditNickname(row.original.token_id, true);
        const nicknameSaveOnClick = (row) => {
            try {
                dispatch(
                    setRangerAppTokenUsername({
                        token_id: row.original.token_id,
                        device_nickname,
                    })
                );

                const updatedTokens = tokens[tokenKey].map((t) => {
                    if (t.token_id === row.original.token_id) {
                        return { ...t, device_nickname };
                    }
                    return t;
                });

                setTokens({ ...tokens, [tokenKey]: updatedTokens });
                toggleEditNickname(row.original.token_id, false);
                setMessage({
                    type: 'success',
                    text: 'Token nickname updated successfully',
                });
            } catch (error) {
                setMessage({ type: 'error', text: error.message });
            }
        };

        const viewQrCode = async (row) => {
            try {
                const response = await generateQrCodeImageRangerApp(
                    row.original.qr_code_id,
                    row.original.qr_code
                );
                if (response) {
                    setTokenQrCodeModal({
                        show: true,
                        token: row.original,
                        qrCode: response,
                    });
                }
            } catch (error) {
                const { data } = error.response;
                setMessage({ type: 'error', text: data.message });
            }
        };

        const generateQrCode = async (token) => {
            try {
                const { item } = await generateQrCodeTokenRangerApp(token.token_id);
                if (item) {
                    await setQrCodeTokenRangerApp(token.token_id, item.code, item.id);

                    const response = await generateQrCodeImageRangerApp(
                        item.id,
                        item.code
                    );
                    if (response) {
                        const updatedTokens = tokens[tokenKey].map((t) => {
                            if (t.token_id === token.token_id) {
                                return { ...t, qr_code: item.code, qr_code_id: item.id };
                            }
                            return t;
                        });

                        setTokens({ ...tokens, [tokenKey]: updatedTokens });

                        setTokenQrCodeModal({
                            show: true,
                            token,
                            qrCode: response,
                        });
                    }
                }
            } catch (error) {
                const { data } = error.response;
                setMessage({ type: 'error', text: data.message });
            }
        };

        const tableColumns = () => {
            return [
                {
                    Header: 'DEVICE ID',
                    accessor: 'device_id',
                    sortable: true,
                    Cell: ({ row }) =>
                        renderDeviceField(
                            filters?.text,
                            row.original.device_id ?? 'Not Assigned'
                        ),
                    minWidth: 200,
                    maxWidth: 340,
                    resizable: true,
                },
                {
                    Header: 'NICKNAME',
                    accessor: 'device_nickname',
                    sortable: true,
                    resizable: true,
                    Cell: ({ row }) =>
                        renderNicknameField(
                            expired,
                            row,
                            editDeviceNickname,
                            nicknameOnChange,
                            nicknameOnKeyDown,
                            editDeviceNickname[row.original.token_id]
                                ? () => nicknameSaveOnClick(row)
                                : () => nicknameOpenOnClick(row)
                        ),
                    minWidth: 200,
                    maxWidth: 300,
                },
                {
                    Header: 'TOKEN ID',
                    accessor: 'token_id',
                    sortable: true,
                    Cell: ({ row }) =>
                        renderTokenIDField(
                            filters?.text,
                            row.original.token_id
                                ? `Token ${row.original.token_id.substring(0, 20)}...`
                                : '',
                            () => addToClipboard(row.original.token_id)
                        ),
                    minWidth: 280,
                    resizable: true,
                },
                {
                    Header: 'LOCATIONS',
                    sortable: false,
                    Cell: ({ row }) => renderLocationsField(row, setTokenDetailsModal),
                    minWidth: 200,
                    maxWidth: 340,
                    resizable: true,
                },
                {
                    Header: 'CREATED DATE',
                    id: 'created_at',
                    sortable: true,
                    accessor: 'created_at',
                    Cell: ({ row }) => renderCreatedField(row),
                    minWidth: 160,
                    maxWidth: 240,
                },
                {
                    Header: 'EXPIRATION DATE',
                    id: 'expire_at',
                    sortable: true,
                    accessor: 'expire_at',
                    Cell: ({ row }) =>
                        renderExpirationField(expired, row, () =>
                            setIsExpireModalOpen({
                                show: true,
                                token: row.original,
                            })
                        ),
                    minWidth: 240,
                    maxWidth: 240,
                },
                {
                    Header: 'Actions',
                    minWidth: 280,
                    maxWidth: 280,
                    sortable: false,
                    Cell: ({ row }) =>
                        renderActionsField(
                            expired,
                            () =>
                                setTokenDetailsModal({
                                    show: true,
                                    details: row.original,
                                }),
                            () => viewQrCode(row)
                        ),
                },
            ];
        };

        const closeModal = () => setIsModalOpen(false);
        const closeExpireModal = () => setIsExpireModalOpen({ show: false, token: null });

        const issueNewTokenModalAction = async (type, msg, payload) => {
            closeModal();

            if (type === 'success') {
                if (expired) setExpiredTokens([payload, ...tokens.expired]);
                else setActiveTokens([payload, ...tokens.active]);

                setMessage({ type: 'success', text: msg });
            } else if (type === 'error') {
                setMessage({ type: 'error', text: msg });
            }
        };

        const issueExpireTokenModalAction = (type, msg) => {
            const updatedTokens = tokens[tokenKey].filter(
                (token) => token.token_id !== isExpireModalOpen.token.token_id
            );
            if (expired) setExpiredTokens(updatedTokens);
            else setActiveTokens(updatedTokens);

            setMessage({ type, text: msg });
            closeExpireModal();
        };

        const rangerAppUsersGuideLink = `${process.env.SN_KB_INT_URL}?sys_kb_id=f1aa601f471fd650b53ac3df336d431d&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=ef15fa17976f1e906fd8bba6f053afe0`;

        return (
            <div className={classes}>
                <div className="token-list-table-wrap">
                    <Box textAlign="left" className="mb-1">
                        {iaTokenManagementLocationRestricted && (
                            <>
                                <Text size="md">
                                    <b>Attention:</b> This feature is currently only
                                    available at{' '}
                                    <i>
                                        Washington Monument Tours, Yosemite National Park
                                        Timed Entry, and The National Christmas Tree
                                        Lighting Opening Ceremony.
                                    </i>
                                </Text>
                                <Text size="md">
                                    <b>Attention:</b> By using tokens, you will be able to
                                    authenticate a device to access the Ranger App{' '}
                                    <i>only for scanning passes and tickets</i>. All other
                                    Ranger App functionality requires a regular username /
                                    password login.
                                </Text>
                            </>
                        )}
                        {!iaTokenManagementLocationRestricted && (
                            <Text size="md">
                                You can learn more about this feature by reviewing the{' '}
                                <a
                                    href={rangerAppUsersGuideLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Ranger App Authentication
                                </a>{' '}
                                article in the Help Center.
                            </Text>
                        )}
                    </Box>
                    {renderFilters()}
                    <ReactTableWithLayout
                        noDataText="No token found"
                        columns={tableColumns()}
                        defaultSorted={[{ id: 'created_at', desc: true }]}
                        data={filterData()}
                        isStriped
                    />
                </div>
                {isRolePMOorHigher(highestRole) && !expired && (
                    <ButtonGroup className="ranger-token-buttons">
                        <Button
                            iconBeforeElement={<Icon iconName="add-circle-outline" />}
                            id="add-ranger-token"
                            onClick={() => setIsModalOpen(true)}
                            appearance="secondary"
                        >
                            Issue New Token
                        </Button>
                    </ButtonGroup>
                )}
                {!expired && isModalOpen && (
                    <CreateRangerTokenModal
                        loggedInUser={loggedInUser}
                        managerRole={managerRoles}
                        modalAction={issueNewTokenModalAction}
                        closeModal={closeModal}
                        generateQrCode={generateQrCode}
                        reloadTokens={reloadTokens}
                    />
                )}
                {!expired && isExpireModalOpen.show && (
                    <ConfirmExpirationTokenModal
                        token={isExpireModalOpen.token}
                        modalAction={issueExpireTokenModalAction}
                        closeModal={closeExpireModal}
                        reloadTokens={reloadTokens}
                    />
                )}
            </div>
        );
    };

    return (
        <div id="RangerTokenPage" className="page-content wrapper">
            {rangerTokenTable()}
            {tokenDetailsModal.show && (
                <ViewTokenDetailsModal
                    token={tokenDetailsModal.details}
                    closeModal={() =>
                        setTokenDetailsModal({ show: false, details: null })
                    }
                />
            )}
            {!expired && tokenQrCodeModal.show && (
                <ViewQrCodeTokenModal
                    token={tokenQrCodeModal.token}
                    qrCode={tokenQrCodeModal.qrCode}
                    closeModal={() =>
                        setTokenQrCodeModal({ show: false, token: null, qrCode: null })
                    }
                />
            )}
        </div>
    );
}

RangerTokenPage.propTypes = propTypes;
