/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button, Heading, Icon, Text } from 'sarsaparilla';

type MfaEnrollmentBackupCodesProps = {
    onDownload: () => void;
    onClose: () => void;
};

function MfaEnrollmentBackupCodes({
    onDownload,
    onClose,
}: MfaEnrollmentBackupCodesProps) {
    return (
        <>
            <Heading
                headingLevel={1}
                appearance="h4"
                className="mb-3"
                headingAlign="center"
            >
                Download Backup Codes
            </Heading>

            <Text size="md" className="mb-3">
                If you lose access to your agency-mandated multi-factor authenticator
                application, you can still gain access to your account with pre-generated
                backup codes. Save these somewhere safe to reduce the risk of losing
                access to your account. <b>Note</b>, your backup codes will be downloaded
                as a .txt file.
            </Text>

            <div className="my-5 center">
                <Button
                    appearance="secondary"
                    onClick={onDownload}
                    size="lg"
                    iconBeforeElement={<Icon iconName="file-download" />}
                    gaTrackingId="112941841278"
                >
                    Download Backup Codes
                </Button>
            </div>

            <div className="center">
                <Button onClick={onClose}>Continue</Button>
            </div>

            <Text size="md" className="mt-2">
                You may continue to your account sign in by clicking the above button if
                you do not wish to download your codes at this time.
            </Text>
        </>
    );
}

export default MfaEnrollmentBackupCodes;
