/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { getLocalTimeZone, now, toZoned, ZonedDateTime } from '@internationalized/date';
import { isEmpty, isNil } from 'lodash';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    DatePicker,
    DividerNew as Divider,
    FeatureFlag,
    FlexCol,
    FlexRow,
    Heading,
    Icon,
    Inline,
    Label,
    ModalActions,
    phoneValidator,
    Spacer,
    StyledModal,
    Switch,
    Text,
    TextArea,
    TextFieldStateless,
    TYPES,
} from 'sarsaparilla';
import { AnchorPageSection, AnchorPageWithSidebarLayout } from 'shared-ui';
import { AppDispatch } from '../../../dev/store';
import { cancelEmailChange } from '../../actions/confirmEmail';
import { doneLoading, startLoading } from '../../actions/loading';
import { createMfaBackupCodes } from '../../actions/mfa';
import { addNoteForExistingUser } from '../../actions/notes';
import { AvailableOidcProviders, AvailableOidcProvidersLabels } from '../../actions/oidc';
import {
    deleteRole,
    editingUser,
    editUserRoles,
    emailChangeCancelled,
    forceLogout,
    forceResetPassword,
    loginDotGovUnlinked,
    requestEmailChange,
    resendConfirmation,
    resetConfirmStatus,
    resetMFA,
    resetResetStatus,
    setDefaultRole,
    setMFABypass,
    unlinkLoginDotGovTarget,
    updateConcAssignment as updateConcAssignmentAction,
    updateEffectiveDates,
    updatePersonalInfo,
    userMFAEnrollmentStatus,
} from '../../actions/updateUser';
import { updateUserStatus } from '../../actions/userManagement';
import { Concessionaire, PhoneNumber, Role, User, UserNote } from '../../constants/types';
import { errorForUpdateUser } from '../../utilities/errorMessages';
import { ATR, BAH_ADMIN, PMO } from '../../utilities/roles';
import { TargetedEvent } from '../../utilities/tools';
import {
    canUserControlMFA,
    canUserDoMFABypass,
    canUserForceLogout,
    canUserForceResetPassword,
    countDifferences,
    isUserConcessionaire,
    isUserConcessionaireManager,
    isUserCsrSupervisor,
} from '../../utilities/user';
import ActionErrorDisplay from '../ActionErrorDisplay';
import Toast from '../Toast';
import EffectiveDates from '../UserEffectiveDates';
import UserNotes from '../UserNotes';
import UserStatusToggle from '../UserStatusToggle';
import AdditionalInformation from './AdditionalInformation';
import MfaStatus, { MfaStatusProps } from './MfaStatus';
import NewEmailAddressModal from './NewEmailAddressModal';
import PasswordSection from './PasswordSection';
import PhoneNumbers from './PhoneNumbers';
import RolesTable from './RolesTable';

type UpdateUserPanelProps = {
    match: TYPES.MATCH;
    location: TYPES.LOCATION;
};

type GlobalState = {
    updateUser: {
        accountToSave: User;
        accountSaved: User;
        error: Error | null;
        reloadAccount: boolean;
        readOnly: boolean;
        assignedConcessionaires: Concessionaire[] | null;
        userIdEnabledPasswordReset: string;
        errorEnablingPasswordReset: string;
        requestConfirmResponse: {
            ttl: string;
            error: string | null;
        };
    };
    notes: {
        notes: UserNote[];
    };
    login: {
        user: User | null;
    };
    fetchManagedConcessionaires: {
        assignedConcessionaire: Concessionaire;
    };
    userManagement: {
        updateUserStatusErrorMessage: string;
        updateUserStatusSuccessful: boolean;
    };
};

type StateUser = Partial<User> & {
    new_email?: string;
};

type State = {
    phoneNumbers: PhoneNumber[];
    loggedInConcessionaire: boolean;
    loggedInConcessionaireManager: boolean;
    isCsrSupervisor: boolean;
    isValidPhoneNumber: boolean;
    canControlMFA: boolean;
    canDoMFABypass: boolean;
    canForceLogout: boolean;
    canForceResetPassword: boolean;
    isMFAModalOpen: boolean;
    isMFABypassModalOpen: boolean;
    isNewMFABackupCodesModalOpen: boolean;
    isNewEmailModalOpen: boolean;
    statusToggleModalIsOpen: boolean;
    edited: boolean;
    bypassMFAJustification: string;
    bypassMFAEndDate: ZonedDateTime | null | undefined;
    titleMFAModal: string;
    bodyMFAModal: React.ReactElement;
    actionMFAModal: (props: any) => void;
    user: StateUser;
    changesCounter: number;
    modalSize: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'full';
};

const createPhoneNumberListFromUser = (accountToSave: User) => {
    if (!accountToSave?.phone_numbers) return [];

    return accountToSave.phone_numbers.map((phone: PhoneNumber) => {
        const number = phone.phone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(/\s+/g, '');
        return {
            ...phone,
            phone: `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`,
        };
    });
};

export default function UpdateUserPanel({ match, location }: UpdateUserPanelProps) {
    const dmTimezone = getLocalTimeZone();

    const {
        accountToSave: editingAccount,
        accountSaved: updatedAccount,
        error,
        reloadAccount,
        readOnly,
        assignedConcessionaires: concessionaires,
        userIdEnabledPasswordReset,
        errorEnablingPasswordReset,
        requestConfirmResponse,
    } = useSelector((st: GlobalState) => st.updateUser);
    const concessionaire = useSelector(
        (st: GlobalState) => st.fetchManagedConcessionaires.assignedConcessionaire
    );
    const { updateUserStatusErrorMessage, updateUserStatusSuccessful } = useSelector(
        (st: GlobalState) => st.userManagement
    );
    const loggedInUser = useSelector((st: GlobalState) => {
        return st.login ? st.login.user : null;
    });
    const userNotes = useSelector((st: GlobalState) => {
        return st.notes.notes;
    });

    const [userMFAStatus, setUserMFAStatus] = React.useState<MfaStatusProps[]>([]);
    const [state, setState] = React.useState<State>({
        phoneNumbers: createPhoneNumberListFromUser(editingAccount),
        loggedInConcessionaire: false,
        loggedInConcessionaireManager: false,
        isCsrSupervisor: false,
        isValidPhoneNumber: true,
        canControlMFA: false,
        canDoMFABypass: false,
        canForceLogout: false,
        canForceResetPassword: false,
        isMFAModalOpen: false,
        isMFABypassModalOpen: false,
        isNewMFABackupCodesModalOpen: false,
        isNewEmailModalOpen: false,
        statusToggleModalIsOpen: false,
        edited: false,
        bypassMFAJustification: '',
        bypassMFAEndDate: null,
        titleMFAModal: '',
        bodyMFAModal: <></>,
        actionMFAModal: () => {},
        user: {
            user_id: '',
            first_name: '',
            last_name: '',
            email: '',
            new_email: '',
            locked: false,
            can_run_transactions: false,
            should_receive_sms: false,
            enforce_effective_dates: false,
            effective_start_at: undefined,
            effective_end_at: undefined,
            roles: [],
        },
        changesCounter: 0,
        modalSize: 'sm',
    });
    const [spinningModal, setSpinningModal] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [typeToast, setTypeToast] = React.useState('success');
    const [msgToast, setMsgToast] = React.useState('');
    const [initialPhoneNumbers, setInitialPhoneNumbers] = React.useState<PhoneNumber[]>(
        []
    );
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const notEnrolledMFA =
        userMFAEnrollmentStatus(editingAccount)[0].userMFAStatus === 'Not Enrolled';

    const updateStateUser = (user: StateUser) => {
        setState((prevState: State) => {
            const newUser = {
                ...prevState.user,
                ...user,
            };
            return {
                ...prevState,
                user: newUser,
            };
        });
    };

    const setInitialValues = React.useCallback(
        (user: Partial<User>) => {
            if (!user) return;
            const account = {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                new_email: '',
                locked: user.locked,
                can_run_transactions: user.can_run_transactions,
                should_receive_sms: user.should_receive_sms,
                enforce_effective_dates: editingAccount?.enforce_effective_dates,
                effective_start_at: editingAccount?.effective_start_at,
                effective_end_at: editingAccount?.effective_end_at,
                roles: user.roles,
                user_id: user.user_id,
            };
            updateStateUser(account);
        },
        [editingAccount]
    );

    const initializeValues = () => {
        const phoneNumbers = createPhoneNumberListFromUser(editingAccount);
        const ums = userMFAEnrollmentStatus(editingAccount, userNotes);
        setUserMFAStatus(ums);

        const canControlMFA = canUserControlMFA(loggedInUser);
        const canDoMFABypass = canUserDoMFABypass(loggedInUser);
        const canForceLogout = canUserForceLogout(loggedInUser);
        const canForceResetPassword = canUserForceResetPassword(loggedInUser);

        const isCsrSupervisor = isUserCsrSupervisor(loggedInUser);
        const isConcessionaire = isUserConcessionaire(loggedInUser);
        const isConcessionaireManager = isUserConcessionaireManager(loggedInUser);

        setState((prevState) => ({
            ...prevState,
            phoneNumbers,
            loggedInConcessionaire: isConcessionaire,
            loggedInConcessionaireManager: isConcessionaireManager,
            canControlMFA,
            canDoMFABypass,
            canForceLogout,
            canForceResetPassword,
            isCsrSupervisor,
            edited: false,
            changesCounter: 0,
            isValidPhoneNumber: true,
        }));

        setInitialPhoneNumbers(phoneNumbers);
        setInitialValues(editingAccount);

        if (location && location.pathname.includes('/internal/account/edit-user')) {
            if (match?.params.userId) {
                dispatch(editingUser(match.params.userId));
            }
            dispatch(resetResetStatus());
            dispatch(resetConfirmStatus());
        }
    };

    const resetState = () =>
        setState((prevState) => ({ ...prevState, edited: false, changesCounter: 0 }));

    React.useEffect(() => {
        initializeValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const ums = userMFAEnrollmentStatus(editingAccount, userNotes);
        setUserMFAStatus(ums);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNotes]);

    React.useEffect(() => {
        if (reloadAccount && editingAccount) {
            setInitialValues(editingAccount);
            const phoneNumbers = createPhoneNumberListFromUser(editingAccount);
            const ums = userMFAEnrollmentStatus(editingAccount, userNotes);
            setInitialPhoneNumbers(phoneNumbers);
            setUserMFAStatus(ums);
            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
            }));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadAccount, editingAccount]);

    React.useEffect(() => {
        if (updatedAccount) {
            setInitialValues(updatedAccount);
            const phoneNumbers = createPhoneNumberListFromUser(updatedAccount);
            const ums = userMFAEnrollmentStatus(updatedAccount, userNotes);
            setUserMFAStatus(ums);
            setInitialPhoneNumbers(phoneNumbers);
            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
            }));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedAccount]);

    const setEdited = () => setState((prevState) => ({ ...prevState, edited: true }));

    const setIsValidPhoneNumber = (inputPhoneVal: string) => {
        const isValidPhoneNumber = phoneValidator(inputPhoneVal).isValid;
        setState((prevState) => ({ ...prevState, isValidPhoneNumber }));
    };

    const setCanSave = () => {
        return (
            !readOnly ||
            !editingAccount ||
            (loggedInUser && loggedInUser.user_id === editingAccount.user_id)
        );
    };

    const getPhoneNumberList = (phoneNumbers: PhoneNumber[]) => {
        const formattedPhoneNumbers = phoneNumbers
            .filter(
                (inputNumber: PhoneNumber) =>
                    inputNumber.phone && inputNumber.phone !== '(___) ___ - ____'
            )
            .map((inputNumber: PhoneNumber) => ({
                ...inputNumber,
                phone: inputNumber.phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace(/\s+/g, ''),
            }));
        return formattedPhoneNumbers;
    };

    const setEnableEffectiveDates = (enabled: boolean) => {
        updateStateUser({ enforce_effective_dates: enabled });
        setEdited();
    };

    const closeModal = () => {
        setState((prevState) => ({
            ...prevState,
            isMFAModalOpen: false,
            isMFABypassModalOpen: false,
            isNewMFABackupCodesModalOpen: false,
        }));
        setSpinningModal(false);
    };

    const closeNewEmailModal = () => {
        setState((prevState) => ({
            ...prevState,
            isNewEmailModalOpen: false,
        }));
    };

    const closeStatusToggleModal = () => {
        setState((prevState) => ({
            ...prevState,
            statusToggleModalIsOpen: false,
        }));
    };

    const confirmStatusToggleModal = async (note: string) => {
        closeStatusToggleModal();
        await dispatch(
            updateUserStatus({ ...state.user, user_id: editingAccount.user_id }, note)
        );
        updateStateUser({ locked: !state.user.locked });
        setShowToast(true);
    };

    const handleForceLogout = async () => {
        const hasError = await dispatch(forceLogout(editingAccount.user_id));
        setShowToast(true);
        if (hasError !== null) {
            setTypeToast('error');
            setMsgToast('Failed to force logout for the user.');
        } else {
            setTypeToast('success');
            setMsgToast('Force logout was successful!');
        }
    };

    const handleForceResetPassword = async () => {
        const hasError = await dispatch(forceResetPassword(editingAccount.user_id));
        setShowToast(true);
        if (hasError !== null) {
            setTypeToast('error');
            setMsgToast('Failed to invalidate password for the user.');
        } else {
            setTypeToast('success');
            setMsgToast('Password invalidation was successful!');
        }
    };

    const actionResetMFA = async () => {
        await dispatch(resetMFA(editingAccount.user_id));
        dispatch(editingUser(editingAccount.user_id));

        setSpinningModal(false);
        setTypeToast('info');
        setMsgToast('Reset MFA was successful!');
        closeModal();
        setShowToast(true);
    };

    const actionRegenerateBackupCodes = async () => {
        dispatch(startLoading());
        setSpinningModal(true);
        try {
            const codes = await createMfaBackupCodes(editingAccount.user_id);
            setState((prevState) => ({
                ...prevState,
                isNewMFABackupCodesModalOpen: true,
                isMFAModalOpen: true,
                titleMFAModal: 'New MFA Backup Codes',
                modalSize: 'sm',
                bodyMFAModal: (
                    <>
                        <div>
                            The following are new backup codes that the user can use to
                            authenticate during login.
                        </div>
                        {codes.map((code: string) => (
                            <pre key={code}>{code}</pre>
                        ))}
                    </>
                ),
                actionMFAModal: closeModal,
            }));
            dispatch(editingUser(editingAccount.user_id));
        } finally {
            dispatch(doneLoading());
            setSpinningModal(false);
        }
    };

    const actionSaveAllowMFABypass = async ({
        bypassJustification,
        bypassEndDate,
    }: any) => {
        const endDate = toZoned(bypassEndDate as ZonedDateTime, 'UTC').toAbsoluteString();
        const hasError = await dispatch(
            setMFABypass(editingAccount?.user_id, endDate ?? '')
        );
        if (hasError !== null) {
            setTypeToast('error');
            setMsgToast('Failed to set MFA bypass for the user.');
        } else {
            await dispatch(
                addNoteForExistingUser(
                    `MFA bypass allowed: ${bypassJustification as string}`,
                    'MFA_BYPASS'
                )
            );
            setTypeToast('info');
            setMsgToast('MFA bypass was successful!');
            setState((prevState) => ({
                ...prevState,
                bypassMFAJustification: '',
                bypassMFAEndDate: null,
            }));
        }
        dispatch(editingUser(editingAccount.user_id));
        setSpinningModal(false);
        closeModal();
        setShowToast(true);
    };

    const setMFAModalState = (type: string) => {
        switch (type) {
            case 'reset':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    titleMFAModal: 'Reset MFA Confirmation',
                    modalSize: 'sm',
                    bodyMFAModal: (
                        <>
                            <div>Are you sure you want to reset MFA?</div>
                            <br />
                            <div>
                                This action will unenroll MFA, User will be prompted to
                                enroll using a multi-factor authentication app on the next
                                login.
                            </div>
                        </>
                    ),
                    actionMFAModal: actionResetMFA,
                }));
                break;
            case 'backup':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    titleMFAModal: `Generate ${!notEnrolledMFA ? 'New' : ''} Back Up Codes`,
                    modalSize: 'sm',
                    bodyMFAModal: (
                        <>
                            <div>
                                Are you sure you want to
                                {` Generate ${!notEnrolledMFA ? 'New' : ''} Back Up Codes`}
                                ?
                            </div>
                            <br />
                            {!notEnrolledMFA && (
                                <div>
                                    This action will delete previous Backup Codes
                                    generated, and regenerate new ones to be used by the
                                    User next time needed.
                                </div>
                            )}
                        </>
                    ),
                    actionMFAModal: actionRegenerateBackupCodes,
                }));
                break;
            case 'bypass':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    isMFABypassModalOpen: true,
                    titleMFAModal: 'Allow MFA Bypass',
                    modalSize: 'md',
                    bodyMFAModal: (
                        <>
                            <div>
                                <Text>
                                    This will allow the selected user to bypass the
                                    multifactor authentication security requirements from
                                    now until the specified end date and time.
                                </Text>
                            </div>
                            <Spacer />
                            <div>
                                <FlexRow>
                                    <FlexCol xs={3}>
                                        <Label htmlFor="account-info-name">Name:</Label>
                                    </FlexCol>
                                    <FlexCol>
                                        <Text id={'account-info-name'}>
                                            {editingAccount.first_name}{' '}
                                            {editingAccount.last_name}
                                        </Text>
                                    </FlexCol>
                                </FlexRow>
                                <FlexRow>
                                    <FlexCol xs={3}>
                                        <Label htmlFor="account-info-email">
                                            Email Address:
                                        </Label>
                                    </FlexCol>
                                    <FlexCol>
                                        <Text id={'account-info-email'}>
                                            {editingAccount.email}
                                        </Text>
                                    </FlexCol>
                                </FlexRow>
                            </div>
                            <Spacer />
                            <FlexRow>
                                <FlexCol xs={6}>
                                    <DatePicker
                                        id="bypass-date"
                                        label="End Bypass On:"
                                        size="md"
                                        defaultValue={state.bypassMFAEndDate}
                                        placeholderValue={now(dmTimezone)}
                                        minValue={now(dmTimezone)}
                                        granularity="minute"
                                        numVisibleMonths={1}
                                        onChange={(value, validation) => {
                                            if (value && !validation?.isInvalid) {
                                                setState((previousState) => {
                                                    return {
                                                        ...previousState,
                                                        bypassMFAEndDate: value,
                                                    };
                                                });
                                            }
                                        }}
                                    />
                                </FlexCol>
                                <FlexCol>
                                    <Text>
                                        Date and time reflect your location, not that of
                                        the person you are setting up with MFA Bypass
                                    </Text>
                                </FlexCol>
                            </FlexRow>
                            <Spacer />
                            <Label htmlFor={'justification'} isRequired>
                                Justification for bypass:
                            </Label>
                        </>
                    ),
                    actionMFAModal: actionSaveAllowMFABypass,
                }));
                break;
            default:
                break;
        }
    };

    const saveEnabled = () => {
        const { enforce_effective_dates, effective_start_at, effective_end_at } =
            state.user;
        return (
            (state.edited ||
                (state.changesCounter > 0 &&
                    getPhoneNumberList(state.phoneNumbers).length > 0)) &&
            state.isValidPhoneNumber &&
            (!enforce_effective_dates || (effective_start_at && effective_end_at))
        );
    };

    const handleEffectiveDatesSave = async (event?: React.FormEvent) => {
        event?.preventDefault();

        await dispatch(
            updateEffectiveDates({
                user_id: state.user.user_id as string,
                enforce_effective_dates: state.user.enforce_effective_dates as boolean,
                effective_start_at: state.user.effective_start_at as string,
                effective_end_at: state.user.effective_end_at as string,
            })
        );
        setShowToast(true);
    };

    const handlePersonalInfoSave = async (event?: React.FormEvent) => {
        event?.preventDefault();

        await dispatch(
            updatePersonalInfo(
                {
                    user_id: state.user.user_id as string,
                    first_name: state.user.first_name as string,
                    last_name: state.user.last_name as string,
                    phone_numbers: getPhoneNumberList(state.phoneNumbers),
                },
                state.user.should_receive_sms as boolean
            )
        );
        setShowToast(true);
    };

    const handleEmailChangeSave = async () => {
        if (isEmpty(state.user.new_email)) {
            setTypeToast('error');
            setMsgToast(`New email field is empty!`);
            setShowToast(true);
            return;
        }

        if (state.user.new_email === state.user.email) {
            setTypeToast('error');
            setMsgToast(`New email is equal to the old email`);
            setShowToast(true);
            return;
        }

        await dispatch(
            requestEmailChange(
                state.user.user_id as string,
                state.user.new_email as string
            )
        );
        setShowToast(true);
    };

    const defaultRoleChange = (role: Role) => {
        if (!role.is_default_role) {
            dispatch(setDefaultRole(role));
        }
    };

    const handleEditRoleClick = () => dispatch(editUserRoles(history));
    const handleEditAssignedConcessionairesClick = () =>
        dispatch(updateConcAssignmentAction(history));

    const addPhoneNumber = () => {
        setState((prevState) => {
            const phoneNumbers = [
                ...prevState.phoneNumbers,
                { phone: '', ext: '', type: 'cell' },
            ];
            return {
                ...prevState,
                phoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    phoneNumbers
                ),
                isValidPhoneNumber: false,
            };
        });
    };

    const removePhoneNumber = (index: number) => {
        const edited =
            getPhoneNumberList(state.phoneNumbers).length > 0 &&
            initialPhoneNumbers.length > 0;
        setState((prevState) => {
            const phoneNumbers = [...prevState.phoneNumbers];
            phoneNumbers.splice(index, 1);
            return {
                ...prevState,
                phoneNumbers,
                edited,
                changesCounter:
                    phoneNumbers.length === initialPhoneNumbers.length
                        ? 0
                        : (prevState.phoneNumbers ?? initialPhoneNumbers).length -
                          phoneNumbers.length,
                isValidPhoneNumber: true,
            };
        });
    };

    const isLoggedUserSameRoleAsUser = (userA: Partial<User>, userB: Partial<User>) => {
        if (!userA.roles?.length || !userB.roles?.length) return null;

        const rolesArr1 = userA.roles.map((item: Partial<Role>) => item.role_type);
        const rolesArr2 = userB.roles.map((item: Partial<Role>) => item.role_type);

        return rolesArr1.some(
            (roleType: string | undefined) =>
                [PMO, BAH_ADMIN, ATR].includes(roleType || '') &&
                rolesArr2.includes(roleType)
        );
    };

    const cbEffectiveDates = (
        start: Moment | string | null,
        end: Moment | string | null
    ): void => {
        updateStateUser({ effective_start_at: start, effective_end_at: end });
        setEdited();
    };

    const successUI = () => {
        if (!error && updatedAccount) {
            return (
                <Toast show={showToast} type={typeToast} setShow={setShowToast}>
                    <p className="sarsa-text size-lg">{msgToast}</p>
                </Toast>
            );
        }
        if (updateUserStatusSuccessful) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Update user status was successful!
                    </p>
                </Toast>
            );
        }
        if (userIdEnabledPasswordReset) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Password reset email was sent successfully!
                    </p>
                </Toast>
            );
        }
        if (requestConfirmResponse?.ttl && !requestConfirmResponse?.error) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Confirmation email was sent successfully!
                    </p>
                </Toast>
            );
        }
        if (!error && showToast) {
            return (
                <Toast show={showToast} type={typeToast} setShow={setShowToast}>
                    <p className="sarsa-text size-lg">{msgToast}</p>
                </Toast>
            );
        }
        return null;
    };

    const isEditingConcessionaireManager = () => {
        if (!editingAccount) return false;
        return (
            !loggedInUser?.is_concessionaire &&
            isUserConcessionaireManager(editingAccount)
        );
    };

    const canEditLocations = () => {
        if (isEditingConcessionaireManager()) {
            return false;
        }
        if (readOnly) {
            return false;
        }
        if (state.loggedInConcessionaire && isUserConcessionaireManager(editingAccount)) {
            return false;
        }
        return true;
    };

    const concessionaireEntity = () => {
        if (!editingAccount?.is_concessionaire) return null;
        const safeConc = concessionaires || [];

        if (safeConc.length > 0) {
            return (
                <div className="assigned-concessionaires-wrapper mt-3">
                    <div className="assigned-concessionaires-header">
                        Associated Concessionaires
                    </div>
                    <Button
                        appearance="link"
                        className="update-user-update-concessionaires-button"
                        iconBeforeElement={<Icon iconName="add-circle" />}
                        onClick={handleEditAssignedConcessionairesClick}
                    >
                        Edit Assigned Concessionaires
                    </Button>
                    <ul className="assigned-concessionaires-list">
                        {safeConc.map((conc) => (
                            <li key={`conc_${conc.concessionaire_id}`}>
                                {conc.concessionaire_name}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        if (concessionaire) {
            return (
                <div className="assigned-concessionaire-wrapper">
                    <div className="assigned-concessionaire-title">
                        Associated Concessionaire:
                        <span className="assigned-concessionaire-name">
                            {concessionaire.concessionaire_name}
                        </span>
                    </div>
                </div>
            );
        }

        return null;
    };

    const onPhoneTypeChange = (index: number, type: string) => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                ext: type === 'work' ? updatedPhoneNumbers[index].ext : '',
                type,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const onPhoneExtensionChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                ext: event.target.value,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const onPhoneNumberChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsValidPhoneNumber(event.target.value);
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                phone: event.target.value,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const showDeleteColumn = () => {
        if (readOnly) {
            return false;
        }
        if (editingAccount?.roles?.length < 2) {
            return false;
        }
        if (isEditingConcessionaireManager()) {
            return false;
        }
        if (isUserConcessionaireManager(editingAccount)) {
            return false;
        }
        return true;
    };

    const loginDotGovLabel = AvailableOidcProvidersLabels.loginDotGov;

    const unlink = () => {
        dispatch(unlinkLoginDotGovTarget(editingAccount.user_id))
            .then((resp) => {
                if (resp instanceof Error) {
                    setTypeToast('error');
                    setMsgToast(`Failed to unlink ${loginDotGovLabel} account!`);
                    setShowToast(true);
                } else {
                    dispatch(loginDotGovUnlinked());
                    setTypeToast('info');
                    setMsgToast(`Unlink ${loginDotGovLabel} account was successful!`);
                    setShowToast(true);
                }
            })
            .catch(() => {});
    };

    const renderSidebarLayout = (
        enforceEffectiveDates: boolean,
        effectiveStartAt: Moment | string | null,
        effectiveEndAt: Moment | string | null
    ) => {
        const isEmailConfirmed = editingAccount?.is_email_confirmed === true;
        const canResendConfirmation =
            editingAccount?.is_email_confirmed === false ||
            (!isNil(editingAccount?.email_change) &&
                isNil(editingAccount?.email_change.completed_at));

        const loginDotGovLinked =
            editingAccount?.oidc_user_info?.[AvailableOidcProviders.loginDotGov]
                ?.enabled === true;
        const loginDotGovLinkDate =
            editingAccount?.oidc_user_info?.[AvailableOidcProviders.loginDotGov]
                ?.modified_at;

        const emailChangeButton = () => {
            if (
                isNil(editingAccount?.email_change) ||
                !isNil(editingAccount?.email_change.completed_at)
            ) {
                return (
                    <Button
                        appearance="tertiary"
                        onClick={() =>
                            setState((prevState) => ({
                                ...prevState,
                                isNewEmailModalOpen: true,
                            }))
                        }
                    >
                        Change Email Address
                    </Button>
                );
            }

            return (
                <Button
                    appearance="tertiary"
                    onClick={() => {
                        dispatch(cancelEmailChange(editingAccount?.user_id));
                        dispatch(emailChangeCancelled());
                    }}
                >
                    Cancel Email Change
                </Button>
            );
        };

        const disableButtons = !saveEnabled();

        return (
            <div className="rec-nested-wrap">
                <FlexRow justifyContent="space-between">
                    <FlexCol xs={1} className="mr-2">
                        <Switch
                            label={!state.user.locked ? 'Active' : 'Locked'}
                            isSelected={!state.user.locked}
                            onChange={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    statusToggleModalIsOpen: true,
                                }));
                            }}
                            isDisabled={readOnly}
                        />
                    </FlexCol>
                    <FlexCol xs={3}>
                        <Box textAlign="left">
                            <Text>
                                <b>{`${state.user.first_name} ${state.user.last_name}`}</b>{' '}
                                - {state.user.email}
                            </Text>
                        </Box>
                    </FlexCol>
                    <FlexCol>
                        <Box textAlign="right">
                            <Button
                                appearance="link"
                                iconAfterElement={<Icon iconName="help" size="md" />}
                                onClick={() =>
                                    window.open(
                                        process.env.SN_MANAGE_USERS_FAQ_URL,
                                        '_blank'
                                    )
                                }
                            >
                                Guide to Creating and Managing Users
                            </Button>
                        </Box>
                    </FlexCol>
                </FlexRow>
                <Divider className="mt-3 mb-3" />
                {successUI()}
                <AnchorPageWithSidebarLayout
                    className="te-facility-fees-tab"
                    preventScrollToAnchor
                    singlePanelMode
                >
                    <AnchorPageSection
                        heading="Personal Info"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow>
                            <FlexCol sm={3}>
                                <TextFieldStateless
                                    id="updateUserFirstName"
                                    label="First Name"
                                    value={state.user.first_name}
                                    onChange={(e) => {
                                        setEdited();
                                        updateStateUser({ first_name: e.target.value });
                                    }}
                                    placeholder="First Name"
                                    isRequired
                                    isDisabled={!setCanSave()}
                                />
                                <Spacer size="sm" />
                                <Text className="sarsa-label mt-1">Email</Text>
                                <Text>
                                    {state.user.email}
                                    {!isEmailConfirmed ? ' (Not verified)' : ''}
                                </Text>
                                <Spacer size="sm" />
                            </FlexCol>
                            <FlexCol sm={3}>
                                <TextFieldStateless
                                    label="Last Name"
                                    value={state.user.last_name}
                                    onChange={(e) => {
                                        setEdited();
                                        updateStateUser({ last_name: e.target.value });
                                    }}
                                    placeholder="Last Name"
                                    isRequired
                                    isDisabled={!setCanSave()}
                                    id="updateUserLastName"
                                />
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol sm={6}>
                                <Alert type="info">
                                    Please contact your manager if you need to update the
                                    email address associated with your account.
                                </Alert>
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                <Divider className="mt-4 mb-4" />
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5">
                                        Phone Numbers
                                    </Heading>
                                </div>
                                <Spacer size="sm" />
                                <PhoneNumbers
                                    phoneNumbers={state.phoneNumbers}
                                    canSave={setCanSave()}
                                    onExtChange={(event, index) => {
                                        onPhoneExtensionChange(index, event);
                                    }}
                                    onTypeChange={(type, index) => {
                                        onPhoneTypeChange(index, type);
                                    }}
                                    onNumberChange={(event, index) => {
                                        onPhoneNumberChange(index, event);
                                    }}
                                    onRemovePhone={removePhoneNumber}
                                    onAddPhone={addPhoneNumber}
                                />
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                <ButtonGroup>
                                    <Button
                                        onClick={() => initializeValues()}
                                        id="updateUserCancel"
                                        appearance="tertiary"
                                        isDisabled={disableButtons}
                                    >
                                        Discard Changes
                                    </Button>
                                    {setCanSave() && (
                                        <Button
                                            appearance="primary"
                                            isDisabled={disableButtons}
                                            onClick={(e) => {
                                                setTypeToast('success');
                                                setMsgToast(
                                                    `${!isEmpty(state.user.new_email) ? state.user.new_email : state.user.email} was saved successfully!`
                                                );
                                                handlePersonalInfoSave(e);
                                            }}
                                            id="updateUserSave"
                                        >
                                            Save
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="Roles and Locations"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow className="mt-2 mb-2">
                            <FlexCol>
                                <Checkbox
                                    className="hidden"
                                    isChecked={state.user.can_run_transactions}
                                    onChange={() =>
                                        updateStateUser({
                                            can_run_transactions:
                                                !state.user.can_run_transactions,
                                        })
                                    }
                                    label="Can Run Transactions"
                                    isDisabled={readOnly}
                                    id="can_run_transactions"
                                />
                                <Checkbox
                                    isDisabled={true}
                                    isChecked={
                                        editingAccount?.inherits_concessionaire_roles
                                    }
                                    onChange={() => {}}
                                    label={
                                        state.loggedInConcessionaire ||
                                        isUserConcessionaire(editingAccount)
                                            ? 'Concessionaire'
                                            : 'Concessionaire Facility Manager'
                                    }
                                    id="concessionaire"
                                />
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                {concessionaireEntity()}
                                <RolesTable
                                    showBorders
                                    showHeader={false}
                                    showDefaultColumn={false}
                                    showDeleteColumn={showDeleteColumn()}
                                    canEditLocations={canEditLocations()}
                                    onEditLocations={handleEditRoleClick}
                                    onRoleDelete={(role) => dispatch(deleteRole(role))}
                                    onDefaultRoleChange={defaultRoleChange}
                                    editingSelf={false}
                                    roles={editingAccount?.roles}
                                />
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="User History"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow className="mt-3 mb-2">
                            <FlexCol xs={12} md={12} lg={6}>
                                <AdditionalInformation
                                    user={{
                                        ...editingAccount,
                                        enforce_effective_dates: enforceEffectiveDates,
                                        effective_start_at: effectiveStartAt,
                                        effective_end_at: effectiveEndAt,
                                    }}
                                />
                            </FlexCol>
                            <FlexCol>
                                <div className="rec-form-item-wrap">
                                    <UserNotes
                                        readOnly={
                                            state.isCsrSupervisor ? false : readOnly
                                        }
                                    />
                                </div>
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="Security"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow className="mt-5 mb-3">
                            <FlexCol sm={6}>
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5Caps">
                                        Effective Dates
                                    </Heading>
                                </div>
                            </FlexCol>
                        </FlexRow>
                        <FlexRow className="mt-3 mb-3">
                            <FlexCol sm={4}>
                                <EffectiveDates
                                    className="mb-1"
                                    editable={!readOnly}
                                    effectiveDatesEnabled={enforceEffectiveDates}
                                    inStartDate={effectiveStartAt}
                                    inEndDate={effectiveEndAt}
                                    setDatesFn={cbEffectiveDates}
                                    setEnabledFn={setEnableEffectiveDates}
                                    showGuide
                                />
                            </FlexCol>
                            <FlexCol className={'ml-2'}>
                                <ButtonGroup>
                                    <Button
                                        onClick={() => initializeValues()}
                                        id="updateUserCancel"
                                        appearance="tertiary"
                                        isDisabled={disableButtons}
                                    >
                                        Discard Changes
                                    </Button>
                                    {setCanSave() && (
                                        <Button
                                            appearance="primary"
                                            isDisabled={disableButtons}
                                            onClick={(e) => {
                                                setTypeToast('success');
                                                setMsgToast(
                                                    `${!isEmpty(state.user.new_email) ? state.user.new_email : state.user.email} was saved successfully!`
                                                );
                                                handleEffectiveDatesSave(e);
                                            }}
                                            id="updateUserSave"
                                        >
                                            Save
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </FlexCol>
                        </FlexRow>
                        <Divider className="mt-3 mb-3" />
                        <FlexRow className="mt-4 mb-2">
                            <FlexCol sm={6}>
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5Caps">
                                        Email
                                    </Heading>
                                </div>
                                <Spacer size="sm" />
                                <Text className="sarsa-label mt-1">
                                    Current Email Address
                                </Text>
                                <Text>
                                    {state.user.email}
                                    {!isEmailConfirmed ? ' (Not verified)' : ''}
                                </Text>
                            </FlexCol>
                        </FlexRow>
                        {!isNil(editingAccount?.email_change) &&
                            isNil(editingAccount?.email_change.completed_at) && (
                                <FlexRow className="mb-2">
                                    <FlexCol sm={12}>
                                        <Alert
                                            type="warning"
                                            heading="Email Change Pending"
                                        >
                                            You have a e-mail change request pending
                                            confirmation.
                                            <Spacer size="sm" />
                                            New e-mail:{' '}
                                            {editingAccount?.email_change.new_email}
                                        </Alert>
                                    </FlexCol>
                                </FlexRow>
                            )}
                        <FlexRow className="space-left mb-1">
                            {loggedInUser &&
                                isLoggedUserSameRoleAsUser(loggedInUser, state.user) ===
                                    false && (
                                    <FlexRow className="space-left mb-3">
                                        <ButtonGroup isStretchedToFit>
                                            {emailChangeButton()}
                                            {canResendConfirmation && (
                                                <Button
                                                    appearance="tertiary"
                                                    onClick={async () => {
                                                        await dispatch(
                                                            resendConfirmation(
                                                                editingAccount
                                                            )
                                                        );
                                                        setShowToast(true);
                                                    }}
                                                >
                                                    Resend Confirmation Email
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </FlexRow>
                                )}
                        </FlexRow>
                        <Divider className="mt-1 mb-3" />
                        <FlexRow className="mt-4 mb-3">
                            <FlexCol sm={6}>
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5Caps">
                                        {process.env.SITE_NAME} Password
                                    </Heading>
                                    <Spacer size="sm" />
                                    <PasswordSection
                                        user={editingAccount}
                                        loggedInUser={loggedInUser}
                                        setShowToast={setShowToast}
                                    />
                                </div>
                            </FlexCol>
                        </FlexRow>
                        <FeatureFlag flag="iaEnableMfa">
                            <Divider className="mt-1 mb-3" />
                            <FlexRow className="mt-4 mb-3">
                                <FlexCol sm={6}>
                                    <div className="rec-color-gray">
                                        <Heading headingLevel={2} appearance="h5Caps">
                                            Multi-factor Authentication
                                        </Heading>
                                    </div>
                                </FlexCol>
                            </FlexRow>
                            <FlexRow className="space-left mb-0">
                                <div className="update-user-roles-table-header">
                                    Multi-factor Authentication Enrollment Status
                                </div>
                            </FlexRow>
                            {userMFAStatus &&
                                userMFAStatus.map((status) => {
                                    return (
                                        <MfaStatus
                                            key={`mfa-status-${status.key}`}
                                            userMFAStatus={status.userMFAStatus}
                                            additionalInfo={status.additionalInfo}
                                        />
                                    );
                                })}
                            <FlexRow className="space-left mt-2 mb-4">
                                {state.canControlMFA && (
                                    <ButtonGroup isStretchedToFit>
                                        <Button
                                            appearance="tertiary"
                                            isDisabled={notEnrolledMFA}
                                            onClick={() => setMFAModalState('reset')}
                                            gaTrackingId="966162610055"
                                        >
                                            Reset MFA
                                        </Button>
                                        <Button
                                            appearance="tertiary"
                                            onClick={() => setMFAModalState('backup')}
                                            gaTrackingId="966162610055"
                                        >
                                            {`Generate ${!notEnrolledMFA ? 'New' : ''} Back Up Codes`}
                                        </Button>
                                        {state.canDoMFABypass && (
                                            <Button
                                                appearance="tertiary"
                                                gaTrackingId="966162610055"
                                                onClick={() => setMFAModalState('bypass')}
                                            >
                                                Allow MFA Bypass
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                )}
                            </FlexRow>
                        </FeatureFlag>
                        <FeatureFlag flag="enableOidc">
                            <FlexRow>
                                <FlexCol sm={6}>
                                    <Divider className="mt-1 mb-3" />
                                </FlexCol>
                            </FlexRow>
                            <FlexRow className="space-left">
                                <div className="update-user-roles-table-header">
                                    {loginDotGovLabel}
                                </div>
                            </FlexRow>
                            <Spacer size="sm" />
                            <Text className="sarsa-label mt-1">
                                {loginDotGovLabel} Link Status
                            </Text>
                            <Text>
                                {loginDotGovLinked
                                    ? `${loginDotGovLabel} account linked on ${moment(loginDotGovLinkDate).format('MM/DD/YYYY')}`
                                    : `${loginDotGovLabel} not linked.`}
                            </Text>
                            {state.canControlMFA && (
                                <FlexRow className="space-left mt-3 mb-4">
                                    <ButtonGroup isStretchedToFit>
                                        <Button
                                            appearance="tertiary"
                                            onClick={unlink}
                                            isDisabled={!loginDotGovLinked}
                                        >
                                            {`Unlink ${loginDotGovLabel}`}
                                        </Button>
                                    </ButtonGroup>
                                </FlexRow>
                            )}
                        </FeatureFlag>
                        {state.canForceResetPassword &&
                            state.canForceLogout &&
                            loggedInUser &&
                            isLoggedUserSameRoleAsUser(loggedInUser, state.user) ===
                                false && (
                                <>
                                    <Divider className="mt-3 mb-3" />
                                    <FlexRow className="mt-4 mb-3">
                                        <FlexCol sm={6}>
                                            <div className="rec-color-gray">
                                                <Heading
                                                    headingLevel={2}
                                                    appearance="h5Caps"
                                                >
                                                    Account Recovery
                                                </Heading>
                                            </div>
                                        </FlexCol>
                                    </FlexRow>
                                    <FlexRow className="space-left mb-1">
                                        <FlexRow className="space-left mb-3">
                                            <ButtonGroup isStretchedToFit>
                                                <Button
                                                    appearance="tertiary"
                                                    isDisabled={
                                                        !state.canForceResetPassword
                                                    }
                                                    onClick={handleForceResetPassword}
                                                >
                                                    Invalidate Password
                                                </Button>
                                                <Button
                                                    appearance="tertiary"
                                                    isDisabled={!state.canForceLogout}
                                                    onClick={handleForceLogout}
                                                >
                                                    Force Logout
                                                </Button>
                                            </ButtonGroup>
                                        </FlexRow>
                                    </FlexRow>
                                </>
                            )}
                    </AnchorPageSection>
                </AnchorPageWithSidebarLayout>
            </div>
        );
    };

    const enforceEffectiveDates = state.user.enforce_effective_dates;
    const effectiveStartAt = !isEmpty(state.user.effective_start_at)
        ? state.user.effective_start_at
        : null;
    const effectiveEndAt = !isEmpty(state.user.effective_end_at)
        ? state.user.effective_end_at
        : null;

    return (
        <div>
            <div className="edit-user-wrapper">
                <div id="page-body">
                    <ActionErrorDisplay
                        error={error}
                        errorStringMapping={errorForUpdateUser}
                    />
                    {updateUserStatusErrorMessage && (
                        <Alert shouldFocusOnMount type="error">
                            {updateUserStatusErrorMessage}
                        </Alert>
                    )}
                    {errorEnablingPasswordReset && (
                        <Alert shouldFocusOnMount type="error">
                            {errorEnablingPasswordReset}
                        </Alert>
                    )}
                    {requestConfirmResponse?.error && (
                        <Alert shouldFocusOnMount type="error">
                            {requestConfirmResponse?.error}
                        </Alert>
                    )}
                    {renderSidebarLayout(
                        enforceEffectiveDates || false,
                        effectiveStartAt || '',
                        effectiveEndAt || ''
                    )}
                </div>
            </div>
            <FeatureFlag flag="iaEnableMfa">
                <StyledModal
                    size={state.modalSize}
                    isOpen={state.isMFAModalOpen}
                    heading={state.titleMFAModal}
                    onRequestClose={closeModal}
                >
                    <div className="modal-mfa-text-body">{state.bodyMFAModal}</div>
                    {state.isMFABypassModalOpen && (
                        <TextArea
                            placeholder="Write the justification here:"
                            id="justification"
                            label=""
                            value={state.bypassMFAJustification}
                            onChange={(e: TargetedEvent) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    bypassMFAJustification: e.target.value,
                                    actionMFAModal: actionSaveAllowMFABypass,
                                }))
                            }
                        />
                    )}
                    <ModalActions>
                        <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                            {!state.isNewMFABackupCodesModalOpen && (
                                <Button
                                    appearance="tertiary"
                                    isDisabled={spinningModal}
                                    onClick={closeModal}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSpinningModal(true);
                                    state.actionMFAModal({
                                        bypassJustification: state.bypassMFAJustification,
                                        bypassEndDate: state.bypassMFAEndDate,
                                    });
                                }}
                                isLoading={spinningModal}
                                isDisabled={
                                    spinningModal ||
                                    (state.isMFABypassModalOpen &&
                                        (state.bypassMFAJustification.length <= 1 ||
                                            state.bypassMFAEndDate === null ||
                                            state.bypassMFAEndDate === undefined ||
                                            state.bypassMFAEndDate.toString().length <=
                                                1))
                                }
                            >
                                {state.isMFABypassModalOpen ? 'Allow Bypass' : 'Okay'}
                            </Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
            </FeatureFlag>
            <NewEmailAddressModal
                isOpen={state.isNewEmailModalOpen}
                email={state.user.new_email || ''}
                currentEmail={state.user.email || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateStateUser({
                        new_email: e.target.value,
                    });
                }}
                onConfirm={() => {
                    closeNewEmailModal();
                    setTypeToast('info');
                    setMsgToast(
                        `A change to the user's email address has been requested. The user will receive a ` +
                            `confirmation email (sent to the requested address) and must click a link in that email to confirm this change.`
                    );
                    handleEmailChangeSave();
                }}
                onClose={closeNewEmailModal}
            />
            <UserStatusToggle
                isOpen={state.statusToggleModalIsOpen}
                selectedUser={state.user}
                cancel={closeStatusToggleModal}
                confirm={confirmStatusToggleModal}
            />
        </div>
    );
}
