/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

import { formatDistanceToNow } from 'date-fns';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonGroup, ModalActions, StyledModal } from 'sarsaparilla';

import { AppDispatch } from '../../dev/store';
import { keepaliveNoThrottle, keepaliveThrottled } from '../actions/keepalive';
import {
    autoLogoutWarningCanceled,
    closeAutoLogoutWarning,
    logOutForInactivity,
} from '../actions/login'; // CUSTOMIZABLE TIME VALUES

type AutoLogoutWarningProps = {
    defaultTimeoutSeconds?: number;
    promptBeforeIdleSeconds?: number;
    useReduxValue?: boolean;
};

type State = {
    login: {
        account: {
            inactivity_limit_seconds: number;
        };
    };
};

export function IdleMonitor({
    defaultTimeoutSeconds = 2 * 60 * 60, // 2 hours
    promptBeforeIdleSeconds = 60 * 60, // 1 hour
    useReduxValue = true,
}: AutoLogoutWarningProps) {
    const [isPromptOpen, setIsPromptOpen] = React.useState(false);
    const [remaining, setRemaining] = React.useState(0);
    const intervalRef = React.useRef(0);

    const dispatch = useDispatch<AppDispatch>();
    const logoutFn = () => {
        setIsPromptOpen(false);
        dispatch(autoLogoutWarningCanceled());
    };

    const logoutInactivityFn = () => dispatch(logOutForInactivity());

    const updateLocalStorage = (lastActiveDate: Date | null | undefined) => {
        // Because checkLoggedOut expects lastActiveAt timestamp in local storage,
        // and because we can't deploy all of the UI's to prod in sync,
        // we must maintain backwards compatibility with existing checkLoggedIn expectations
        // therefore, we must write to localstorage, the same data that redux-idle-monitor would.
        // idlemonitor stores values in this format, as a string: {"isActive":true,"lastActive":1736529609176}
        if (!lastActiveDate) {
            return;
        }

        const lastActive = Math.floor(lastActiveDate.getTime());

        window.localStorage.setItem(
            'idlemonitor',
            JSON.stringify({ isActive: true, lastActive })
        );
    };

    const onIdle = () => {
        logoutInactivityFn();
    };

    const onActive = () => {
        setIsPromptOpen(false);
    };

    const onAction = (event?: Event, timer?: IIdleTimer) => {
        updateLocalStorage(timer?.getLastActiveTime());
        dispatch(keepaliveThrottled());
    };

    const onPrompt = () => {
        setIsPromptOpen(true);
    };

    let maxInactivityDuration = defaultTimeoutSeconds;
    const reduxInactivityLimit = useSelector(
        (state: State) => state.login?.account?.inactivity_limit_seconds
    );
    if (useReduxValue) {
        maxInactivityDuration = reduxInactivityLimit;
    }
    const timeout = maxInactivityDuration * 1000; // ms. This is how long until 'idle' state triggers logout
    const promptBeforeIdle = promptBeforeIdleSeconds * 1000; // ms. This is how long to display the warning modal before logout

    const idleTimerProps = {
        onIdle,
        onActive,
        onAction,
        onPrompt,
        timeout,
        promptBeforeIdle,
        crossTab: true,
        throttle: 60 * 1000, // this rate limits how often we update local storage and do keepalive
    };

    const timer = useIdleTimer(idleTimerProps);

    // When the modal is open we put the remaining time into state so that the time
    // remaining displayed in the modal continues to update
    React.useEffect(() => {
        if (isPromptOpen) {
            const timeRemaining = Number.isSafeInteger(timer.getRemainingTime())
                ? Math.ceil(timer.getRemainingTime() / 1000)
                : 0;
            intervalRef.current = window.setInterval(() => {
                setRemaining(timeRemaining);
            }, 500);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [isPromptOpen, timer]);

    const stayLoggedIn = () => {
        setIsPromptOpen(false);
        dispatch(closeAutoLogoutWarning());
        dispatch(keepaliveNoThrottle());
        timer.activate();
    };

    const logoutDate = 1000 * remaining + new Date().getTime();

    return (
        <StyledModal
            heading="Are you still there?"
            size="sm"
            isOpen={isPromptOpen}
            onRequestClose={stayLoggedIn}
            shouldShowCloseButton={false}
            shouldCloseOnOverlayClick={false}
        >
            <p>
                Your session will expire due to inactivity in{' '}
                {formatDistanceToNow(logoutDate, { includeSeconds: true })}.
            </p>
            <p>If you are logged off, any changes will be lost.</p>
            <ModalActions>
                <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                    <Button appearance={'tertiary'} onClick={logoutFn}>
                        Log Off
                    </Button>
                    <Button appearance={'primary'} onClick={stayLoggedIn}>
                        Stay Logged In
                    </Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

export default IdleMonitor;

// cSpell:ignore idlemonitor
