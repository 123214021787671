/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Accordion,
    AccordionContent,
    AccordionHeading,
    AccordionItem,
    Button,
    Icon,
    Select,
} from 'sarsaparilla';
import { searchFieldChanged, searchValueChanged } from '../actions/userManagement';
import {
    EMAIL_FIELD_TYPE,
    FIRST_NAME_FIELD_TYPE,
    LAST_NAME_FIELD_TYPE,
    LOCATION_NAME_FIELD_TYPE,
} from '../constants/globals';
import LocationPicker from './LocationPicker';

const propTypes = {
    id: PropTypes.string,
    run: PropTypes.func,
    clear: PropTypes.func,
    hasRan: PropTypes.bool,
    fieldSelectionChanged: PropTypes.func,
    valueChanged: PropTypes.func,
    field: PropTypes.string,
    value: PropTypes.string,
};

const fieldOptions = [
    { label: 'Last Name', value: LAST_NAME_FIELD_TYPE },
    { label: 'First Name', value: FIRST_NAME_FIELD_TYPE },
    { label: 'Email', value: EMAIL_FIELD_TYPE },
    { label: 'Location', value: LOCATION_NAME_FIELD_TYPE },
];

export class UserListThrottle extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    componentDidMount() {
        if (this.props.value?.location_id && this.props.value) {
            this.props.run(
                this.props.value,
                `Location: ${this.props.value.location_name}`
            );
        }
    }

    changeFieldSelection(evt) {
        const value = evt.target.value;
        this.props.fieldSelectionChanged(value);

        if (
            this.props.field === LOCATION_NAME_FIELD_TYPE &&
            value !== LOCATION_NAME_FIELD_TYPE
        ) {
            this.props.clear();
        }

        this.setState({ error: null });
    }

    changeValueSelection(evt) {
        if (evt.target.value.includes('@')) {
            this.props.fieldSelectionChanged(EMAIL_FIELD_TYPE);
        }
        this.props.valueChanged(evt.target.value);
        this.setState({ error: null });
    }

    changeLocation(location) {
        if (
            this.props.field === LOCATION_NAME_FIELD_TYPE &&
            location.location_id &&
            location.location_type
        ) {
            this.props.valueChanged(location);
            this.props.run(location, `Location: ${location.location_name}`);
        }
    }

    keyUpValueSelection(e) {
        if (e.key === '@') {
            this.props.fieldSelectionChanged(EMAIL_FIELD_TYPE);
        }
        if (e.keyCode === 13) {
            this.runSearch();
        }
    }

    runSearch() {
        if (this.props.value.length) {
            let label = null;
            fieldOptions.forEach((opt) => {
                if (opt.value === this.props.field) {
                    label = opt.label;
                }
            });
            const throttleString = `${label}: ${this.props.value}`;
            const params = {};
            params[this.props.field] = this.props.value;
            this.props.run(params, throttleString);
        } else {
            this.setState({ error: 'empty' });
        }
    }

    clearSearch() {
        this.setState({
            error: null,
        });
        this.props.clear();
    }

    searchInstructions() {
        if (this.props.field === LOCATION_NAME_FIELD_TYPE) {
            return null;
        }

        return (
            <>
                <div className="throttle-search-instructions">
                    <div className="throttle-search-instructions-text">
                        {`You can search for users based on first name, last name, or email as well as location by selecting any of those options from the drop down and entering your search criteria.`}
                    </div>
                </div>
                <Accordion
                    id="accountSearchInstructions"
                    headingLevel={2}
                    className="ml-4"
                >
                    <AccordionItem>
                        <AccordionHeading>Search Criteria Instructions</AccordionHeading>
                        <AccordionContent>
                            <p>
                                If you want to search for “John Smith”, you can select
                                “First Name” and search for John, “Last Name” and search
                                for Smith, or “Email” and search for
                                “john.smith@example.com”. However, searching for “Smith”
                                under “First Name” or “Email” will not surface any results
                                since it does not meet the search criteria for those
                                options.
                            </p>
                            <p>
                                PII requirements dictate that lists of users presented in
                                search results through The Hub remain relatively small to
                                limit the exposure risk. PMO and Program Manager users
                                have access to thousands of records though, and are thus
                                required to minimize the number of search results
                                presented through upfront filtering. Additionally,
                                encryption requirements mean that only full word search
                                matches are supported by the system.
                            </p>
                            <p>
                                If you want to find your own account, please click on your
                                name in the top right corner and select “My Profile” to
                                view your account info.
                            </p>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </>
        );
    }

    searchPlaceholderForSelection() {
        switch (this.props.field) {
            case FIRST_NAME_FIELD_TYPE:
                return 'Search by First Name';
            case LAST_NAME_FIELD_TYPE:
                return 'Search by Last Name';
            case EMAIL_FIELD_TYPE:
                return 'Search by Email';
            default:
                return '';
        }
    }

    renderRunner() {
        const inputErr = this.state.error === 'empty' ? 'rec-error' : '';
        const searchButtonDisabled = !this.props.value || this.props.value.length === 0;

        return (
            <div id={this.props.id} className="throttle-runner">
                <div className="throttle-label">Search For:</div>
                <div className="throttle-field">
                    <Select
                        id="user-list-throttle"
                        label="Search for"
                        isLabelVisible={false}
                        options={fieldOptions}
                        onChange={this.changeFieldSelection.bind(this)}
                        value={this.props.field}
                    />
                </div>
                {this.props.field === LOCATION_NAME_FIELD_TYPE && (
                    <div className="throttle-location">
                        <LocationPicker
                            id="user-list-throttle"
                            selectedLocation={
                                this.props.value?.location_id ? this.props.value : null
                            }
                            locationSelect={this.changeLocation.bind(this)}
                        />
                    </div>
                )}
                {this.props.field !== LOCATION_NAME_FIELD_TYPE && (
                    <>
                        <div className="throttle-value">
                            <input
                                className={`throttle-value-input ${inputErr}`}
                                placeholder={this.searchPlaceholderForSelection()}
                                aria-label="Throttle Search"
                                onChange={this.changeValueSelection.bind(this)}
                                onKeyUp={this.keyUpValueSelection.bind(this)}
                            />
                            <div className="search-icon">
                                <Icon iconName="search" />
                            </div>
                        </div>
                        <div className="throttle-run">
                            <Button
                                appearance="tertiary"
                                onClick={this.runSearch.bind(this)}
                                disabled={searchButtonDisabled}
                            >
                                Search
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    }

    renderClearer() {
        const fieldLabel = fieldOptions.filter((op) => op.value === this.props.field)[0]
            .label;

        return (
            <Button
                aria-label={`clear search for ${fieldLabel}: ${this.props.value} `}
                className="throttle-clearer"
                appearance="tertiary-alt"
                onClick={this.clearSearch.bind(this)}
            >
                <div>{`You searched for - [ ${fieldLabel}: ${this.props.value} ]`}</div>
                <Icon iconName="close-circle" size="md" />
            </Button>
        );
    }

    render() {
        const classes = classNames({
            'user-list-throttle': true,
            'internal-header-span': true,
        });
        const isByLocation = this.props.field === LOCATION_NAME_FIELD_TYPE;

        return (
            <div className={classes}>
                {this.searchInstructions()}
                {(!this.props.hasRan || isByLocation) && this.renderRunner()}
                {this.props.hasRan && !isByLocation && this.renderClearer()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        field: state.userManagement.searchField,
        value: state.userManagement.searchValue,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fieldSelectionChanged: (field) => dispatch(searchFieldChanged(field)),
        valueChanged: (value) => dispatch(searchValueChanged(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListThrottle);
