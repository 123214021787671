/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './login.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Alert,
    Box,
    Button,
    FormValidationAlert,
    Heading,
    RadioToggle,
    Stack,
    Text,
    TextField,
} from 'sarsaparilla';
import { logOut } from '../../actions/login';
import { errorForLogin } from '../../utilities/errorMessages';
import { useAppDispatch } from '../../utilities/redux-helpers';
import {
    VerificationCodeField,
    type ValidationState,
    type VerificationCodeFieldRef,
} from '../mfaEnrollment/VerificationCodeField';

const TOTP = 'totp';
const BACKUP = 'backup';
interface MfaValidateFormProps {
    submitFn: (code: string) => void;
    error: string;
    showStartOver?: boolean;
    submitText?: string;
}

type loginState = {
    login: {
        attempts: number;
    };
};

export function MfaValidateForm({
    submitFn,
    error,
    showStartOver,
    submitText,
}: MfaValidateFormProps) {
    const dispatch = useAppDispatch();
    const inputRef = React.useRef<VerificationCodeFieldRef>(null);
    const backupCodeInputRef = React.useRef<TextField>(null);
    const [inputValidation, setInputValidation] = React.useState<
        ValidationState | null | undefined
    >(null);
    const attempts = useSelector((state: loginState) => state.login.attempts);

    const inputModeOptions = [
        {
            label: 'Use 6-Digit Code',
            value: TOTP,
        },
        {
            label: 'Use Backup Code',
            value: BACKUP,
        },
    ];
    const [inputMode, setInputMode] = React.useState(inputModeOptions[0].value);

    const logoutHandler = () => {
        dispatch(logOut());
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const code = formData.get('code') as string;

        const validation =
            inputMode === TOTP
                ? inputRef.current?.validate()
                : (backupCodeInputRef.current?.validate() as ValidationState);

        setInputValidation(validation);

        if (code && !validation?.isInvalid) {
            submitFn(code);
        }
    };

    return (
        <div className="ia-enter-mfa">
            <Heading
                headingLevel={1}
                appearance="h3"
                headingAlign="center"
                className="mb-3"
            >
                Sign In
            </Heading>

            {error ? (
                <Alert shouldFocusOnMount type="error" className="my-3">
                    {errorForLogin(error, attempts)}
                </Alert>
            ) : inputValidation?.isInvalid ? (
                // @ts-ignore - type will be fixed in next sarsa release
                <FormValidationAlert errors={[inputValidation]} className="my-3" />
            ) : null}

            <Stack>
                <Box>
                    Connect the security key that you associated with your account or use
                    a backup code.
                    <strong> (Backup codes may only be used one time.)</strong>
                </Box>
                <Box alignX="center">
                    <RadioToggle
                        options={inputModeOptions}
                        value={inputMode}
                        onChange={(e) => setInputMode(e.target.value)}
                        isRequired
                    />
                </Box>
                <Heading headingLevel={2} headingAlign="center" appearance="h6">
                    {inputMode === TOTP
                        ? '6-digit Code from Authenticator App'
                        : 'One-time Backup Code'}
                </Heading>
                <form className="center-vertical" onSubmit={onSubmit} noValidate>
                    {inputMode === TOTP ? (
                        <VerificationCodeField
                            ref={inputRef}
                            label="Verification Code"
                            className="my-3"
                            name="code"
                            autoFocus
                        />
                    ) : (
                        <TextField
                            ref={backupCodeInputRef}
                            id="backup-code"
                            label="One-Time Backup Code"
                            isRequired
                            isLabelVisible={false}
                            className="my-3 big-text-field"
                            name="code"
                            shouldFocusOnMount
                        />
                    )}

                    <Button type="submit" id="login" gaTrackingId="237605532484">
                        {submitText ?? 'Log In'}
                    </Button>
                </form>
            </Stack>
            <Heading
                headingLevel={2}
                appearance="h6"
                className="mt-5 mb-half"
                headingAlign="center"
            >
                Having trouble logging in?
            </Heading>
            <Text tag="div" align="center">
                Reach out to your manager or contact the Help Desk&apos;s Internal Help
                Line.
                {showStartOver && (
                    <div className="mt-half">
                        <Button
                            appearance="link"
                            id="logout"
                            to="/internal/account/login"
                            onClick={logoutHandler}
                            gaTrackingId="237605532484"
                        >
                            Start over
                        </Button>
                    </div>
                )}
            </Text>
        </div>
    );
}
