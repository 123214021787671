/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/***
 * DISCLAIMER
 * Code extracted, and sightly modified from: https://github.com/u4aew/react-webauthn/blob/main/src/hooks/useWebAuthn.ts
 * Repo uses ISC license: https://github.com/u4aew/react-webauthn/blob/7c0b704a5a7afb4e563289ee6ff1aaefa311c425/package.json#L17C3-L17C4 / https://en.wikipedia.org/wiki/ISC_license
 * Repo link: https://github.com/u4aew/react-webauthn/tree/main
 * */

/**
 * Interface for Assertion Options.
 *
 * @interface IAssertionOpt
 * @property {string} challenge - The challenge for the assertion.
 */
export interface IAssertionOpt {
    challenge: string;
    timeoutDuration?: number;
}

/**
 * Interface for Credential Options. Extends IAssertionOpt.
 *
 * @interface ICredentialOpt
 * @property {string} userId - The user ID.
 * @property {string} userName - The username.
 * @property {string} userDisplayName - The display name of the user.
 */
export interface ICredentialOpt extends IAssertionOpt {
    userId: string;
    userName: string;
    userDisplayName: string;
    timeoutDuration?: number;
}

// this is to decode the challenge into a uint8 array
function base64ToUint8Array(input: string | BufferSource): Uint8Array {
    let inputAsString: string = '';
    if (input instanceof ArrayBuffer) {
        inputAsString = new TextDecoder().decode(input);
    } else {
        inputAsString = input as string;
    }
    /*
    Explanation:
    Golang base64 encoding uses URL Safe encoding, which means replacing + and / with - and _, so we must replace them back.
    Golang also is not padding the length of the string, which atob expects. The string length must be a multiple of 4.
     */
    const paddedBase64 = inputAsString
        .padEnd(inputAsString.length + ((4 - (inputAsString.length % 4)) % 4), '=')
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const binaryString = atob(paddedBase64);

    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export const getCredential = async (
    credentialOpt: PublicKeyCredentialCreationOptions
): Promise<Credential | null> => {
    const challengeArray = base64ToUint8Array(credentialOpt.challenge);
    const publicKeyCredentialCreationOptions = {
        ...credentialOpt,
        user: {
            id: base64ToUint8Array(credentialOpt?.user?.id),
            name: credentialOpt?.user?.name,
            displayName: credentialOpt.user.displayName,
        },
        challenge: challengeArray,
        pubKeyCredParams: [
            {
                type: 'public-key',
                alg: -7,
            },
            {
                type: 'public-key',
                alg: -257,
            },
        ],
    } as PublicKeyCredentialCreationOptions;
    return await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions,
    });
};

/**
 * Method to get Assertion.
 *
 * @async
 * @function
 * @returns {Promise<Credential | null>} - A Promise that resolves to the assertion.
 * @param assertionOpt
 */
export const getAssertion = async (
    assertionOpt: PublicKeyCredentialRequestOptions
): Promise<Credential | null> => {
    const credentials = [];
    if (assertionOpt.allowCredentials) {
        for (const allowCredential of assertionOpt.allowCredentials) {
            credentials.push({
                ...allowCredential,
                id: base64ToUint8Array(allowCredential.id),
            });
        }
    }
    const publicKeyCredentialRequestOptions = {
        ...assertionOpt,
        allowCredentials: credentials,
        challenge: base64ToUint8Array(assertionOpt.challenge),
    } as PublicKeyCredentialRequestOptions;
    const req = {
        publicKey: publicKeyCredentialRequestOptions,
    };
    return await navigator.credentials.get(req);
};
