/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { FeatureFlag, TYPES } from 'sarsaparilla';
import { editMyProfile } from '../actions/updateUser';
import MfaEnrollmentWizardModal from '../components/MfaEnrollmentWizardModal';
import MyProfile from '../components/MyProfile/MyProfile';
import MyProfilePanel from '../components/MyProfile/MyProfilePanel';
import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';

type MyProfilePageProps = {
    history: TYPES.HISTORY;
    location: TYPES.LOCATION;
    match: TYPES.MATCH;
};

function MyProfilePage({ history, location, match }: MyProfilePageProps) {
    const [isMfaModalOpen, setIsMfaModalOpen] = React.useState<boolean>(false);

    const toggleIsMfaModalOpen = () => {
        setIsMfaModalOpen(!isMfaModalOpen);
    };

    const LegacyMyProfile = (
        <MyProfile
            history={history}
            location={location}
            match={match}
            toggleIsMfaModalOpen={toggleIsMfaModalOpen}
        />
    );

    const MyProfileV2 = (
        <MyProfilePanel
            location={location}
            match={match}
            toggleIsMfaModalOpen={toggleIsMfaModalOpen}
        />
    );

    React.useEffect(() => {
        editMyProfile(history);
    }, [history]);

    return (
        <SiteWrapper>
            <div className="page-title">
                {/* @ts-ignore */}
                <PageHeader title="My Profile" />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                <FeatureFlag flag="iaEditUserV2" fallback={LegacyMyProfile}>
                    {MyProfileV2}
                </FeatureFlag>
                <MfaEnrollmentWizardModal
                    canSkip={false}
                    hideStartOverButton={true}
                    isOpen={isMfaModalOpen}
                    onClose={toggleIsMfaModalOpen}
                />
            </div>
        </SiteWrapper>
    );
}

export default MyProfilePage;
