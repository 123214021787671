/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { FINANCE_ADMIN } from 'ui-internal-account/src/utilities/roles';
import { get } from 'lodash';
import { parseAbsolute, parseDate, toCalendarDate } from '@internationalized/date';
import { Button, DatePicker, momentToCalendarDate, Inline } from 'sarsaparilla';

import { AllLeafTypesArr } from '../../utilities/locationConstants';
import LocationPicker from '../LocationPicker';
import ActionErrorDisplay from '../ActionErrorDisplay';

const MAX_END_DATE = '2099-12-31';

export default class EditPermit extends React.Component {
    static propTypes = {
        permit: PropTypes.any,
        concessionaire: PropTypes.any,
        savePermit: PropTypes.func,
        deletePermit: PropTypes.func,
        error: PropTypes.any,
        selectedRole: PropTypes.any,
    };

    constructor(props) {
        super(props);

        this.state = {
            permit: {},
            confirmDelete: false,
        };

        this.locationSelected = this.locationSelected.bind(this);
        this.navAdd = this.navAdd.bind(this);
        this.navDelete = this.navDelete.bind(this);
    }

    componentDidUpdate(prevProps) {
        // Reset local permit if props permit has changed.
        if (this.props.permit?.permit_id !== prevProps.permit?.permit_id) {
            this.setState({ permit: {}, confirmDelete: false });
        }
    }

    // Merge props and state permit
    getPermit = (propsPermit = this.props.permit, statePermit = this.state.permit) => {
        return { ...propsPermit, ...statePermit };
    };

    navAdd() {
        this.props.savePermit(this.getPermit(), true);
    }

    navDelete() {
        if (this.state.confirmDelete) {
            this.props.deletePermit(this.getPermit());
        } else {
            this.setState({
                confirmDelete: true,
            });
        }
    }

    locationSelected(location) {
        const locFields = {};
        Object.keys(location)
            .filter((key) => key.indexOf('location') === 0)
            .forEach((key) => {
                locFields[key] = location[key];
            });

        this.setState((prev) => {
            const locatedPermit = { ...prev.permit, ...locFields };

            return { permit: locatedPermit };
        });
    }

    renderLocationSelect(label, field) {
        const id = `Mod_${field}`;
        const css = classnames({
            'concessionaire-mod-input-block': true,
            'concessionaire-mod-locselect-block': true,
        });
        const permit = this.getPermit();
        const location = {};
        Object.keys(permit)
            .filter((key) => key.indexOf('location') === 0)
            .forEach((key) => {
                location[key] = permit[key];
            });

        return (
            <div className={css}>
                <label htmlFor={id}>{label}</label>
                <LocationPicker
                    id={id}
                    selectedLocation={permit.location_id ? location : null}
                    locationSelect={this.locationSelected}
                    selectableLocationTypes={AllLeafTypesArr}
                />
            </div>
        );
    }

    renderSingleDateBlock(label, field, disabled = false) {
        const id = `Mod_${field.replace('.', '_')}`;
        const css = classnames({
            'concessionaire-mod-input-block': true,
            'concessionaire-mod-singledate-block': true,
        });
        const permit = this.getPermit();

        const rawDate = permit?.[field];
        const dateValue =
            typeof rawDate === 'string'
                ? toCalendarDate(parseAbsolute(get(permit, field), 'utc'))
                : moment.isMoment(rawDate)
                  ? momentToCalendarDate(rawDate)
                  : null;

        return (
            <div className={css}>
                <DatePicker
                    label={label}
                    id={id}
                    defaultValue={dateValue}
                    isDisabled={disabled}
                    minValue={parseDate('2000-01-01')}
                    maxValue={parseDate(MAX_END_DATE)}
                    onChange={(value, validation) => {
                        if (value && !validation.isInvalid) {
                            this.setState((prev) => {
                                return {
                                    permit: {
                                        ...prev.permit,
                                        [field]: moment.utc(
                                            value.toString(),
                                            'YYYY-MM-DD'
                                        ),
                                    },
                                };
                            });
                        }
                    }}
                />
            </div>
        );
    }

    renderDeleteButton() {
        if (
            this.props.selectedRole &&
            this.props.selectedRole.role_type === FINANCE_ADMIN
        ) {
            return (
                <Button appearance="danger" onClick={this.navDelete}>
                    {this.state.confirmDelete ? 'Confirm' : ''} Delete
                </Button>
            );
        }

        return null;
    }

    render() {
        const permit = this.getPermit();
        const canSubmit =
            permit.end_date !== null && Object.keys(this.state.permit).length !== 0;
        return (
            <div className="concessionaire-details-wrapper">
                <div className="concessionaires-header">
                    <h2 className="concessionaire-component-title h5">
                        <span className="header-main-title">
                            {this.props.permit && this.props.permit.user_id
                                ? 'Edit'
                                : 'Add'}{' '}
                            Permit for:{' '}
                            <i>{this.props.concessionaire.concessionaire_name}</i>
                        </span>
                    </h2>
                </div>

                <ActionErrorDisplay error={this.props.error} />

                <div className="concessionaires-body">
                    <div className="concessionaire-mod-inputs">
                        {this.renderLocationSelect(
                            'Select a Location',
                            'permit_location'
                        )}
                        {permit &&
                            this.renderSingleDateBlock(
                                'Permit Created',
                                'created_at',
                                true
                            )}
                        {this.renderSingleDateBlock(
                            'Permit Start Date',
                            'permit_start_date'
                        )}
                        {this.renderSingleDateBlock(
                            'Permit Expiration Date',
                            'permit_end_date'
                        )}
                    </div>
                </div>
                <div className="concessionaires-footer ia-footer-submission">
                    <div />
                    <Inline>
                        {this.renderDeleteButton()}
                        <Button
                            className="cancel-permit"
                            appearance="tertiary"
                            to="/internal/account/concessionaire-details"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="submit-permit"
                            appearance="primary"
                            onClick={this.navAdd}
                            isDisabled={!canSubmit}
                        >
                            {this.props.permit?.permit_id ? 'Update' : 'Add'}
                        </Button>
                    </Inline>
                </div>
            </div>
        );
    }
}
