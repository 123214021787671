/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const FilterBarContext = React.createContext();

// todo move to a common file
function reducer(state, action) {
    switch (action.type) {
        case 'openAtIndex':
            return {
                isModalOpen: true,
                activeIndex: action.index,
            };
        case 'close':
            return {
                ...state,
                isModalOpen: false,
            };
        case 'switchToIndex':
            return {
                ...state,
                activeIndex: action.index,
            };
        default:
            throw new Error('FacilityFilterBar says: Not a valid reducer action type');
    }
}

const initialState = {
    isModalOpen: false,
    activeIndex: null,
};

function FacilityFilterBarProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state]);

    return (
        <FilterBarContext.Provider value={value}>{children}</FilterBarContext.Provider>
    );
}

export const useFilterBarContext = () => {
    const context = useContext(FilterBarContext);
    return context;
};

FacilityFilterBarProvider.propTypes = {
    children: PropTypes.any,
};

export default FacilityFilterBarProvider;
