/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    EmailField,
    emailValidator,
    Heading,
    HelmetWrapperInternal,
    Inline,
    Spacer,
    StyledModal,
    Text,
    TextField,
    useFlags,
} from 'sarsaparilla';
import { useDispatch } from 'react-redux';
import { clearError, loginMessagesStorageKey } from '../../actions/login';
import { AvailableOidcProviders, oidcRedirect } from '../../actions/oidc';
import useOidcConfig from '../../hooks/useOidcConfig';
import { errorForLogin } from '../../utilities/errorMessages';
import LoginMethods from './loginMethods';

function LoginFormHeader({
    title,
    info,
    showLoginForm,
}: {
    title: string;
    info: string;
    showLoginForm: boolean;
}) {
    return (
        <>
            <Heading headingLevel={1} appearance="h3" className="mb-4">
                {title}
            </Heading>
            {!showLoginForm && (
                <Text fontWeight="semibold">
                    Select the login method recommended by your Agency to access the{' '}
                    <a href="/internal/account/hub">{process.env.SITE_NAME} Hub.</a>
                </Text>
            )}
            {info && (
                <Alert type="info" className="my-3">
                    {info}
                </Alert>
            )}
        </>
    );
}

type LoginFormProps = {
    loginFn: (email: string, password: string) => void;
    initialEmail: string;
    setEmailFn: (email: string) => void;
    errorMessage: string | Error;
    attempts: number;
    showConfirmEmailChange: boolean | null;
};

type ErrorMessageProps = {
    errorMessage: string | Error;
    concurrentSessionError: boolean;
    attempts: number;
    className?: string;
};

function ErrorMessage({
    errorMessage,
    concurrentSessionError,
    attempts,
    className,
}: ErrorMessageProps) {
    if (errorMessage || concurrentSessionError) {
        let err = errorForLogin(errorMessage, attempts);

        if (concurrentSessionError) {
            err =
                'You have been logged out of your current session because this account is logged in with another browser. ' +
                'Please sign in again to gain access to your account and force a logout of the other session.';
        }

        return (
            <Alert shouldFocusOnMount type="error" className={className}>
                {err}
            </Alert>
        );
    }

    return null;
}

export function LoginForm({
    loginFn,
    initialEmail,
    errorMessage,
    setEmailFn,
    attempts,
    showConfirmEmailChange,
}: LoginFormProps) {
    const { enableOidc, iaLoginShowCustomBanner } = useFlags();
    const reduxDispatch = useDispatch<any>();
    const [emailField, setEmailField] = React.useState(initialEmail);
    const [showTroubleLoggingIn, setShowTroubleLoggingIn] = React.useState(false);
    const [emailIsValid, setEmailIsValid] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [loginMessageFlags, setLoginMessageFlags] = React.useState({
        concurrentSessionError: false,
        loggedOutForInactivity: false,
    });
    const [showLoginForm, setShowLoginForm] = React.useState<boolean | null>(true);
    const oidcCfg = useOidcConfig();

    // clear prior errors since this is a fresh start
    React.useEffect(() => {
        reduxDispatch(clearError());
    }, []);

    React.useEffect(() => {
        if (enableOidc !== null && enableOidc !== undefined) {
            setShowLoginForm(!enableOidc);
        }

        const loginMessagesString = localStorage.getItem(loginMessagesStorageKey);
        if (loginMessagesString) {
            setLoginMessageFlags(JSON.parse(loginMessagesString));
        }
    }, [enableOidc]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailField(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!emailValidator(emailField).isValid) {
            setEmailIsValid(false);
            return;
        }

        setEmailIsValid(true);
        loginFn(emailField, password);
    };

    const setEmail = () => {
        setEmailFn(emailField);
    };

    const handleHubButton = () => setShowLoginForm(true);
    const handleOidcButton = () => {
        setShowLoginForm(false);

        oidcRedirect(oidcCfg, AvailableOidcProviders.loginDotGov);
    };

    const renderLoginMethods = () => {
        return (
            <LoginMethods
                handleHubButton={handleHubButton}
                handleOidcButton={handleOidcButton}
            />
        );
    };

    const renderLoginForm = () => {
        return (
            <div className="login-form-container">
                <div aria-live="assertive">
                    {!emailIsValid && (
                        <Alert type="error" className="mb-2">
                            That is not a valid email address.
                        </Alert>
                    )}
                </div>

                {errorMessage?.status === 403 && iaLoginShowCustomBanner && (
                    <Alert type={'warning'} className={'top'}>
                        All passwords were reset on March 4, 2025. If you have not already
                        done so, please click forgot password to reset your password now.
                        Be aware that your account will be locked after 3 failed attempts.
                    </Alert>
                )}
                <Spacer />

                <EmailField
                    label="Email Address"
                    isLabelVisible={false}
                    id="email"
                    onChange={handleEmailChange}
                    value={emailField}
                    placeholder="Email Address"
                />

                <TextField
                    label="Password"
                    isLabelVisible={false}
                    type="password"
                    id="password"
                    onChange={handlePasswordChange}
                    value={password}
                    placeholder="Password"
                    enableShowPassword
                />
                <ErrorMessage
                    attempts={attempts}
                    errorMessage={errorMessage}
                    concurrentSessionError={loginMessageFlags?.concurrentSessionError}
                    className="mb-3"
                />
                <ButtonGroup isStacked>
                    <Button
                        type="submit"
                        id="login"
                        gaTrackingId="819335029303"
                        className="mb-3"
                    >
                        Login
                    </Button>
                    {enableOidc && (
                        <Button
                            onClick={() => {
                                setShowLoginForm(false);
                            }}
                            appearance="tertiary"
                            type="button"
                            id="login-methods"
                            gaTrackingId="819335029303"
                            className="mt-0 mb-3"
                        >
                            Return to Sign In Options
                        </Button>
                    )}
                </ButtonGroup>
                <Inline space="xxl" alignX="center" className="mt-4">
                    <Button
                        appearance="link"
                        id="forgot-password"
                        to="/internal/account/recover-password"
                        onClick={setEmail}
                        gaTrackingId="819335029303"
                    >
                        Forgot Password?
                    </Button>
                    <Button
                        appearance="link"
                        onClick={() => setShowTroubleLoggingIn(true)}
                        gaTrackingId="819335029303"
                    >
                        Trouble Logging In?
                    </Button>
                </Inline>
                <StyledModal
                    isOpen={showTroubleLoggingIn}
                    heading="Trouble Logging In?"
                    onRequestClose={() => setShowTroubleLoggingIn(false)}
                >
                    Below is a list of possible issues that might be preventing you from
                    logging in.
                    <ul>
                        <li>Account is locked.</li>
                        <li>Account is outside of effective dates.</li>
                        <li>Did not correctly enter current password</li>
                        <li>Did not correctly enter email address for account.</li>
                        {enableOidc === true && (
                            <>
                                <li>Did not enter MFA code correctly.</li>
                                <li>MFA code expired before entry.</li>
                                <li>Used an invalid MFA backup code.</li>
                            </>
                        )}
                    </ul>
                    For assistance, contact your manager, or the help desk at (877) -
                    345-6777
                </StyledModal>
            </div>
        );
    };

    let title = 'Sign In';
    let info = '';
    if (loginMessageFlags.loggedOutForInactivity) {
        title = 'Session Timeout';
        info =
            'You have been logged out automatically due to inactivity. Login with your email and password.';
    }
    if (showConfirmEmailChange === true) {
        title = 'Confirm Email Change';
    }

    const renderContent = () => {
        if (showLoginForm === null) {
            return null;
        }

        return showLoginForm ? renderLoginForm() : renderLoginMethods();
    };

    return (
        <form onSubmit={onLogin}>
            <div className="login-container">
                <HelmetWrapperInternal title={title} />
                <LoginFormHeader
                    title={title}
                    info={info}
                    showLoginForm={showLoginForm !== null && showLoginForm}
                />
                {renderContent()}
            </div>
        </form>
    );
}
