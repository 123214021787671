/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import cx from 'classnames';
import React, { useState } from 'react';
import { LocationProps } from 'ui-communication/src/constants/types';
import { getLocationDisplayName, R1S_LOCATION_ID } from '../../utilities/locations';
import { Expander } from './Expander';
import Location from './Location';

import useIsLoggedInUserAConcessionaire from '../../hooks/useIsLoggedInUserAConcessionaire';
import useLoggedInUser from '../../hooks/useLoggedInUser';

type ExpandableLocationRowProps = {
    location: LocationProps;
    isExpanding: boolean;
    expanded: boolean;
    onExpanderChange: () => void;
    onSelect: () => void;
    selectableLocationTypes: string[];
    allowHierarchyFillSelection: boolean;
};

export function ExpandableLocationRow({
    location,
    isExpanding,
    expanded,
    onExpanderChange,
    onSelect,
    selectableLocationTypes,
    allowHierarchyFillSelection,
}: ExpandableLocationRowProps): React.ReactNode {
    const [isExpanderFocused, setIsExpanderFocused] = useState(false);
    const paddingDepthWidth = 15;

    const isUserConcessionaire = useIsLoggedInUserAConcessionaire();
    const loggedInUser = useLoggedInUser();
    const displayConcessionaireStyle =
        isUserConcessionaire && loggedInUser?.concessionaires?.length === 1;
    const replaceRootWithConcessionaire =
        allowHierarchyFillSelection &&
        location.location_id === R1S_LOCATION_ID &&
        displayConcessionaireStyle;

    const concessionaireName =
        loggedInUser?.concessionaires && loggedInUser.concessionaires.length > 0
            ? loggedInUser.concessionaires[0].concessionaire_name
            : '';

    const locationName = replaceRootWithConcessionaire
        ? concessionaireName
        : getLocationDisplayName(location, false);

    if (location.location_name === 'Loading') {
        return null;
    }

    let isExpandable = false;

    if (location.children?.length > 0) {
        isExpandable = true;
    }

    const style = {
        paddingLeft: `${paddingDepthWidth * location.depth + 5}px`,
    };

    let expanderReaderText = `Expand ${locationName}`;

    if (expanded) {
        expanderReaderText = `Collapse ${locationName}`;
    }

    const selectableByType = selectableLocationTypes
        ? selectableLocationTypes.includes(location.location_type)
        : true;
    const selectable =
        selectableByType && (!location.isHierarchyFill || allowHierarchyFillSelection);

    const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
        const button = e.target;
        if (button instanceof HTMLElement) {
            button?.focus();
        }
    };

    const handleMouseLeave = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement?.blur();
        }
    };

    return (
        <div
            className={cx('expandable-location-row', {
                highlighted: isExpanderFocused,
            })}
            style={style}
            onMouseLeave={handleMouseLeave}
        >
            <Expander
                isLoading={isExpanding}
                screenReaderText={expanderReaderText}
                visible={isExpandable}
                expanded={expanded}
                onChange={onExpanderChange}
                setFocused={setIsExpanderFocused}
            />

            <Location
                handleMouseOver={handleMouseOver}
                handleClick={onSelect}
                selectable={selectable}
                locationName={locationName}
            />
        </div>
    );
}
