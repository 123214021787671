/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { RouteComponentProps } from 'react-router-dom';

export default class navigation {
    static async push(
        url: string,
        history?: RouteComponentProps['history']
    ): Promise<void> {
        if (
            history &&
            window.location.pathname.indexOf('/internal/account') >= 0 &&
            url.indexOf('/internal/account') >= 0
        ) {
            history.push(url);
        } else {
            window.location.assign(url);
        }
    }

    static async replace(
        url: string,
        history?: RouteComponentProps['history']
    ): Promise<void> {
        if (
            history &&
            window.location.pathname.indexOf('/internal/account') >= 0 &&
            url.indexOf('/internal/account') >= 0
        ) {
            history.replace(url);
        } else {
            window.location.assign(url);
        }
    }
}
