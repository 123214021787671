/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    Button,
    ButtonGroup,
    FlexCol,
    FlexRow,
    Heading,
    Text,
    useFlags,
} from 'sarsaparilla';
import React from 'react';

type MfaEnrollmentIntroductionProps = {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isLoading: boolean;
    onOptOut: () => void;
    onUsePhysical: () => void;
    onUseTotp: () => void;
    startOver: () => void;
};

function MfaEnrollmentIntroduction({
    canSkip,
    hideStartOverButton,
    isLoading,
    onOptOut,
    onUsePhysical,
    onUseTotp,
    startOver,
}: MfaEnrollmentIntroductionProps) {
    const { iaAllowWebauthn } = useFlags();
    return (
        <>
            <Heading
                headingLevel={1}
                appearance="h4"
                headingAlign="center"
                className="mb-3"
            >
                Set Up Multi-Factor Authentication
            </Heading>

            <Text size="md" className="mt-6 mb-3" align="left">
                Multi-factor authentication will be available for use starting March 1st,
                2025 to protect your {process.env.SITE_NAME} account with an extra layer
                of security, based on federal mandate.
            </Text>

            <FlexRow>
                <FlexCol>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            isDisabled={!iaAllowWebauthn}
                            onClick={onUsePhysical}
                            appearance="tertiary"
                            type="button"
                            id="hub-method"
                            gaTrackingId="819335029303"
                            className="mb-2"
                        >
                            Use Physical Security Key
                        </Button>
                    </ButtonGroup>
                </FlexCol>
                <FlexCol>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            onClick={onUseTotp}
                            appearance="tertiary"
                            type="button"
                            id="oidc-method"
                            gaTrackingId="819335029303"
                            className="mb-2"
                        >
                            Use Mobile Authenticator App
                        </Button>
                    </ButtonGroup>
                </FlexCol>
            </FlexRow>

            <FlexRow>
                <FlexCol className="mb-3">
                    {iaAllowWebauthn ? (
                        <Text className="left">
                            Insert a secure key device (e.g. Yubikey) into your computer,
                            and then click the button above.
                        </Text>
                    ) : (
                        <Text className="left">Physical key support is coming soon.</Text>
                    )}
                </FlexCol>
                <FlexCol className="mb-3">
                    <Text className="left">
                        Download and install your agency-mandated authenticator
                        application (e.g. Microsoft Authenticator, Duo) for your phone,
                        tablet, or desktop. Then click the button above.
                    </Text>
                </FlexCol>
            </FlexRow>

            {canSkip && (
                <div className="mt-3 center">
                    <Button
                        appearance="link"
                        onClick={onOptOut}
                        isDisabled={isLoading}
                        gaTrackingId="261677614215"
                    >
                        Skip for now
                    </Button>
                </div>
            )}

            {!hideStartOverButton && (
                <Text tag="div" className="center mt-5">
                    <Button
                        href="/internal/account/login"
                        appearance="link"
                        onClick={startOver}
                        rel="noreferrer"
                        gaTrackingId="261677614215"
                    >
                        Start over
                    </Button>
                    <span>&nbsp;</span>
                    <span>and return to login page</span>
                </Text>
            )}
        </>
    );
}

export default MfaEnrollmentIntroduction;
