/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import { deleteAuthCookie, setAuthStorage } from '../utilities/internalSecurityUtil';
import * as globals from '../constants/globals';
import {
    getTokenFromStorageNoRedirect,
    MFAValidateFactors,
    navigateLogin,
    resetIdleMonitor,
} from './login';

export const discoverFactor = (code) => {
    if (code?.length === 6) {
        return MFAValidateFactors.TOTP;
    }

    return code?.length > 6 ? MFAValidateFactors.BackupCodes : MFAValidateFactors.Any;
};

export const isUserEnrolled = (user) => {
    return user.mfa_data?.factors?.length > 0;
};

export const clearSession = () => {
    localStorage.removeItem(globals.TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem('persist:root');
    if (window.sessionStorage) {
        window.sessionStorage.clear();
    }
    deleteAuthCookie();
    navigateLogin();
};

// using our own axios calls because makeAuthorizedRequest requires redux and we don't want to
const authorizedRequest = async (url, method, data) => {
    let tokenObject = {};
    try {
        tokenObject = getTokenFromStorageNoRedirect();
    } catch (e) {
        clearSession();
        throw e;
    }

    const headers = {
        Authorization: `Bearer ${tokenObject.access_token}`,
        'content-type': 'application/json; charset=utf-8',
    };

    const response = await axios({
        url,
        method,
        headers,
        data,
        credentials: 'include',
    });
    return response;
};

export const generateMfaTotp = async (data) => {
    const url = `${process.env.API}/internal/account/mfa/totp`;
    const response = await authorizedRequest(url, 'POST', data);
    return response?.data;
};

export const mfaOptOut = async () => {
    const url = `/api/internal/account/mfa/optout`;
    const response = await authorizedRequest(url, 'PATCH');
    if (response?.data) {
        // User is now logged in
        resetIdleMonitor();
    }
    return response?.data;
};

export const validateMfaFactor = async (token, factor) => {
    const url = `/api/internal/account/mfa/validate`;
    const payload = {
        token,
        factor,
    };
    const response = await authorizedRequest(url, 'POST', payload);
    if (response?.data) {
        // this is now a login response / hub session
        resetIdleMonitor();
        setAuthStorage(response.data);
    }

    return response?.data;
};

export const createMfaBackupCodes = async (userId) => {
    const url = `/api/internal/account/mfa/backup/${userId}`;
    const response = await authorizedRequest(url, 'POST');
    return response?.data?.codes;
};

export const beginRegister = async () => {
    const response = await authorizedRequest(
        '/api/internal/account/mfa/webauthn/begin',
        'POST'
    );
    return response?.data;
};

export const finishRegister = async (credentials, nickname) => {
    const payload = {
        nickname,
        device_response: credentials,
    };

    const response = await authorizedRequest(
        '/api/internal/account/mfa/webauthn/generate',
        'POST',
        payload
    );
    if (response?.data) {
        // this is now a login response / hub session
        resetIdleMonitor();
        setAuthStorage(response.data);
    }

    return response?.data;
};

export const beginValidate = async () => {
    const response = await authorizedRequest('/api/internal/account/mfa/begin', 'POST', {
        factor: 4,
    });
    return response.data;
};
