/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { FlexRow, FlexCol, TextField, HelmetWrapperInternal, Alert } from 'sarsaparilla';
import SiteWrapper from './SiteWrapper';

class TrainingLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            knowledgeArticles: [],
            knowledgeArticlesContent: [],
            articlesById: {},
        };
    }

    componentDidMount() {
        // fetch and initialize knowledge base content
        fetch(`/shared/internal-account/data/knowledgeArticles.json`, {
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((res) => {
                let json = res;
                const articlesById = {};
                json.forEach((article) => {
                    articlesById[article.id] = article;
                });
                json = orderBy(json, ['category', 'title'], ['asc', 'asc']);
                this.setState({
                    knowledgeArticles: json,
                    articlesById,
                });
            })
            .then(() => {
                // fetch and initialize knowledge base content
                fetch(`/shared/internal-account/data/knowledgeArticlesContent.json`, {
                    credentials: 'include',
                })
                    .then((response) => response.json())
                    .then((json) => {
                        json.map((a) => {
                            const item = a;
                            item.content =
                                `${item.number} ${item.kb_category} ${item.short_description} ${item.text}`.toLowerCase();
                            // add article text to article
                            if (this.state.articlesById[item.number]) {
                                this.setState((previousState) => {
                                    const newObj = { ...previousState.articlesById };
                                    newObj[item.number].text = item.text;

                                    return {
                                        ...previousState,
                                        articlesById: { ...newObj },
                                    };
                                });
                            }
                            return item;
                        });
                        this.setState({
                            knowledgeArticlesContent: json,
                        });
                    })
                    .catch((err) => {
                        // eslint-disable-next-line no-console
                        console.log(err);
                    });
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    }

    getItemDescription(desc) {
        let text = desc;
        if (text) {
            /* eslint-disable-next-line no-control-regex */
            text = text.replace(/[^\x00-\x7F]/g, '');
            if (text.length < 250) {
                return text;
            }
            text = text.substring(0, 250);
            const lastSpaceInd = text.lastIndexOf(' ');
            text = text.substring(0, lastSpaceInd).trim();
            if (!text.endsWith('.')) {
                text += '...';
            }
        }
        return text;
    }

    // fancy  in memory regex based search that supports multi word "and" type search across knowledge base content
    searchArticles = (searchText) => {
        if (!searchText || searchText === '') {
            return this.state.knowledgeArticles;
        }

        // cleanup search text
        const terms = searchText
            .replaceAll('(', ' ')
            .replaceAll(')', ' ')
            .replaceAll('"', ' ')
            .replaceAll("'", ' ')
            .replaceAll('  ', ' ')
            .split(' ');
        // construct multi word AND regex expression
        const termsDict = {};
        terms.forEach((term) => {
            termsDict[term] = true;
        });

        const matchTerms = (content) => {
            const matches = [];
            terms.forEach((term) => {
                if (content.includes(term)) matches.push(term);
            });

            return matches;
        };

        const countsById = {};
        // match article content
        const matchedContent = this.state.knowledgeArticlesContent.filter((item) => {
            const m = matchTerms(item.content);
            let matched = false;
            if (m.length >= terms.length) {
                countsById[item.number] = m.length / item.content.length;
                const matchedDict = {};
                m.forEach((w) => {
                    matchedDict[w] = true;
                });
                matched =
                    Object.keys(termsDict).length === Object.keys(matchedDict).length;
            }
            return matched;
        });
        // finally return articles that were matched
        let ret = [];
        matchedContent.forEach((item) => {
            const article = this.state.articlesById[item.number];
            if (article) {
                article.match_count = countsById[item.number];
                ret[ret.length] = article;
            }
        });
        ret = orderBy(ret, ['match_count'], ['desc']);
        return ret;
    };

    render() {
        const linkRows = this.searchArticles(this.state.searchText).map((article) => {
            let linkCell;
            if (article.link !== '') {
                linkCell = (
                    <td>
                        <a href={article.link}>{article.id}</a>
                    </td>
                );
            } else {
                linkCell = <td />;
            }
            return (
                <tr key={article.id}>
                    <td>{article.id}</td>
                    <td>{article.category}</td>
                    <td>
                        <a
                            href={`${process.env.HUB_ROOT}/resources/kb/articles/${article.id}.pdf`}
                        >
                            {article.title}
                        </a>
                        <p>{this.getItemDescription(article.text)}</p>
                    </td>
                    {linkCell}
                </tr>
            );
        });

        return (
            <SiteWrapper>
                <HelmetWrapperInternal title="Secure Training Links" />
                <h1 className="ia-contact-us-title">Secure Training Links</h1>
                <section className="ia-nav-content-block">
                    <FlexRow justifyContent="center">
                        <FlexCol lg={8}>
                            <Alert
                                hasAriaLive
                                type="info"
                                heading="Instructions"
                                className="mb-3"
                            >
                                <p>
                                    The search bar searches both the article title and the
                                    content of the articles. You may need to click the
                                    Related Link on the right to open additional
                                    information or videos.
                                </p>
                            </Alert>

                            <TextField
                                label="Search Results"
                                isLabelVisible={false}
                                aria-label="Enter what to search"
                                type="text"
                                placeholder="Search Results"
                                value={this.state.searchText}
                                onChange={(e) =>
                                    this.setState({ searchText: e.target.value })
                                }
                            />
                            <table className="knowledgebase-table">
                                <thead>
                                    <tr>
                                        <th width="14%">Article ID</th>
                                        <th width="14%">Article Category</th>
                                        <th>Article</th>
                                        <th width="14%">Related</th>
                                    </tr>
                                </thead>
                                <tbody>{linkRows}</tbody>
                            </table>
                        </FlexCol>
                    </FlexRow>
                </section>
            </SiteWrapper>
        );
    }
}

function mapDispatchToProps() {
    return {};
}

export default connect(
    (state) => ({
        sendErr: state.contact.sendErr,
        sendSuccess: state.contact.sendSuccess,
    }),
    mapDispatchToProps
)(TrainingLinks);
