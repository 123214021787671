/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import classNames from 'classnames';
import { every, find, gt, isEmpty, isEqual, map, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Heading, Icon, Select, Switch, Tooltip, TYPES, Alert } from 'sarsaparilla';
import {
    clearFacilityNoteErrors,
    clearFacilityPOCError,
    fetchFacilityInfo,
    setLocationFlag,
    submitFacilityNotes,
    submitFacilityPOC,
} from '../actions/facilityInfo';
import { fetchUsersForPOCSelection } from '../actions/fetchUsers';
import ActionErrorDisplay from '../components/ActionErrorDisplay';
import { EditableBlock } from '../components/EditableBlock';
import EditableNotes from '../components/EditableNotes';
import PageHeader from '../components/PageHeader';
import FacilityInfoPageFooter from '../components/facilityinfo/FacilityInfoPageFooter';
import { POC_ADDITIONAL, POC_PRIMARY, POC_SECONDARY } from '../constants/globals';
import {
    errorForFetchingFacilityInfo,
    errorForUpdateFacility,
} from '../utilities/errorMessages';
import { decodeLocTypeUrlKeyMap } from '../utilities/launchTiles';
import {
    AGENCY,
    REC_AREA,
    REC_AREA_DISTRICT,
    REGION,
    REGIONAL_DISTRICT,
    ROOT,
} from '../utilities/locationConstants';
import {
    ATR,
    BAH_ADMIN,
    displayStringForRoleType,
    FACILITY_MANAGER,
    highestRoleAtLocation,
    PMO,
    SUPER_USER,
} from '../utilities/roles';
import SiteWrapper from './SiteWrapper';

const propTypes = {
    match: TYPES.MATCH,
    dispatch: PropTypes.func,
    selectedRole: PropTypes.any,
    loggedInUser: PropTypes.any,
    info: PropTypes.any,
    fetchFacilityInfoError: PropTypes.bool,
    primaryPOCUser: PropTypes.any,
    secondaryPOCUser: PropTypes.any,
    additionalPOCObject: PropTypes.any,
    pocUsers: PropTypes.array,
    submitPOCErrorType: PropTypes.bool,
    notes: PropTypes.array,
    erroredNoteSubmissionIDs: PropTypes.array,
    submitFacilityPOC: PropTypes.func,
    submitFacilityNotes: PropTypes.func,
    clearFacilityPOCError: PropTypes.func,
    setCollectsMobileFees: PropTypes.func,
};

const emptyPOCUser = {
    roles: [],
    phone_numbers: [],
    first_name: '',
    last_name: '',
    email: '',
    user_id: '',
};
const emptyPOCObject = { name: '', role: '', email: '', phone: '' };

const showCollectMobileFees = (locationType, hasParkPass) => {
    switch (locationType) {
        case ROOT:
        case AGENCY:
        case REGION:
        case REGIONAL_DISTRICT:
            return false;
        case REC_AREA:
        case REC_AREA_DISTRICT:
            return hasParkPass;
        default:
            return true;
    }
};

const showCallCenterNotes = (locationType) => {
    switch (locationType) {
        case ROOT:
        case AGENCY:
        case REGION:
        case REGIONAL_DISTRICT:
            return false;
        default:
            return true;
    }
};

const renderLocationType = (locationType) => {
    return <div className="ia-infostack-text">{locationType}</div>;
};

const renderBlock = (forEdit, forError, doClear, doUpdate) => {
    return (
        <EditableBlock
            forEdit={forEdit}
            forError={forError}
            doClear={doClear}
            doUpdate={doUpdate}
        />
    );
};

const renderToggleSwitch = (collectsMobileFees, toggleCollectsMobileFees) => {
    return (
        <div className="mt-1">
            <Switch
                id="collect-mobile-fees"
                label={collectsMobileFees ? 'Yes' : 'No'}
                isSelected={collectsMobileFees}
                onChange={toggleCollectsMobileFees}
            />
        </div>
    );
};

const renderPaymentPref = (acceptsPaper, acceptsCard, lockbox, infoPair) => {
    return (
        <div className="ia-infostack-sub">
            <p>
                To request any changes to these settings, please contact your Program
                Manager.
            </p>
            {acceptsPaper}
            {acceptsCard}
            {lockbox}
            {infoPair}
        </div>
    );
};

const renderFacilityDiv = (locationName, parentFieldNames) => {
    return (
        <>
            <div className="ia-infostack-text">{locationName}</div>
            <div className="ia-infostack-subtext">{parentFieldNames}</div>
        </>
    );
};

const renderNotesDiv = (notes, onNotesChange, onNotesCancelClick, label, id) => {
    return (
        <>
            <EditableNotes
                notes={notes}
                onNotesChange={onNotesChange}
                onNotesCancelClick={onNotesCancelClick}
                noteType="display"
                idSuffix="optional-notes"
                label={label}
                id={id}
            />
            <br />
        </>
    );
};

export class FacilityInfoPage extends React.PureComponent {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.state = {
            deferredActions: [],
            primaryPOCUser: props.primaryPOCUser,
            secondaryPOCUser: props.secondaryPOCUser,
            additionalPOCObject: props.additionalPOCObject,
            temp: {
                notes: [],
                additionalPOCObject: emptyPOCObject,
            },
            notes: props.notes,
            unsavedChanges: 0,
            primaryPOCIsChanged: false,
            secondaryPOCIsChanged: false,
            additionalPOCIsChanged: false,
            collectsMobileFees: !!props.info.collects_mobile_fees,
        };

        this.userSelect = this.userSelect.bind(this);
        this.submitFacilityInfoChanges = this.submitFacilityInfoChanges.bind(this);
        this.nukeFacilityInfoChanges = this.nukeFacilityInfoChanges.bind(this);
        this.updateDisplayNotes = this.updateDisplayNotes.bind(this);
        this.hideMessageBanners = this.hideMessageBanners.bind(this);
        this.getPOCUserList = this.getPOCUserList.bind(this);
        this.toggleCollectsMobileFees = this.toggleCollectsMobileFees.bind(this);
    }

    componentDidMount() {
        const {
            dispatch,
            match: {
                params: { locationType, locationId },
            },
        } = this.props;

        if (locationType && locationId) {
            let locationTypeToUse = decodeLocTypeUrlKeyMap[locationType];
            if (locationType === 'Campground') {
                // TODO: I hate this solution, but need to fix this page quickly and the proper fix conflicts with
                // a requirement from camping.
                // see: ui-internal-account/src/utilities/launchTiles.jsx:278
                locationTypeToUse = 'Campground';
            }

            const location = {
                location_id: locationId,
                location_type: locationTypeToUse,
            };

            dispatch(fetchFacilityInfo(location, true, false));

            dispatch(fetchUsersForPOCSelection([location]));
        }
    }

    componentDidUpdate(prevProps) {
        let doUpdate = false;
        const newState = { temp: {} };

        if (this.props.primaryPOCUser.user_id !== prevProps.primaryPOCUser.user_id) {
            doUpdate = true;
            newState.primaryPOCUser = this.props.primaryPOCUser;
        }
        if (this.props.secondaryPOCUser.user_id !== prevProps.secondaryPOCUser.user_id) {
            doUpdate = true;
            newState.secondaryPOCUser = this.props.secondaryPOCUser;
        }
        if (!isEqual(this.props.additionalPOCObject, prevProps.additionalPOCObject)) {
            doUpdate = true;
            newState.additionalPOCObject = this.props.additionalPOCObject;
        }
        if (!isEqual(this.props.notes, prevProps.notes)) {
            doUpdate = true;
            newState.notes = this.props.notes;
        }
        if (!isEqual(this.props.submitPOCErrorType, prevProps.submitPOCErrorType)) {
            doUpdate = true;
            newState.submitPOCErrorType = this.props.submitPOCErrorType;
        }
        if (
            !isEqual(
                this.props.info.collects_mobile_fees,
                prevProps.info.collects_mobile_fees
            )
        ) {
            doUpdate = true;
            newState.collectsMobileFees = this.props.info.collects_mobile_fees;
        }

        if (doUpdate) {
            if (isEmpty(newState.temp.additionalPOCObject)) {
                newState.temp.additionalPOCObject = this.props.additionalPOCObject;
            }
            this.setState(newState);
        }
    }

    getRenderRules() {
        const out = [];

        out.push({
            title: 'Facility ID',
            transform: () => this.wrapText(this.props.info.location_id),
        });

        out.push({
            title: 'Facility Name',
            transform: () => {
                return this.renderFacilityName(this.props.info);
            },
        });

        out.push({
            title: 'Type',
            transform: () => renderLocationType(this.props.info.location_type),
        });

        out.push({
            title: 'Alternate Names',
            transform: () => this.wrapText(this.props.info.alternate_names),
        });

        out.push({
            title: 'Hierarchy',
            transform: () => {
                if (!this.props.info.location_path) {
                    return null;
                }
                const directParentId = this.props.info.location_path.filter(
                    (loc) => loc.location_id === this.props.info.location_id
                )[0].location_parent_id;

                return this.props.info.location_path.map((loc) => {
                    return this.props.info.location_id === loc.location_id ? (
                        ''
                    ) : (
                        <Tooltip
                            content={`${loc.location_id} | ${loc.location_name}`}
                            position="bottom"
                            key={`tooltip-${loc.location_id}`}
                        >
                            <span>
                                {loc.location_description}{' '}
                                {directParentId === loc.location_id ? '' : '> '}
                            </span>
                        </Tooltip>
                    );
                });
            },
        });

        out.push({
            title: 'Concessionaire Operated',
            transform: () => {
                const value = this.props.info.concessionaire_operated;
                if (typeof value === 'boolean') {
                    return value ? 'Yes' : 'No';
                }
                return null;
            },
        });

        out.push({
            title: 'Primary POC',
            transform: () => this.renderPocSelector(POC_PRIMARY),
        });

        out.push({
            title: 'Secondary POC',
            transform: () => this.renderPocSelector(POC_SECONDARY),
        });

        out.push({
            title: 'Additional POC',
            transform: () =>
                renderBlock(
                    this.state.temp.additionalPOCObject,
                    this.props.submitPOCErrorType === POC_ADDITIONAL,
                    this.props.clearFacilityPOCError,
                    (poc) => {
                        if (!this.state.additionalPOCIsChanged) {
                            this.incrementUnsaved();
                        }

                        this.setState((prevState) => {
                            return {
                                additionalPOCIsChanged: true,
                                additionalPOCObject: poc,
                                temp: { ...prevState.temp, additionalPOCObject: poc },
                            };
                        });
                    }
                ),
        });

        if (showCallCenterNotes(this.props.info.location_type)) {
            out.push({
                title: 'Mandatory Call Center Read',
                transform: () => {
                    const notes = this.state.notes.filter((note) => note.mandatory);
                    const errors = this.props.erroredNoteSubmissionIDs.filter(
                        (error) => error.mandatory
                    );

                    return this.renderNotes(notes, errors, true);
                },
            });

            out.push({
                title: 'Optional Call Center Notes',
                transform: () => {
                    const notes = this.state.notes.filter((note) => !note.mandatory);
                    const errors = this.props.erroredNoteSubmissionIDs.filter(
                        (error) => !error.mandatory
                    );

                    return this.renderNotes(notes, errors, false);
                },
            });
        }

        out.push({
            title: 'Payment and Finance Preferences',
            transform: () => {
                const object = this.props.info.payment_preferences;
                if (object) {
                    let qrCodeToggle = this.wrapText(
                        this.state.collectsMobileFees ? 'Yes' : 'No'
                    );
                    const roleAtLocation = highestRoleAtLocation(
                        this.props.loggedInUser.roles,
                        this.props.selectedRole.location
                    );
                    if (
                        [PMO, ATR, SUPER_USER, BAH_ADMIN].includes(
                            roleAtLocation?.role_type
                        )
                    ) {
                        qrCodeToggle = renderToggleSwitch(
                            this.state.collectsMobileFees,
                            this.toggleCollectsMobileFees
                        );
                    }
                    const mobileFees = showCollectMobileFees(
                        this.props.info.location_type,
                        this.props.info.has_park_pass
                    )
                        ? this.renderInfoPair('Collects Mobile Fees', qrCodeToggle, 3)
                        : null;
                    return renderPaymentPref(
                        this.renderInfoPair(
                            'Accepts Paper Payments:',
                            this.wrapText(object.paper_payments ? 'Yes' : 'No'),
                            3
                        ),
                        this.renderInfoPair(
                            'Accepts Card Payments:',
                            this.wrapText(object.cc_payments ? 'Yes' : 'No'),
                            3
                        ),
                        this.renderInfoPair(
                            'Lockbox Field Deposits',
                            this.wrapText(object.use_r1s_lockbox ? 'Yes' : 'No'),
                            3
                        ),
                        mobileFees
                    );
                }
                return null;
            },
        });

        return out;
    }

    getLocationParentFromPath(path) {
        let parentName = '';
        let parentAgency = '';
        if (path && path.length > 1) {
            const parentIndex = path.length - 2;
            parentName = path[parentIndex].location_description;
            parentAgency = path[parentIndex].location_agency_name;
        }
        return { name: parentName, agency: parentAgency };
    }

    getLocationNameFromPath(path) {
        if (path) {
            const locationIndex = path.length - 1;
            return path[locationIndex].location_description;
        }
        return '';
    }

    getPOCUserList() {
        const self = this.props.loggedInUser;
        const selectedLoc = this.props.selectedRole.location;
        if (self && selectedLoc) {
            const highestRole = highestRoleAtLocation(self.roles, selectedLoc);
            const isFMPlusAtLoc =
                highestRole &&
                (highestRole.role_type === SUPER_USER ||
                    highestRole.role_type === PMO ||
                    highestRole.role_type === ATR ||
                    highestRole.role_type === FACILITY_MANAGER);
            const hasRoleToAssign = self.roles.find((r) => {
                return (
                    r.location.location_id === selectedLoc.location_id &&
                    r.location.location_type === selectedLoc.location_type
                );
            });
            if (isFMPlusAtLoc && hasRoleToAssign) {
                return [this.props.loggedInUser, ...this.props.pocUsers];
            }
        }

        return this.props.pocUsers;
    }

    cancelDisplayNotes = () => {
        this.setState((prevState) => {
            const prevNotes = [...prevState.notes];
            return {
                ...prevState,
                notes: prevNotes,
            };
        });
    };

    getDisplayNotes = (notes) => {
        let sortedNotes = [];
        const hasSeqNum = every(notes, (n) => gt(n.seq_num, 0));

        if (hasSeqNum) {
            sortedNotes = sortBy(notes, (n) => {
                return n.seq_num;
            });
        } else {
            sortedNotes = map(notes, (n, i) => {
                return {
                    ...n,
                    is_updated: true,
                    seq_num: i + 1,
                };
            });
        }

        const displayNotes = map(sortedNotes, (n) => {
            return {
                active: true,
                note_text: n.note,
                seq_num: n.seq_num,
                note_id: n.note_id,
                note_type: 'display',
            };
        });

        return displayNotes;
    };

    // We nuke them from space, specifically
    nukeFacilityInfoChanges() {
        this.zeroUnsaved();
        this.setState((prevState) => {
            return {
                primaryPOCUser: this.props.primaryPOCUser,
                secondaryPOCUser: this.props.secondaryPOCUser,
                additionalPOCObject: this.props.additionalPOCObject,
                notes: this.props.notes,
                primaryPOCIsChanged: false,
                secondaryPOCIsChanged: false,
                additionalPOCIsChanged: false,
                temp: {
                    ...prevState.temp,
                    additionalPOCObject: this.props.additionalPOCObject,
                },
            };
        });
    }

    wrapText(text) {
        return <div className="ia-infostack-text">{text}</div>;
    }

    incrementUnsaved() {
        this.setState((prevState) => {
            return { ...prevState, unsavedChanges: prevState.unsavedChanges + 1 };
        });
    }

    decrementUnsaved() {
        this.setState((prevState) => {
            return { ...prevState, unsavedChanges: prevState.unsavedChanges - 1 };
        });
    }

    zeroUnsaved() {
        this.setState({
            unsavedChanges: 0,
            collectsMobileFees: this.props.info.collects_mobile_fees,
        });
    }

    userSelect(userId, type) {
        const user =
            this.getPOCUserList().find((u) => u.user_id === userId) || emptyPOCUser;
        let unsavedCount = this.state.unsavedChanges;
        if (type === POC_PRIMARY) {
            if (!this.state.primaryPOCIsChanged) unsavedCount += 1;
            this.setState({
                unsavedChanges: unsavedCount,
                primaryPOCIsChanged: true,
                primaryPOCUser: user,
            });
        } else if (type === POC_SECONDARY) {
            if (!this.state.secondaryPOCIsChanged) unsavedCount += 1;
            this.setState({
                unsavedChanges: unsavedCount,
                secondaryPOCIsChanged: true,
                secondaryPOCUser: user,
            });
        }

        this.props.clearFacilityPOCError();
    }

    submitFacilityInfoChanges() {
        this.zeroUnsaved();
        window.scrollTo(0, 0);

        const location = this.props.selectedRole.location;

        if (
            this.state.primaryPOCIsChanged ||
            this.state.secondaryPOCIsChanged ||
            this.state.additionalPOCIsChanged
        ) {
            const primaryUser = this.state.primaryPOCUser || this.props.primaryPOCUser;
            const secondaryUser =
                this.state.secondaryPOCUser || this.props.secondaryPOCUser;
            const additionalObject =
                this.state.additionalPOCObject || this.props.additionalPOCObject;
            this.props.submitFacilityPOC(
                location,
                primaryUser,
                secondaryUser,
                additionalObject
            );
        }

        if (this.state.collectsMobileFees !== this.props.info.collects_mobile_fees) {
            this.props.setCollectsMobileFees(location, this.state.collectsMobileFees);
        }

        this.state.deferredActions.forEach((obj) => obj.action(...obj.args));

        this.setState((prevState) => {
            return {
                ...prevState,
                deferredActions: [],
                primaryPOCIsChanged: false,
                secondaryPOCIsChanged: false,
                additionalPOCIsChanged: false,
                collectsMobileFees: prevState.collectsMobileFees, // I don't know why this is important, but the state gets lost otherwise.
            };
        });
    }

    toggleCollectsMobileFees() {
        if (this.state.collectsMobileFees === this.props.info.collects_mobile_fees) {
            this.incrementUnsaved();
        } else {
            this.decrementUnsaved();
        }

        this.setState((prevState) => {
            return { ...prevState, collectsMobileFees: !prevState.collectsMobileFees };
        });
    }

    updateDisplayNotes(newNotes, notes, mandatory) {
        const updatedNotes = [];
        const removedNotes = [];
        const createdNotes = [];
        const previousNotes = [];

        newNotes.forEach((text, i) => {
            const existingNote = find(notes, (n) => isEqual(n.note_id, text.note_id));
            const submit = {
                ...existingNote,
                note: text.note_text,
                seq_num: text.seq_num,
                submissionId: i,
            };
            if (text.is_deleted) {
                removedNotes.push(submit);
            } else if (text.is_added) {
                createdNotes.push({
                    note_id: null,
                    submissionId: i,
                    note: text.note_text,
                    seq_num: text.seq_num,
                    mandatory,
                });
            } else if (text.is_updated || !isEqual(text.seq_num, existingNote.seq_num)) {
                updatedNotes.push(submit);
                previousNotes.push(submit);
            } else {
                previousNotes.push(submit);
            }
        });

        if (updatedNotes.length || removedNotes.length || createdNotes.length) {
            this.incrementUnsaved();

            const location = this.props.selectedRole.location;

            const deferredAction = {
                action: this.props.submitFacilityNotes,
                args: [location, updatedNotes, removedNotes, createdNotes],
            };

            this.setState((prevState) => {
                return {
                    ...prevState,
                    deferredActions: prevState.deferredActions.concat([deferredAction]),
                    notes: this.collectNotesForDisplay(
                        prevState.notes,
                        previousNotes,
                        createdNotes,
                        mandatory
                    ),
                };
            });
        }
    }

    collectNotesForDisplay(globalNotes, previousNotes, createdNotes, mandatory) {
        const displayNotes = globalNotes.filter((note) => note.mandatory !== mandatory);
        return displayNotes.concat(previousNotes).concat(createdNotes);
    }

    hideMessageBanners() {
        this.setState({
            showFailureMessage: false,
            showSuccessMessage: false,
        });
        this.props.clearFacilityPOCError();
    }

    renderNotes = (notes, errors, mandatory) => {
        //TODO handle the errors var
        const displayNotes = this.getDisplayNotes(notes);

        const label = mandatory ? 'Mandatory Notes' : 'Optional Notes';
        const id = mandatory ? 'editable-mandatory-notes' : 'editable-optional-notes';

        return renderNotesDiv(
            displayNotes,
            (newNotes) => this.updateDisplayNotes(newNotes, notes, mandatory),
            this.cancelDisplayNotes,
            label,
            id
        );
    };

    successUI() {
        if (this.props.submitPOCErrorType === false) {
            return (
                <Alert shouldFocusOnMount type="success">
                    {`Your changes were successfully saved.`}
                </Alert>
            );
        }

        return null;
    }

    renderFacilityName(info) {
        const parent = this.getLocationParentFromPath(info.location_path);
        const locationName = this.getLocationNameFromPath(info.location_path);
        const parentFieldNames = [];

        if (parent?.name) {
            parentFieldNames.push(parent.name);
        }

        if (parent?.agency) {
            parentFieldNames.push(parent.agency);
        }

        return renderFacilityDiv(locationName, parentFieldNames.join(', '));
    }

    renderPocSelector(type) {
        let displayType = type;
        displayType = displayType.replace('POC_', '');
        displayType =
            displayType.charAt(0).toUpperCase() + displayType.slice(1).toLowerCase();

        const label = `Assign a ${displayType} POC`;
        const poc =
            type === POC_PRIMARY
                ? this.state.primaryPOCUser
                : this.state.secondaryPOCUser;
        const locKey = `${this.props.selectedRole.location_id}_${this.props.selectedRole.location_type}`;
        const locRoles = poc.roles.filter(
            (r) => `${r.location_id}_${r.location_type}` === locKey
        );
        const mobilePhones = poc.phone_numbers
            ? poc.phone_numbers.filter((p) => p.type === 'cell')
            : [];

        const name = `${poc.first_name} ${poc.last_name}`;
        const email = poc.email;

        let useThisRoleForStringMapping;
        if (locRoles.length) {
            useThisRoleForStringMapping = locRoles[0];
        } else if (poc.roles.length) {
            useThisRoleForStringMapping = poc.roles[0];
        }

        let role = '--';
        if (useThisRoleForStringMapping) {
            role = displayStringForRoleType(useThisRoleForStringMapping.role_type);
        }

        let useThisPhoneForStringFormatting;
        if (mobilePhones.length) {
            useThisPhoneForStringFormatting = mobilePhones[0];
        } else if (poc?.phone_numbers?.length) {
            useThisPhoneForStringFormatting = poc.phone_numbers[0];
        }

        let phone = '--';
        if (useThisPhoneForStringFormatting) {
            const phoneRegExp = new RegExp(/\d{10}/);
            const phoneString = useThisPhoneForStringFormatting.phone;
            phone = phoneRegExp.test(phoneString)
                ? phoneString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3')
                : phoneString;

            const phoneExt = useThisPhoneForStringFormatting.ext;
            if (phoneExt.length) {
                phone += ` #${phoneExt}`;
            }
        }

        const userOpts = this.getPOCUserList()
            .filter((u) => {
                const other =
                    type === POC_PRIMARY
                        ? this.state.secondaryPOCUser
                        : this.state.primaryPOCUser;
                return u.user_id !== other.user_id;
            })
            .map((u) => {
                return {
                    value: u.user_id,
                    label: `${u.first_name} ${u.last_name}`,
                };
            })
            .sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });

        const unset = poc.user_id.length === 0;
        const detailsCss = classNames({
            'ia-infostack-poc-details': true,
            'ia-infostack-poc-details-unset': unset,
        });

        return (
            <div className="ia-infostack-poc ia-infostack-poc-primary">
                <div className="ia-infostack-control">
                    <Select
                        id={`POC_${type}`}
                        label={label}
                        options={userOpts}
                        onChange={(e) => this.userSelect(e.target.value, type)}
                        placeholder={`Select ${displayType} POC...`}
                        value={poc.user_id}
                    />
                    <button
                        type="button"
                        className="ia-editable-button-delete"
                        aria-label="Click to delete"
                        disabled={unset}
                        onClick={() => this.userSelect(null, type)}
                    >
                        <Icon iconName="close-circle-outline" />
                    </button>
                </div>
                <div className={detailsCss}>
                    {this.renderInfoPair('Name', unset ? 'N/A' : this.wrapText(name), 3)}
                    {this.renderInfoPair('Role', unset ? 'N/A' : this.wrapText(role), 3)}
                    {this.renderInfoPair(
                        'Phone Number',
                        unset ? 'N/A' : this.wrapText(phone),
                        3
                    )}
                    {this.renderInfoPair(
                        'Email',
                        unset ? 'N/A' : this.wrapText(email),
                        3
                    )}
                </div>
            </div>
        );
    }

    renderInfoPair(title, body, heading) {
        return (
            <div
                className="ia-infostack-row"
                key={`info-pair-${title.toLowerCase().replace(/\s/, '')}`}
            >
                <Heading headingLevel={heading} className="ia-infostack-title">
                    {title}
                </Heading>
                <div className="ia-infostack-body">{body}</div>
            </div>
        );
    }

    render() {
        const rules = this.getRenderRules();
        const rows = rules.map((r) => {
            return this.renderInfoPair(r.title, r.transform(), 3);
        });
        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader title="Facility Information" />
                </div>
                {this.successUI()}
                <ActionErrorDisplay
                    error={this.props.submitPOCErrorType}
                    errorStringMapping={errorForUpdateFacility}
                />
                <div id="FacilityInfo" className="page-content wrapper">
                    {this.props.fetchFacilityInfoError && (
                        <Alert
                            shouldFocusOnMount
                            type="error"
                            className="main-fetch-error"
                        >
                            {errorForFetchingFacilityInfo()}
                        </Alert>
                    )}
                    {!this.props.fetchFacilityInfoError && (
                        <div className="ia-infostack">{rows}</div>
                    )}
                </div>
                <FacilityInfoPageFooter
                    unsavedCount={this.state.unsavedChanges}
                    formIsUnmodified={this.state.unsavedChanges === 0}
                    onSubmit={this.submitFacilityInfoChanges}
                    onAbort={this.nukeFacilityInfoChanges}
                />
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.user,
        selectedRole: state.selectedLocationRole.role,
        info: state.facilityInfo.info,
        fetchFacilityInfoError: state.facilityInfo.fetchFacilityInfoError,

        primaryPOCUser: state.facilityInfo.primaryPOCUser,
        secondaryPOCUser: state.facilityInfo.secondaryPOCUser,
        additionalPOCObject: state.facilityInfo.additionalPOCObject,
        submitPOCErrorType: state.facilityInfo.submitPOCErrorType,
        pocUsers: state.fetchUsers.userResults,

        notes: state.facilityInfo.notes,
        erroredNoteSubmissionIDs: state.facilityInfo.erroredNoteSubmissionIDs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        submitFacilityPOC: (location, primaryUser, secondaryUser, additionalObject) => {
            dispatch(
                submitFacilityPOC(location, primaryUser, secondaryUser, additionalObject)
            );
        },
        submitFacilityNotes: (location, update, remove, create) =>
            dispatch(submitFacilityNotes(location, update, remove, create)),
        clearFacilityPOCError: () => dispatch(clearFacilityPOCError()),
        clearFacilityNoteErrors: () => dispatch(clearFacilityNoteErrors()),
        setCollectsMobileFees: (location, value) =>
            dispatch(setLocationFlag(location, 'collects_mobile_fees', value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityInfoPage);
