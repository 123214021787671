/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './mfaEnrollment/Mfa.scss';

import download from 'downloadjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, getSanitizedRedirectUrl, StyledModal, TextBlock } from 'sarsaparilla';

import { AppDispatch } from '../../dev/store';
import { doneLoading, startLoading } from '../actions/loading';
import { loggedIn, logOut, navigateLogin } from '../actions/login';
import {
    createMfaBackupCodes,
    discoverFactor,
    generateMfaTotp,
    mfaOptOut,
    validateMfaFactor,
} from '../actions/mfa';
import { User } from '../constants/types';
import {
    errorForMfaBackupCodes,
    errorForMfaFactor,
    errorForMfaOptOut,
    errorForMfaTotp,
} from '../utilities/errorMessages';
import MfaEnrollmentBackupCodes from './mfaEnrollment/MfaEnrollmentBackupCodes';
import MfaEnrollmentIntroduction from './mfaEnrollment/MfaEnrollmentIntroduction';
import MfaEnrollmentPhysicalKey from './mfaEnrollment/MfaEnrollmentPhysicalKey';
import MfaEnrollmentSetupScan from './mfaEnrollment/MfaEnrollmentSetupScan';
import MfaEnrollmentTotpIntroduction from './mfaEnrollment/MfaEnrollmentTotpIntroduction';
import MfaEnrollmentVerification from './mfaEnrollment/MfaEnrollmentVerification';

type MfaEnrollmentWizardModalProps = {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isOpen?: boolean;
    isEmbedded?: boolean;
    onClose?: () => void;
};

type MfaEnrollmentWizardModalState = {
    mfa: {
        manualKey: string;
        qrCode: string;
        backupCodes: Array<string>;
        generateMfaTotpError: boolean;
        patchMfaOptOutError: boolean;
        validateMfaFactorError: boolean;
        createMfaBackupCodesError: boolean;
        error: string;
        isLoading: boolean;
    };
    loading: {
        display: boolean;
    };
};

const PageIndexes = {
    Start: 'START',
    TotpIntro: 'TOTP_INTRO',
    TotpSetupScan: 'TOTP_SCAN',
    TotpSetupVerify: 'TOTP_VERIFY',
    BackupCodes: 'BACKUP_CODES',
    PhysicalKey: 'PHYSICAL',
};

function ErrorMessage(message: string) {
    if (message === '') {
        return null;
    }

    return (
        <Alert shouldFocusOnMount type="error" className="mb-2">
            {message}
        </Alert>
    );
}

export function MfaEnrollmentWizardModal({
    canSkip,
    hideStartOverButton,
    isOpen,
    isEmbedded,
    onClose,
}: MfaEnrollmentWizardModalProps) {
    const history = useHistory();
    const [step, setStep] = useState<string>(PageIndexes.Start);
    const [userId, setUserId] = useState<string>('');

    const [manualKey, setManualKey] = useState<string>('');
    const [qrCode, setQrCode] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const isLoading = useSelector(
        (reduxState: MfaEnrollmentWizardModalState) => reduxState.loading?.display
    );

    const dispatch = useDispatch<AppDispatch>();

    const clearState = () => {
        setManualKey('');
        setQrCode('');
        setErrorMessage('');
    };

    // any time the page changes to a different step, clear the error state
    useEffect(() => {
        setErrorMessage('');
    }, [step]);

    const handleClose = () => {
        setStep(PageIndexes.Start);
        if (onClose) onClose();
    };

    const optOut = async () => {
        const redirect = getSanitizedRedirectUrl();
        try {
            const response = await mfaOptOut();

            dispatch(loggedIn(response, redirect, history, false));
        } catch (e) {
            console.trace('Error while opting out of MFA', e);
            setErrorMessage(errorForMfaOptOut());
        } finally {
            handleClose();
        }
    };

    const usePhysical = () => {
        setStep(PageIndexes.PhysicalKey);
    };

    const physicalRegistered = (response: { user?: User }) => {
        setUserId(response?.user?.user_id as string);
        setStep(PageIndexes.BackupCodes);
    };

    const useTotp = () => {
        setStep(PageIndexes.TotpIntro);
    };

    const generate = async () => {
        dispatch(startLoading());
        try {
            const response = await generateMfaTotp();
            setManualKey(response.manual_key);
            setQrCode(response.qr_code);
            setStep(PageIndexes.TotpSetupScan);
        } catch {
            setErrorMessage(errorForMfaTotp());
        } finally {
            dispatch(doneLoading());
        }
    };

    const validate = async (code: string) => {
        // this is what happens after inputting a 6-digit code
        const factorType = discoverFactor(code);
        dispatch(startLoading());
        try {
            // This is a Login Response / hub session
            const response = await validateMfaFactor(code, factorType);
            setUserId(response?.user?.user_id);
            setStep(PageIndexes.BackupCodes);
        } catch {
            // if (e?.status === 409) {
            // in case we want to do anything different for rejected code
            // }
            setErrorMessage(errorForMfaFactor());
        } finally {
            dispatch(doneLoading());
        }
    };

    const scanNextStep = () => {
        setStep(PageIndexes.TotpSetupVerify);
    };

    const createBackupCodes = async () => {
        dispatch(startLoading());
        try {
            const codes = await createMfaBackupCodes(userId);
            download(codes.join('\n'), 'mfa_backup_codes.txt', 'text/plain');
        } catch {
            setErrorMessage(errorForMfaBackupCodes());
        } finally {
            dispatch(doneLoading());
        }
    };

    const complete = () => {
        if (isOpen) {
            handleClose();
        }
        if (isEmbedded) {
            navigateLogin('/internal/account/hub');
        }
    };

    const startOver = () => {
        clearState();
        dispatch(logOut(window.location.pathname));
    };

    const renderStep = () => {
        switch (step) {
            case PageIndexes.PhysicalKey:
                return (
                    <MfaEnrollmentPhysicalKey
                        canSkip={canSkip}
                        isLoading={isLoading}
                        onOptOut={optOut}
                        startOver={startOver}
                        onDone={physicalRegistered}
                        setErrorMessage={setErrorMessage}
                        hideStartOverButton={hideStartOverButton}
                    />
                );
            case PageIndexes.TotpIntro:
                return (
                    <MfaEnrollmentTotpIntroduction
                        canSkip={canSkip}
                        hideStartOverButton={hideStartOverButton}
                        isLoading={isLoading}
                        onOptOut={optOut}
                        nextStep={generate}
                        startOver={startOver}
                    />
                );
            case PageIndexes.TotpSetupScan:
                return (
                    <MfaEnrollmentSetupScan
                        hideStartOverButton={hideStartOverButton}
                        mfaManualEntry={manualKey}
                        qrCode={qrCode}
                        nextStep={scanNextStep}
                        startOver={startOver}
                    />
                );
            case PageIndexes.TotpSetupVerify:
                return (
                    <MfaEnrollmentVerification
                        isLoading={isLoading}
                        nextStep={validate}
                        startOver={startOver}
                    />
                );
            case PageIndexes.BackupCodes:
                return (
                    <MfaEnrollmentBackupCodes
                        onDownload={createBackupCodes}
                        onClose={complete}
                    />
                );
            default:
                return (
                    <MfaEnrollmentIntroduction
                        canSkip={canSkip}
                        hideStartOverButton={hideStartOverButton}
                        isLoading={isLoading}
                        onOptOut={optOut}
                        onUsePhysical={usePhysical}
                        onUseTotp={useTotp}
                        startOver={startOver}
                    />
                );
        }
    };

    const renderContainer = () => {
        return (
            <>
                {ErrorMessage(errorMessage)}
                <div className="ia-mfa-wizard py-1 px-3">
                    <TextBlock width="xl">{renderStep()}</TextBlock>
                </div>
            </>
        );
    };

    if (isEmbedded) {
        return renderContainer();
    }

    return (
        <StyledModal
            size="sm"
            isOpen={isOpen ?? false}
            onRequestClose={handleClose}
            shouldShowCloseButton={false}
        >
            {renderContainer()}
        </StyledModal>
    );
}

export default MfaEnrollmentWizardModal;
