/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './login.scss';
import React from 'react';
import {
    Box,
    Button,
    FormValidationAlert,
    Heading,
    RadioToggle,
    Stack,
    Text,
    TextField,
    ValidationState,
} from 'sarsaparilla';
import { beginValidate, clearSession } from '../../actions/mfa';
import { getAssertion } from '../../utilities/webauthn';

interface MfaValidateWebauthnFormProps {
    submitFn: (loginResponse: any) => void;
    codeSubmitFn: (code: string) => void;
}

export function MfaValidateWebauthnForm({
    submitFn,
    codeSubmitFn,
}: MfaValidateWebauthnFormProps) {
    const [options, setOptions] =
        React.useState<null | PublicKeyCredentialRequestOptions>(null);
    const [ready, setReady] = React.useState<boolean>(false);
    const [inputValidation, setInputValidation] = React.useState<
        ValidationState | null | undefined
    >(null);

    const logoutHandler = () => {
        clearSession();
    };
    const backupCodeInputRef = React.useRef<TextField>(null);

    const radioToggleOptions = [
        {
            label: 'Security Key',
            value: 'securitykey',
        },
        {
            label: 'Backup Code',
            value: 'backupcode',
        },
    ];

    const [selected, setSelected] = React.useState(radioToggleOptions[0].value);

    // begin the process on page load
    React.useEffect(() => {
        beginValidate()
            .then((beginValidateResponse) => {
                setOptions(beginValidateResponse?.options);
                setReady(true);
            })
            .catch((e) => {
                clearSession();
            });
    }, []);

    const onCodeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const code = formData.get('code') as string;

        const validation = backupCodeInputRef.current?.validate() as ValidationState;

        setInputValidation(validation);

        if (code && !validation?.isInvalid) {
            codeSubmitFn(code);
        }
    };

    const renderBackupCodeOption = () => {
        return (
            <form
                className="center-vertical full-width"
                onSubmit={onCodeSubmit}
                noValidate
            >
                {inputValidation?.isInvalid ? (
                    <FormValidationAlert errors={[inputValidation]} className="my-3" />
                ) : null}
                <TextField
                    className="my-3 big-text-field"
                    ref={backupCodeInputRef}
                    label="Backup Code"
                    isLabelVisible={false}
                    placeholder="Enter your backup code"
                    name="code"
                    isRequired
                    id="backup-code-entry"
                    shouldFocusOnMount
                />
                <Button type="submit" size={'lg'} id="login" gaTrackingId="237605532484">
                    Log In
                </Button>
            </form>
        );
    };

    const onValidate = async () => {
        let assertion: PublicKeyCredential | null;

        if (!options?.challenge) {
            return;
        }

        try {
            assertion = (await getAssertion(options)) as PublicKeyCredential;
            if (!assertion) {
                throw new Error('Assertion not found');
            }
        } catch (e) {
            // TODO: (follow up PR): how to handle yubikey error/cancel? give them option for backup code / start over?
            return;
        }

        try {
            const response: AuthenticatorAssertionResponse =
                assertion.response as AuthenticatorAssertionResponse;
            const rawId = new Uint8Array(assertion.rawId);
            const assertionResponse = {
                ...assertion,
                id: assertion.id,
                type: assertion.type,
                rawId,
                response: {
                    ...response,
                    authenticatorData: new Uint8Array(response.authenticatorData),
                    clientDataJSON: new Uint8Array(response.clientDataJSON),
                    signature: new Uint8Array(response.signature),
                },
            };

            submitFn(assertionResponse);
        } catch (e) {
            logoutHandler();
            throw e;
        }
    };

    return (
        <div className="ia-enter-mfa">
            <Heading
                headingLevel={1}
                appearance="h3"
                headingAlign="center"
                className="mb-3"
            >
                Sign In
            </Heading>
            <Stack>
                <Box alignX="center">
                    <Text tag="div" align="center">
                        Connect the security key that you associated with your account or
                        use a backup code. <br /> (Backup codes may only be used one
                        time.)
                    </Text>
                </Box>
                <Box alignX="center">
                    <RadioToggle
                        options={radioToggleOptions}
                        value={selected}
                        onChange={(e) => setSelected(e.target.value)}
                        isRequired
                    />
                </Box>

                <Box alignX="center" className="full-width">
                    {selected === 'securitykey' ? (
                        <form noValidate>
                            <Button onClick={onValidate} size={'lg'} isDisabled={!ready}>
                                Use Security Key
                            </Button>
                        </form>
                    ) : (
                        renderBackupCodeOption()
                    )}
                </Box>
                <Heading
                    headingLevel={2}
                    appearance="h6"
                    className="mt-5 mb-half"
                    headingAlign="center"
                >
                    Having trouble logging in?
                </Heading>
                <Text tag="div" align="center">
                    Reach out to your manager or contact the Help Desk&apos;s Internal
                    Help Line.
                    <div className="mt-half">
                        <Button
                            appearance="link"
                            id="logout"
                            to="/internal/account/login"
                            onClick={logoutHandler}
                            gaTrackingId="237605532484"
                        >
                            Start over
                        </Button>
                    </div>
                </Text>
            </Stack>
        </div>
    );
}
