/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexCol, FlexRow } from 'sarsaparilla';

export default function LabelValueRow({ label, value }) {
    return (
        <FlexRow className={'mt-1'}>
            <FlexCol sm={4}>
                <b>{label}:</b>
            </FlexCol>
            <FlexCol sm={5}>{value}</FlexCol>
        </FlexRow>
    );
}
LabelValueRow.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
};
