/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const SET_SELECTED_FIELD_SALES_TAB_INDEX = 'SET_SELECTED_FIELD_SALES_TAB_INDEX';

// Field Sales
export const INVENTORY_CAMPING = 'CAMPING';
export const INVENTORY_RENTAL = 'RENTAL';
export const INVENTORY_PERMIT = 'PERMIT';
export const INVENTORY_VEHICLE_PERMIT = 'VEHICLE_PERMIT';
export const INVENTORY_TICKET = 'TICKET';
export const INVENTORY_TIMED_ENTRY = 'TIMED_ENTRY';
export const INVENTORY_LOTTERY_CAMPING = 'LOTTERY_CAMPING';
export const INVENTORY_LOTTERY_TICKET = 'LOTTERY_TICKET';
export const INVENTORY_LOTTERY_PERMIT = 'LOTTERY_PERMIT';
export const INVENTORY_ACTIVITY_PASS = 'ACTIVITYPASS';
export const INVENTORY_PASS = 'PASS';
export const INVENTORY_PARKPASS = 'PARKPASS';
export const INVENTORY_VENUE_RESERVATIONS = 'VENUE_RESERVATIONS';
export const INVENTORY_POS = 'POS';
export const INVENTORY_TREE_PERMIT = 'TREE_PERMIT';

export const GET_CART = 'GET_INTERNAL_CART';
export const GET_CART_FAILED = 'GET_INTERNAL_CART_FAILED';
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const CUSTOMER_CLEARED = 'CUSTOMER_CLEARED';
export const CART_CLEARED = 'CART_CLEARED';
export const PAYMENT_INITIATED = 'PAYMENT_INITIATED';
export const PAYMENT_INITIATION_ERROR = 'PAYMENT_INITIATION_ERROR';
export const PAYMENT_COMPLETE = 'PAYMENT_COMPLETE';
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS';
export const NOFEE_CHECKOUT_COMPLETE = 'NOFEE_CHECKOUT_COMPLETE';
export const CASHIER_PAYMENT_COMPLETE = 'CASHIER_PAYMENT_COMPLETE';
export const EDIT_RESERVATION = 'EDIT_RESERVATION';
export const EDIT_RESERVATION_FAILED = 'EDIT_RESERVATION_FAILED';
export const EDIT_CLEAR = 'EDIT_CLEAR';
export const REMOVE_RESERVATION = 'REMOVE_RESERVATION';
export const REMOVE_RESERVATION_FAILED = 'REMOVE_RESERVATION_FAILED';
export const SHIFT_RETRIEVED = 'SHIFT_RETRIEVED';
export const SHIFT_OPENED = 'SHIFT_OPENED';
export const SHIFT_CLOSED = 'SHIFT_CLOSED';
export const ACCOUNT_MODAL_OPEN = 'ACCOUNT_MODAL_OPEN';
export const ACCOUNT_MODAL_CLOSE = 'ACCOUNT_MODAL_CLOSE';
export const TOGGLE_FEE_OVERRIDES_MODAL = 'TOGGLE_FEE_OVERRIDES_MODAL';
export const FIELD_SALES_RESET = 'FIELD_SALES_RESET';
export const FIELD_SALES_CANCEL_ORDER_URL = 'http://127.0.0.1:8021/cancel';
export const FIELD_SALES_NEW_PRINT_RECEIPT_URL =
    'http://127.0.0.1:9000/ReceiptService/print';
export const FIELD_SALES_NEW_REPRINT_RECEIPT_URL =
    'http://127.0.0.1:9000/ReceiptService/reprint';
export const FIELD_SALES_NEW_CHECK_PRINTER_URL =
    'http://127.0.0.1:9000/ReceiptService/checkprint';
export const FIELD_SALES_NEW_OPEN_DRAWER_URL =
    'http://127.0.0.1:9000/ReceiptService/drawer';
export const PRS_CLIENT_PRINT_RECEIPT_URL = 'http://127.0.0.1:8021/print';
export const PRS_CLIENT_REPRINT_RECEIPT_URL = 'http://127.0.0.1:8021/reprint';
export const PRS_CLIENT_CHECK_PRINTER_URL = 'http://127.0.0.1:8021/checkprint';
export const FIELD_SALES_PRINT_RECEIPT_URL = 'http://127.0.0.1:9000/print';
export const ACTIVITYPASS_SEARCH_URL = `${process.env.API}/activitypass/internal/passes`;
export const FIELD_SALES_REPRINT_RECEIPT_URL = 'http://127.0.0.1:9000/reprint';
export const FIELD_SALES_CHECK_PRINTER_URL = 'http://127.0.0.1:9000/checkprint';
export const FIELD_SALES_OPEN_DRAWER_URL = 'http://127.0.0.1:9000/drawer';
export const PRINTER_DETECTED = 'PRINTER_DETECTED';
export const PRS_RECEIPT_PRINTING_ENABLED = 'PRS_RECEIPT_PRINTING_ENABLED';
export const PRINT_LOADING = 'PRINT_LOADING';
export const PRINT_LOADING_FINISHED = 'PRINT_LOADING_FINISHED';
export const PRINT_ERROR = 'PRINT_ERROR';
export const PRINT_SUCCESS = 'PRINT_SUCCESS';
export const PRINTER_DETECTION_FAILED = 'PRINTER_DETECTION_FAILED';
export const NEW_PRINTER_DETECTED = 'NEW_PRINTER_DETECTED';
export const NEW_PRINTER_DETECTION_FAILED = 'NEW_PRINTER_DETECTION_FAILED';

// Sales Channel Types
export const FIELD_SALES_SALES_CHANNEL = 'FIELD_SALES_SALES_CHANNEL';
export const CALL_CENTER_SALES_CHANNEL = 'CALL_CENTER_SALES_CHANNEL';
export const CALL_CENTER_ROLE = 'ROLE_CSR';
export const CSR2_ROLE = 'ROLE_CSR2';
export const CSR_SUPERVISOR = 'ROLE_CSR_SUPERVISOR';

// Sales Channel Page Title
export const FIELD_SALES_PAGE_TITLE = 'Field Sales';
export const CALL_CENTER_PAGE_TITLE = 'Call Center Sales';

export const titleLookup = {
    [FIELD_SALES_SALES_CHANNEL]: FIELD_SALES_PAGE_TITLE,
    [CALL_CENTER_SALES_CHANNEL]: CALL_CENTER_PAGE_TITLE,
};

export const TICKET_VIOLATION_ERROR_MESSAGE = 'Incomplete order detail information.';

export const REQUIRE_PAYMENT = 'REQUIRE_PAYMENT';

// cSpell:ignore nofee, PARKPASS, ACTIVITYPASS,
