/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
    Alert,
    Button,
    ButtonGroup,
    DividerNew as Divider,
    FeatureFlag,
    FlexCol,
    FlexRow,
    Heading,
    ModalActions,
    phoneValidator,
    Spacer,
    StyledModal,
    Switch,
    Text,
    TextArea,
    TextFieldStateless,
    TYPES,
    useFormatDateTime,
} from 'sarsaparilla';
import { AnchorPageSection } from 'shared-ui/components/AnchorPageWithSidebarLayout/AnchorPageSection';
import { AnchorPageWithSidebarLayout } from 'shared-ui/components/AnchorPageWithSidebarLayout/AnchorPageWithSidebarLayout';
import { AppDispatch } from '../../../dev/store';
import { cancelEmailChange } from '../../actions/confirmEmail';
import { loginDotGovUnlinkedLoggedInUser } from '../../actions/login';
import { createMfaBackupCodes } from '../../actions/mfa';
import { AvailableOidcProviders, AvailableOidcProvidersLabels } from '../../actions/oidc';
import {
    deleteRole as deleteRoleAction,
    editingUser,
    editUserRoles as editUserRolesAction,
    emailChangeCancelled,
    resendConfirmation as resendConfirmationAction,
    resetConfirmStatus,
    resetMFA as resetMfaAction,
    resetResetStatus,
    resetUser,
    setDefaultRole as setDefaultRoleAction,
    unlinkLoginDotGov as unlinkLoginDotGovAction,
    updateMyProfile,
    userMFAEnrollmentStatus,
} from '../../actions/updateUser';
import { updateUserStatus as updateUserStatusAction } from '../../actions/userManagement';
import { PhoneNumber, Role, State, User } from '../../constants/types';
import {
    FormatDateOptions,
    parseZonedOrStringToUTC,
} from '../../utilities/dateFunctions';
import { errorForUpdateUser } from '../../utilities/errorMessages';
import { ATR, BAH_ADMIN, PMO } from '../../utilities/roles';
import { TargetedEvent } from '../../utilities/tools';
import ActionErrorDisplay from '../ActionErrorDisplay';
import Toast from '../Toast';
import MfaStatus, { MfaStatusProps } from '../UpdateUser/MfaStatus';
import NewEmailAddressModal from '../UpdateUser/NewEmailAddressModal';
import PhoneNumbers from '../UpdateUser/PhoneNumbers';
import RolesTable from '../UpdateUser/RolesTable';
import UserStatusToggle from '../UserStatusToggle';

type MyProfilePanelProps = {
    match: TYPES.MATCH;
    location: TYPES.LOCATION;
    toggleIsMfaModalOpen: () => void;
};

const createPhoneNumberListFromUser = (accountToSave: User): PhoneNumber[] => {
    // if it's empty then we add two default numbers
    if (!accountToSave?.phone_numbers || !accountToSave?.phone_numbers.length) {
        return [
            { phone: '', ext: '', type: 'cell' },
            { phone: '', ext: '', type: 'work' },
        ];
    }

    // Make a fresh array
    const phoneNumberList: PhoneNumber[] = [...accountToSave.phone_numbers];

    // Grab cell if it exists and remove it from fresh array if it exists
    const mainCell = phoneNumberList.find((value, index, arr) => {
        if (value.type === 'cell') {
            arr.splice(index, 1);
            return true;
        }
        return false;
    });

    // Grab work if it exists and remove it from fresh array if it exists
    const mainWork = phoneNumberList.find((value, index, arr) => {
        if (value.type === 'work') {
            arr.splice(index, 1);
            return true;
        }
        return false;
    });

    const reorderedPhoneNumbers: PhoneNumber[] = [
        mainCell ?? { phone: '', ext: '', type: 'cell' },
        mainWork ?? { phone: '', ext: '', type: 'work' },
        ...phoneNumberList,
    ];

    return reorderedPhoneNumbers.map((phone: PhoneNumber) => {
        const number = phone.phone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(/\s+/g, '');
        return {
            ...phone,
            phone: number
                ? `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`
                : '',
        };
    });
};

export default function MyProfilePanel({
    match,
    location,
    toggleIsMfaModalOpen,
}: MyProfilePanelProps) {
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const {
        accountToSave,
        savedAccount,
        error,
        reloadAccount,
        readOnly,
        concessionaires,
        userIdEnabledPasswordReset,
        errorEnablingPasswordReset,
        requestConfirmResponse,
    } = useSelector((state: State) => state.updateUser);
    const loggedInUser = useSelector((state: State) =>
        state.login ? state.login.user : null
    );
    const concessionaire = useSelector(
        (state: State) => state.fetchManagedConcessionaires.assignedConcessionaire
    );
    const { updateUserStatusErrorMessage, updateUserStatusSuccessful } = useSelector(
        (state: State) => state.userManagement
    );
    const userNotes = useSelector((st: State) => {
        return st.notes.notes;
    });

    const [userMFAStatus, setUserMFAStatus] = React.useState<MfaStatusProps[]>([]);

    const [state, setState] = React.useState({
        phoneNumbers: createPhoneNumberListFromUser(accountToSave),
        edited: false,
        isValidPhoneNumber: true,
        isMFAModalOpen: false,
        isMFABypassModalOpen: false,
        isNewMFABackupCodesModalOpen: false,
        isNewEmailModalOpen: false,
        statusToggleModalIsOpen: false,
        bypassMFAJustification: '',
        titleMFAModal: '',
        bodyMFAModal: <></>,
        actionMFAModal: () => {},
        user: {
            first_name: '',
            last_name: '',
            email: '',
            new_email: '',
            locked: false,
            can_run_transactions: false,
            should_receive_sms: false,
            enforce_effective_dates: false,
            effective_start_at: null,
            effective_end_at: null,
            roles: [],
        },
        changesCounter: 0,
    });

    const [spinningModal, setSpinningModal] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [typeToast, setTypeToast] = React.useState('success');
    const [msgToast, setMsgToast] = React.useState('');
    const [initialPhoneNumbers, setInitialPhoneNumbers] = React.useState<any>(null);
    const fmtDt = useFormatDateTime();
    const longFmt = { format: 'll' } as FormatDateOptions;
    const shortFmt = { format: 'L' } as FormatDateOptions;

    const emptyCellPhone = state.phoneNumbers
        .filter((phone: PhoneNumber) => phone.type === 'cell')
        .every((phone: PhoneNumber) => {
            return phone.phone === '' || phone.phone === '(___) ___ - ____';
        });

    const countDifferences = (obj1: any, obj2: any) => {
        let differences = 0;
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
        keys.forEach((key) => {
            const val1 = obj1[key];
            const val2 = obj2[key];
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) {
                    differences += 1;
                }
            } else if (val1 !== val2) {
                differences += 1;
            }
        });
        return differences;
    };

    const updateStateUser = (user: any) => {
        setState((prevState) => {
            const newUser = {
                ...prevState.user,
                ...user,
            };
            return {
                ...prevState,
                user: newUser,
            };
        });
    };

    const setInitialValues = React.useCallback(
        (user: any) => {
            if (!user) return;
            const account = {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                new_email: '',
                locked: user.locked,
                can_run_transactions: user.can_run_transactions,
                should_receive_sms: user.should_receive_sms,
                enforce_effective_dates: accountToSave?.enforce_effective_dates,
                effective_start_at: accountToSave?.effective_start_at,
                effective_end_at: accountToSave?.effective_end_at,
                roles: user.roles,
            };
            updateStateUser(account);
        },
        [accountToSave]
    );

    const initializeValues = React.useCallback(
        (user: User) => {
            const phoneNumbers = createPhoneNumberListFromUser(user);
            const ums = userMFAEnrollmentStatus(user, userNotes);
            setUserMFAStatus(ums);

            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
                edited: false,
                changesCounter: 0,
                isValidPhoneNumber: true,
            }));

            setInitialPhoneNumbers(phoneNumbers);
            setInitialValues(user);

            if (location && location.pathname.includes('/internal/account/edit-user')) {
                if (match?.params.userId) {
                    dispatch(editingUser(match.params.userId));
                }
                dispatch(resetResetStatus());
                dispatch(resetConfirmStatus());
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location, match]
    );

    const resetState = () =>
        setState((prevState) => ({ ...prevState, edited: false, changesCounter: 0 }));

    React.useEffect(() => {
        resetUser();
    }, []);

    React.useEffect(() => {
        const ums = userMFAEnrollmentStatus(accountToSave, userNotes);
        setUserMFAStatus(ums);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userNotes]);

    React.useEffect(() => {
        dispatch(editingUser(loggedInUser?.user_id as string));
    }, [dispatch, loggedInUser]);

    React.useEffect(() => {
        initializeValues(accountToSave);
    }, [initializeValues, accountToSave]);

    React.useEffect(() => {
        if (reloadAccount && accountToSave) {
            setInitialValues(accountToSave);
            const phoneNumbers = createPhoneNumberListFromUser(accountToSave);
            const ums = userMFAEnrollmentStatus(accountToSave, userNotes);
            setUserMFAStatus(ums);
            setInitialPhoneNumbers(phoneNumbers);
            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
            }));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadAccount, accountToSave]);

    React.useEffect(() => {
        if (savedAccount) {
            setInitialValues(savedAccount);
            const phoneNumbers = createPhoneNumberListFromUser(savedAccount);
            const ums = userMFAEnrollmentStatus(savedAccount, userNotes);
            setUserMFAStatus(ums);
            setInitialPhoneNumbers(phoneNumbers);
            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
            }));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedAccount]);

    // Current way to remove the should_receive_sms flag when the user removes all cell phone numbers
    React.useEffect(() => {
        if (emptyCellPhone) {
            updateStateUser({ should_receive_sms: false });
        }
    }, [emptyCellPhone]);

    const setEdited = () => setState((prevState) => ({ ...prevState, edited: true }));

    const setIsValidPhoneNumber = (inputPhoneVal: string) => {
        const isValidPhoneNumber = phoneValidator(inputPhoneVal).isValid;
        setState((prevState) => ({ ...prevState, isValidPhoneNumber }));
    };

    const setCanSave = () => {
        return (
            !readOnly ||
            !accountToSave ||
            (loggedInUser && loggedInUser.user_id === accountToSave.user_id)
        );
    };

    const getPhoneNumberList = (phoneNumbers: PhoneNumber[]) => {
        // empty phone numbers must be filtered out for API response
        const formattedPhoneNumbers = phoneNumbers
            .filter(
                (inputNumber: PhoneNumber) =>
                    inputNumber.phone && inputNumber.phone !== '(___) ___ - ____'
            )
            .map((inputNumber: PhoneNumber) => ({
                ...inputNumber,
                phone: inputNumber.phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace(/\s+/g, ''),
            }));
        return formattedPhoneNumbers;
    };

    const closeModal = () => {
        setState((prevState) => ({
            ...prevState,
            isMFAModalOpen: false,
            isMFABypassModalOpen: false,
            isNewMFABackupCodesModalOpen: false,
        }));
        setSpinningModal(false);
    };

    const closeNewEmailModal = () => {
        setState((prevState) => ({
            ...prevState,
            isNewEmailModalOpen: false,
        }));
    };

    const closeStatusToggleModal = () => {
        setState((prevState) => ({
            ...prevState,
            statusToggleModalIsOpen: false,
        }));
    };

    const confirmStatusToggleModal = async (note: string) => {
        closeStatusToggleModal();
        await dispatch(
            updateUserStatusAction(
                { ...state.user, user_id: accountToSave.user_id },
                note
            )
        );
        updateStateUser({ locked: !state.user.locked });
        setShowToast(true);
    };

    const actionResetMFA = async () => {
        await dispatch(resetMfaAction(accountToSave.user_id));
        dispatch(editingUser(loggedInUser?.user_id as string));
        setMsgToast('Reset MFA was successful!');
        setTypeToast('info');
        closeModal();
        setShowToast(true);
    };

    const actionRegenerateBackupCodes = async () => {
        const codes = await createMfaBackupCodes(accountToSave.user_id);
        setState((prevState) => ({
            ...prevState,
            isNewMFABackupCodesModalOpen: true,
            isMFAModalOpen: true,
            titleMFAModal: 'New MFA Backup Codes',
            bodyMFAModal: (
                <>
                    <div>
                        The following are new backup codes that the user can use to
                        authenticate during login.
                    </div>
                    {codes.map((code: string) => (
                        <pre key={code}>{code}</pre>
                    ))}
                </>
            ),
            actionMFAModal: closeModal,
        }));
        setSpinningModal(false);
        dispatch(editingUser(loggedInUser?.user_id as string));
    };

    const actionEnrollMFA = async () => {
        closeModal();
        toggleIsMfaModalOpen();
    };

    const setMFAModalState = (type: string, notEnrolledMFA: boolean) => {
        switch (type) {
            case 'reset':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    titleMFAModal: 'Reset MFA Confirmation',
                    bodyMFAModal: (
                        <>
                            <div>Are you sure you want to reset MFA?</div>
                            <br />
                            <div>
                                This action will unenroll MFA, User will be prompted to
                                enroll using a multi-factor authentication app on the next
                                login.
                            </div>
                        </>
                    ),
                    actionMFAModal: actionResetMFA,
                }));
                setSpinningModal(false);
                break;
            case 'backup':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    titleMFAModal: `Generate ${!notEnrolledMFA ? 'New' : ''} Back Up Codes`,
                    bodyMFAModal: (
                        <>
                            <div>
                                Are you sure you want to
                                {` Generate ${!notEnrolledMFA ? 'New' : ''} Back Up Codes`}
                                ?
                            </div>
                            <br />
                            {!notEnrolledMFA && (
                                <div>
                                    This action will delete previous Backup Codes
                                    generated, and regenerate new ones to be used by the
                                    User next time needed.
                                </div>
                            )}
                        </>
                    ),
                    actionMFAModal: actionRegenerateBackupCodes,
                }));
                break;
            default:
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    isMFABypassModalOpen: false,
                    titleMFAModal: 'Enroll New MFA',
                    bodyMFAModal: (
                        <>
                            <div>Are you sure you want to Enroll New MFA?</div>
                            <br />
                            <div>This action will Enroll new MFA.</div>
                        </>
                    ),
                    actionMFAModal: actionEnrollMFA,
                }));
                break;
        }
    };

    const saveEnabled = () => {
        const { enforce_effective_dates, effective_start_at, effective_end_at } =
            state.user;
        return (
            (state.edited ||
                (state.changesCounter > 0 &&
                    getPhoneNumberList(state.phoneNumbers).length > 0)) &&
            state.isValidPhoneNumber &&
            (!enforce_effective_dates || (effective_start_at && effective_end_at))
        );
    };

    const handleSave = async (event?: React.FormEvent) => {
        event?.preventDefault();

        let user: Partial<User> = {
            ...state.user,
            phone_numbers: getPhoneNumberList(state.phoneNumbers),
            email: !isEmpty(state.user.new_email)
                ? state.user.new_email
                : state.user.email,
            locked: state.user.locked,
            can_run_transactions: state.user.can_run_transactions,
            is_concessionaire: loggedInUser?.is_concessionaire as boolean,
        };

        user.should_receive_sms = state.user.should_receive_sms;

        if (accountToSave) {
            user = { ...accountToSave, ...user };
        }

        if (user.roles) {
            user.roles = user.roles.map(
                (role: Role) =>
                    ({
                        role_type: role.role_type,
                        is_default_role: role.is_default_role,
                        permit_id: role.permit_id,
                        poc_type: role.poc_type,
                        location: {
                            location_id: role.location.location_id,
                            location_type: role.location.location_type,
                            has_lottery: role.location.has_lottery,
                            has_cooperators: role.location.has_cooperators,
                        },
                    }) as Role
            );
        }

        await dispatch(updateMyProfile(user as User));
        setShowToast(true);
        if (!isEmpty(state.user.new_email)) {
            updateStateUser({
                new_email: '',
            });
        }
    };

    const defaultRoleChange = (role: any) => {
        if (!role.is_default_role) {
            dispatch(setDefaultRoleAction(role));
        }
    };

    const handleEditRoleClick = () => dispatch(editUserRolesAction(history));

    const addPhoneNumber = () => {
        setState((prevState) => {
            const phoneNumbers = [
                ...prevState.phoneNumbers,
                { phone: '', ext: '', type: 'cell' },
            ] as PhoneNumber[];
            return {
                ...prevState,
                phoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    phoneNumbers
                ),
                isValidPhoneNumber: false,
            };
        });
    };

    const removePhoneNumber = (index: number) => {
        const edited =
            getPhoneNumberList(state.phoneNumbers).length > 0 &&
            initialPhoneNumbers.length > 0;
        setState((prevState) => {
            const phoneNumbers = [...prevState.phoneNumbers];
            if (index === 0 || index === 1) {
                // don't remove the index just clear it.
                phoneNumbers[index] = {
                    phone: '',
                    ext: '',
                    type: index === 0 ? 'cell' : 'work',
                };
            } else {
                phoneNumbers.splice(index, 1);
            }
            return {
                ...prevState,
                phoneNumbers,
                edited,
                changesCounter:
                    phoneNumbers.length === initialPhoneNumbers.length
                        ? 0
                        : (prevState.phoneNumbers ?? initialPhoneNumbers).length -
                          phoneNumbers.length,
                isValidPhoneNumber: true,
            };
        });
    };

    const isLoggedUserSameRoleAsUser = (userA: any, userB: any) => {
        if (!userA.roles?.length || !userB.roles?.length) return null;

        const rolesArr1 = userA.roles.map((item: any) => item.role_type);
        const rolesArr2 = userB.roles.map((item: any) => item.role_type);

        return rolesArr1.some(
            (roleType: string) =>
                [PMO, BAH_ADMIN, ATR].includes(roleType) && rolesArr2.includes(roleType)
        );
    };

    const successUI = () => {
        if (!error && savedAccount) {
            return (
                <Toast show={showToast} type={typeToast} setShow={setShowToast}>
                    <p className="sarsa-text size-lg">{msgToast}</p>
                </Toast>
            );
        }
        if (updateUserStatusSuccessful) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Update user status was successful!
                    </p>
                </Toast>
            );
        }
        if (userIdEnabledPasswordReset) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Password reset email was sent successfully!
                    </p>
                </Toast>
            );
        }
        if (requestConfirmResponse?.ttl && !requestConfirmResponse?.error) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Confirmation email was sent successfully!
                    </p>
                </Toast>
            );
        }
        if (showToast) {
            return (
                <Toast show={showToast} type={typeToast} setShow={setShowToast}>
                    <p className="sarsa-text size-lg">{msgToast}</p>
                </Toast>
            );
        }
        return null;
    };

    const concessionaireEntity = () => {
        if (!accountToSave?.is_concessionaire) return null;

        if (concessionaires?.length > 0) {
            return (
                <div className="assigned-concessionaires-wrapper mt-3">
                    <div className="assigned-concessionaires-header">
                        Associated Concessionaires
                    </div>
                    <ul className="assigned-concessionaires-list">
                        {concessionaires.map((conc) => (
                            <li key={`conc_${conc.concessionaire_id}`}>
                                {conc.concessionaire_name}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        if (concessionaire) {
            return (
                <div className="assigned-concessionaire-wrapper">
                    <div className="assigned-concessionaire-title">
                        Associated Concessionaire:
                        <span className="assigned-concessionaire-name">
                            {concessionaire.concessionaire_name}
                        </span>
                    </div>
                </div>
            );
        }

        return null;
    };

    const onPhoneTypeChange = (index: number, type: 'cell' | 'work') => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                ext: type === 'work' ? updatedPhoneNumbers[index].ext : '',
                type,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const onPhoneExtensionChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                ext: event.target.value,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const onPhoneNumberChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsValidPhoneNumber(event.target.value);
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                phone: event.target.value,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const renderSidebarLayout = (effectiveStartAt: any, effectiveEndAt: any) => {
        const showCancelEmail =
            !isNil(accountToSave?.email_change) &&
            isNil(accountToSave?.email_change.completed_at);

        const canResendConfirmation =
            accountToSave?.is_email_confirmed === false || showCancelEmail;

        const notEnrolledMFA =
            userMFAEnrollmentStatus(accountToSave)[0].userMFAStatus === 'Not Enrolled';

        const loginDotGovLinked =
            loggedInUser?.oidc_user_info?.[AvailableOidcProviders.loginDotGov]
                ?.enabled === true;

        const loginDotGovLabel = AvailableOidcProvidersLabels.loginDotGov;

        const disableButtons = !saveEnabled();

        return (
            <div className="rec-nested-wrap">
                {successUI()}
                <AnchorPageWithSidebarLayout
                    className="te-facility-fees-tab"
                    preventScrollToAnchor
                    singlePanelMode
                >
                    <AnchorPageSection
                        heading="Personal Info"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow>
                            <FlexCol sm={3}>
                                <TextFieldStateless
                                    id="updateUserFirstName"
                                    label="First Name"
                                    value={state.user.first_name}
                                    onChange={(e) => {
                                        setEdited();
                                        updateStateUser({ first_name: e.target.value });
                                    }}
                                    placeholder="First Name"
                                    isRequired
                                    isDisabled={!setCanSave()}
                                />
                                <Spacer size="sm" />
                                <Text className="sarsa-label mt-1">Email</Text>
                                <Text>{state.user.email}</Text>
                                <Spacer size="sm" />
                            </FlexCol>
                            <FlexCol sm={3}>
                                <TextFieldStateless
                                    label="Last Name"
                                    value={state.user.last_name}
                                    onChange={(e) => {
                                        setEdited();
                                        updateStateUser({ last_name: e.target.value });
                                    }}
                                    placeholder="Last Name"
                                    isRequired
                                    isDisabled={!setCanSave()}
                                    id="updateUserLastName"
                                />
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol sm={6}>
                                <Alert type="info">
                                    Please contact your manager if you need to update the
                                    email address associated with your account.
                                </Alert>
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                <Divider className="mt-3 mb-3" />
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5">
                                        Phone Numbers
                                    </Heading>
                                </div>
                                <Spacer size="sm" />
                                <Spacer size="sm" />

                                <PhoneNumbers
                                    phoneNumbers={state.phoneNumbers}
                                    canSave={setCanSave()}
                                    onExtChange={(event, index) => {
                                        onPhoneExtensionChange(index, event);
                                    }}
                                    onTypeChange={(type, index) => {
                                        onPhoneTypeChange(index, type);
                                    }}
                                    onNumberChange={(event, index) => {
                                        onPhoneNumberChange(index, event);
                                    }}
                                    onRemovePhone={removePhoneNumber}
                                    onAddPhone={addPhoneNumber}
                                >
                                    <FeatureFlag flag="commsEnableSendSms">
                                        <Switch
                                            isDisabled={
                                                accountToSave?.sms_opt_in_disabled ||
                                                emptyCellPhone
                                            }
                                            isSelected={
                                                state.user.should_receive_sms &&
                                                !emptyCellPhone
                                            }
                                            onChange={(event: any) => {
                                                setEdited();
                                                if (
                                                    !state.user.should_receive_sms &&
                                                    state.phoneNumbers.filter(
                                                        (phone: PhoneNumber) =>
                                                            phone.type === 'cell'
                                                    ).length === 0
                                                ) {
                                                    addPhoneNumber();
                                                }
                                                if (!event?.target?.checked) {
                                                    const phoneNumbers =
                                                        createPhoneNumberListFromUser({
                                                            phone_numbers:
                                                                state.phoneNumbers,
                                                        } as User);
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        phoneNumbers,
                                                    }));
                                                    setInitialPhoneNumbers(phoneNumbers);
                                                }
                                                updateStateUser({
                                                    should_receive_sms:
                                                        !state.user.should_receive_sms,
                                                });
                                            }}
                                            label="Receive SMS (Text)"
                                            id="should_receive_sms"
                                        />
                                    </FeatureFlag>

                                    {accountToSave?.sms_opt_in_disabled && (
                                        <FlexRow className={'mt-3 bt-3'}>
                                            <FlexCol sm={6}>
                                                <Alert type="warning">
                                                    You have disabled SMS from your mobile
                                                    device. In order to re-enable SMS, you
                                                    must first text &quot;START&quot; to{' '}
                                                    {process.env['SMS_PHONE_NUMBER']},
                                                    wait 5 minutes, and then click this
                                                    button
                                                </Alert>
                                            </FlexCol>
                                        </FlexRow>
                                    )}
                                </PhoneNumbers>
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                <ButtonGroup>
                                    <Button
                                        onClick={() => initializeValues(accountToSave)}
                                        id="updateUserCancel"
                                        appearance="tertiary"
                                        isDisabled={disableButtons}
                                    >
                                        Discard Changes
                                    </Button>
                                    {setCanSave() && (
                                        <Button
                                            appearance="primary"
                                            isDisabled={disableButtons}
                                            onClick={(e) => {
                                                setTypeToast('success');
                                                setMsgToast(
                                                    `${!isEmpty(state.user.new_email) ? state.user.new_email : state.user.email} was saved successfully!`
                                                );
                                                handleSave(e);
                                            }}
                                            id="updateUserSave"
                                        >
                                            Save
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="Roles and Locations"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow>
                            <FlexCol>
                                {concessionaireEntity()}
                                <RolesTable
                                    showBorders
                                    showHeader={false}
                                    showDefaultColumn={accountToSave?.roles?.length > 1}
                                    showDeleteColumn={false}
                                    canEditLocations={false}
                                    onEditLocations={handleEditRoleClick}
                                    onRoleDelete={deleteRoleAction}
                                    onDefaultRoleChange={defaultRoleChange}
                                    editingSelf
                                    roles={accountToSave?.roles}
                                />
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="User History"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        {!loggedInUser?.must_set_password &&
                            !isEmpty(loggedInUser?.password_set_at) && (
                                <FlexRow className="mt-3">
                                    <FlexCol xs={6}>
                                        <Alert type="warning">
                                            <Link to="/internal/account/change-password">
                                                Update your password
                                            </Link>{' '}
                                            before{' '}
                                            {fmtDt(
                                                parseZonedOrStringToUTC(
                                                    loggedInUser?.password_set_at as string
                                                ).add({ months: 3 }),
                                                longFmt
                                            )}
                                            .
                                        </Alert>
                                    </FlexCol>
                                </FlexRow>
                            )}
                        <FlexRow className="mt-3 mb-2">
                            <FlexCol sm={2}>
                                <Text fontWeight="semibold">Created at</Text>
                                <Text>{`${fmtDt(parseZonedOrStringToUTC(loggedInUser?.created_at as string), shortFmt)}`}</Text>
                            </FlexCol>
                            <FlexCol sm={2} className="">
                                <Text fontWeight="semibold">Last Logged in</Text>
                                <Text>{`${fmtDt(parseZonedOrStringToUTC(loggedInUser?.last_logged_in_at as string), shortFmt)}`}</Text>
                            </FlexCol>
                            <FlexCol sm={2}>
                                <Text fontWeight="semibold">Last Password set</Text>
                                <Text>
                                    {!isEmpty(loggedInUser?.password_set_at)
                                        ? `${fmtDt(parseZonedOrStringToUTC(loggedInUser?.password_set_at as string), shortFmt)}`
                                        : 'never'}
                                </Text>
                            </FlexCol>
                            {!isEmpty(
                                loggedInUser?.oidc_user_info?.['loginDotGov']?.modified_at
                            ) && (
                                <FlexCol sm={2}>
                                    <Text fontWeight="semibold">Login.gov connected</Text>
                                    <Text>
                                        {`${fmtDt(
                                            parseZonedOrStringToUTC(
                                                loggedInUser?.oidc_user_info?.[
                                                    'loginDotGov'
                                                ]?.modified_at as string
                                            ),
                                            shortFmt
                                        )}`}
                                    </Text>
                                </FlexCol>
                            )}
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="Security"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        {state.user.enforce_effective_dates && (
                            <>
                                <FlexRow className="mt-5 mb-3">
                                    <FlexCol sm={6}>
                                        <div className="rec-color-gray">
                                            <Heading headingLevel={2} appearance="h5Caps">
                                                Effective Dates
                                            </Heading>
                                        </div>
                                    </FlexCol>
                                </FlexRow>
                                <FlexRow className="mt-3 mb-3">
                                    <FlexCol sm={1}>
                                        <Text fontWeight="semibold">Start Date</Text>
                                        <Text>{`${effectiveStartAt ? fmtDt(parseZonedOrStringToUTC(effectiveStartAt), shortFmt) : 'N/A'}`}</Text>
                                    </FlexCol>
                                    <FlexCol sm={1}>
                                        <Text fontWeight="semibold">End Date</Text>
                                        <Text>{`${effectiveEndAt ? fmtDt(parseZonedOrStringToUTC(effectiveEndAt), shortFmt) : 'N/A'}`}</Text>
                                    </FlexCol>
                                </FlexRow>
                                <Divider className="mt-3 mb-3" />
                            </>
                        )}
                        <FlexRow className="mt-4 mb-2">
                            <FlexCol sm={6}>
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5Caps">
                                        Email
                                    </Heading>
                                </div>
                                <Spacer size="sm" />
                                <Text className="sarsa-label mt-1">
                                    Current Email Address
                                </Text>
                                <Text>{state.user.email}</Text>
                            </FlexCol>
                        </FlexRow>
                        {!isNil(accountToSave?.email_change) &&
                            isNil(accountToSave?.email_change.completed_at) && (
                                <FlexRow className="mb-2">
                                    <FlexCol sm={12}>
                                        <Alert
                                            type="warning"
                                            heading="Email Change Pending"
                                        >
                                            You have a e-mail change request pending
                                            confirmation.
                                            <Spacer size="sm" />
                                            New e-mail:{' '}
                                            {accountToSave?.email_change.new_email}
                                        </Alert>
                                    </FlexCol>
                                </FlexRow>
                            )}
                        <FlexRow className="space-left mb-1">
                            {isLoggedUserSameRoleAsUser(loggedInUser, state.user) ===
                                false && (
                                <FlexRow className="space-left mb-3">
                                    <ButtonGroup isStretchedToFit>
                                        {showCancelEmail && (
                                            <Button
                                                appearance="tertiary"
                                                onClick={() => {
                                                    dispatch(
                                                        cancelEmailChange(
                                                            accountToSave?.user_id
                                                        )
                                                    );
                                                    dispatch(emailChangeCancelled());
                                                }}
                                            >
                                                Cancel Email Change
                                            </Button>
                                        )}
                                        {canResendConfirmation && (
                                            <Button
                                                appearance="tertiary"
                                                onClick={() => {
                                                    dispatch(
                                                        resendConfirmationAction(
                                                            accountToSave
                                                        )
                                                    );
                                                    setShowToast(true);
                                                }}
                                            >
                                                Resend Confirmation Email
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </FlexRow>
                            )}
                        </FlexRow>
                        <FeatureFlag flag="iaEnableMfa">
                            <Divider className="mt-1 mb-3" />
                            <FlexRow className="mt-4 mb-3">
                                <FlexCol sm={6}>
                                    <div className="rec-color-gray">
                                        <Heading headingLevel={2} appearance="h5Caps">
                                            Multi-factor Authentication
                                        </Heading>
                                    </div>
                                </FlexCol>
                            </FlexRow>
                            <FlexRow className="space-left">
                                <div className="update-user-roles-table-header">
                                    Multi-factor Authentication Enrollment Status
                                </div>
                            </FlexRow>
                            {userMFAStatus &&
                                userMFAStatus.map((status) => {
                                    return (
                                        <MfaStatus
                                            key={`mfa-status-${status.key}`}
                                            userMFAStatus={status.userMFAStatus}
                                            additionalInfo={status.additionalInfo}
                                        />
                                    );
                                })}
                            <FlexRow className="space-left mt-2 mb-4">
                                <ButtonGroup isStretchedToFit>
                                    <Button
                                        appearance="tertiary"
                                        onClick={() =>
                                            setMFAModalState('reset', notEnrolledMFA)
                                        }
                                        isDisabled={notEnrolledMFA}
                                        gaTrackingId="809337037347"
                                    >
                                        Reset MFA
                                    </Button>
                                    <Button
                                        appearance="tertiary"
                                        onClick={() =>
                                            setMFAModalState('backup', notEnrolledMFA)
                                        }
                                        gaTrackingId="809337037347"
                                    >
                                        {`Generate ${!notEnrolledMFA ? 'New' : ''} Back Up Codes`}
                                    </Button>
                                    <Button
                                        appearance="tertiary"
                                        onClick={() =>
                                            setMFAModalState('enroll', notEnrolledMFA)
                                        }
                                        isDisabled={!notEnrolledMFA}
                                        gaTrackingId="809337037347"
                                    >
                                        Enroll New MFA
                                    </Button>
                                </ButtonGroup>
                            </FlexRow>
                        </FeatureFlag>
                        <FeatureFlag flag="enableOidc">
                            <FlexRow>
                                <FlexCol sm={6}>
                                    <Divider className="mt-1 mb-3" />
                                </FlexCol>
                            </FlexRow>
                            <FlexRow className="space-left">
                                <div className="update-user-roles-table-header">
                                    {loginDotGovLabel}
                                </div>
                            </FlexRow>
                            <Spacer size="sm" />
                            <Text className="sarsa-label mt-1">
                                {loginDotGovLabel} Link Status
                            </Text>
                            <Text>
                                {loginDotGovLinked
                                    ? 'Linked'
                                    : `${loginDotGovLabel} not linked. You may link ${loginDotGovLabel} to your account from the Hub login landing page.`}
                            </Text>
                            <FlexRow className="space-left mt-3 mb-4">
                                <ButtonGroup isStretchedToFit>
                                    <Button
                                        appearance="tertiary"
                                        onClick={() => {
                                            dispatch(unlinkLoginDotGovAction()).then(
                                                (resp) => {
                                                    if (resp instanceof Error) {
                                                        setTypeToast('error');
                                                        setMsgToast(
                                                            `Failed to unlink ${loginDotGovLabel} account!`
                                                        );
                                                    } else {
                                                        dispatch(
                                                            loginDotGovUnlinkedLoggedInUser()
                                                        );
                                                        setTypeToast('info');
                                                        setMsgToast(
                                                            `Unlink ${loginDotGovLabel} account was successful!`
                                                        );
                                                    }
                                                    setShowToast(true);
                                                }
                                            );
                                        }}
                                        isDisabled={!loginDotGovLinked}
                                    >
                                        {loginDotGovLinked
                                            ? `Unlink ${loginDotGovLabel}`
                                            : `${loginDotGovLabel} not linked`}
                                    </Button>
                                </ButtonGroup>
                            </FlexRow>
                        </FeatureFlag>
                    </AnchorPageSection>
                </AnchorPageWithSidebarLayout>
            </div>
        );
    };

    const effectiveStartAt = !isEmpty(state.user.effective_start_at)
        ? state.user.effective_start_at
        : null;
    const effectiveEndAt = !isEmpty(state.user.effective_end_at)
        ? state.user.effective_end_at
        : null;

    return (
        <div>
            <div className="edit-user-wrapper">
                <div id="page-body">
                    <ActionErrorDisplay
                        error={error}
                        errorStringMapping={errorForUpdateUser}
                    />
                    {updateUserStatusErrorMessage && (
                        <Alert shouldFocusOnMount type="error">
                            {updateUserStatusErrorMessage}
                        </Alert>
                    )}
                    {errorEnablingPasswordReset && (
                        <Alert shouldFocusOnMount type="error">
                            {errorEnablingPasswordReset}
                        </Alert>
                    )}
                    {requestConfirmResponse?.error && (
                        <Alert shouldFocusOnMount type="error">
                            {requestConfirmResponse?.error}
                        </Alert>
                    )}
                    {renderSidebarLayout(effectiveStartAt, effectiveEndAt)}
                </div>
            </div>
            <FeatureFlag flag="iaEnableMfa">
                <StyledModal
                    size="sm"
                    isOpen={state.isMFAModalOpen}
                    heading={state.titleMFAModal}
                    onRequestClose={closeModal}
                >
                    <div className="modal-mfa-text-body">{state.bodyMFAModal}</div>

                    {state.isMFABypassModalOpen && (
                        <TextArea
                            placeholder="Write the justification here:"
                            id="justification"
                            label=""
                            value={state.bypassMFAJustification}
                            onChange={(e: TargetedEvent) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    bypassMFAJustification: e.target.value,
                                }))
                            }
                        />
                    )}

                    <ModalActions>
                        <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                            {!state.isNewMFABackupCodesModalOpen && (
                                <Button
                                    appearance="tertiary"
                                    onClick={closeModal}
                                    gaTrackingId="649122101685"
                                    isDisabled={spinningModal}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSpinningModal(true);
                                    state.actionMFAModal();
                                }}
                                gaTrackingId="649122101685"
                                isLoading={spinningModal}
                                isDisabled={
                                    spinningModal ||
                                    (state.isMFABypassModalOpen &&
                                        state.bypassMFAJustification.length <= 1)
                                }
                            >
                                {state.isMFABypassModalOpen
                                    ? 'Confirm MFA Bypass'
                                    : 'Okay'}
                            </Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
            </FeatureFlag>
            <NewEmailAddressModal
                isOpen={state.isNewEmailModalOpen}
                email={state.user.new_email}
                currentEmail={state.user.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateStateUser({
                        new_email: e.target.value,
                    });
                }}
                onConfirm={() => {
                    closeNewEmailModal();
                    setTypeToast('info');
                    setMsgToast(
                        `A change to the user's email address has been requested. The user will receive a confirmation email (sent to the requested address) and must click a link in that email to confirm this change.`
                    );
                    handleSave();
                }}
                onClose={closeNewEmailModal}
            />
            <UserStatusToggle
                isOpen={state.statusToggleModalIsOpen}
                selectedUser={state.user}
                cancel={closeStatusToggleModal}
                confirm={confirmStatusToggleModal}
            />
        </div>
    );
}
