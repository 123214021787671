/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    CalendarDate,
    CalendarDateTime,
    DateValue,
    getLocalTimeZone,
    now,
    parseAbsolute,
    parseAbsoluteToLocal,
    parseDate,
    parseDateTime,
    parseZonedDateTime,
    toZoned,
    ZonedDateTime,
} from '@internationalized/date';
import { DateRange } from '@react-types/datepicker';
import { isNil, isNull } from 'lodash';
import { type TzTimeZone } from 'sarsaparilla';
import {
    CalendarDateOrString,
    CalendarDateTimeOrString,
    ZonedDateTimeOrString,
} from '../constants/types';

type CommDates = {
    created_at: string | ZonedDateTime;
    begin_at: string | ZonedDateTime;
    end_at: string | ZonedDateTime;
    reservation_begin_at: string | ZonedDateTime;
    reservation_end_at: string | ZonedDateTime;
    canceled_at: string | ZonedDateTime;
    scheduled_at: string | ZonedDateTime;
    sending_started_at: string | null | ZonedDateTime;
};

export type FormatDateOptions =
    | {
          format:
              | 'L'
              | 'l'
              | 'LL'
              | 'll'
              | 'LLL'
              | 'lll'
              | 'LLLL'
              | 'llll'
              | 'LT'
              | 'LTS';
          timeZone?: TzTimeZone;
          showTimeZone?: boolean;
      }
    | Intl.DateTimeFormatOptions;

export function sortCommunicationsByDateRule(left: CommDates, right: CommDates) {
    const parsedLeft = parseZonedOrStringToUTC(left.created_at);
    const parsedRight = parseZonedOrStringToUTC(right.created_at);

    return parsedRight.compare(parsedLeft);
}

export function isCalendarDateTime(
    dt: CalendarDateTimeOrString | null
): dt is CalendarDateTime {
    return dt instanceof CalendarDateTime;
}

export function isZonedDateTime(dt: ZonedDateTimeOrString | null): dt is ZonedDateTime {
    return dt instanceof ZonedDateTime;
}

export function isString(dt: DateValue | string | null): dt is string {
    return typeof dt === 'string';
}

export function endOfDay(cdt: DateValue): DateValue {
    if (isNil(cdt)) {
        return cdt;
    }
    return cdt.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
}

export function startOfDay(cdt: DateValue): DateValue {
    if (isNil(cdt)) {
        return cdt;
    }
    return cdt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function parseDateString(dt: CalendarDateOrString): CalendarDate {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return parseDate(dt);
    }
    return dt;
}

export function parseDateTimeOrString(dt: CalendarDateTimeOrString): CalendarDateTime {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return parseDateTime(dt);
    }
    return dt;
}

export function parseZonedDateTimeOrStringTZ(
    dt: ZonedDateTime | string,
    tz: string
): ZonedDateTime {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return parseAbsolute(dt, tz);
    }

    return dt;
}

export function parseZonedOrStringToUTC(dt: ZonedDateTime | string): ZonedDateTime {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        if (dt.includes('[')) {
            return parseAbsolute(parseZonedDateTime(dt).toAbsoluteString(), 'UTC');
        }
        return parseAbsolute(dt, 'UTC');
    }

    return parseAbsolute(dt.toAbsoluteString(), 'UTC');
}

export function parseZonedDateTimeOrString(dt: ZonedDateTime | string): ZonedDateTime {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return parseAbsoluteToLocal(dt);
    }
    return dt;
}

export function startOfDayToUTC(dt: DateValue | string): string {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return (startOfDay(parseAbsolute(dt, 'UTC')) as ZonedDateTime).toAbsoluteString();
    }
    return (startOfDay(dt) as ZonedDateTime).toAbsoluteString();
}

export function endOfDayToUTC(dt: DateValue | string): string {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return (endOfDay(parseAbsolute(dt, 'UTC')) as ZonedDateTime).toAbsoluteString();
    }
    return (endOfDay(dt) as ZonedDateTime).toAbsoluteString();
}

export function toZonedStartOfDayToUTC(dt: DateValue | string): string {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return (startOfDay(parseAbsolute(dt, 'UTC')) as ZonedDateTime).toAbsoluteString();
    }
    return (startOfDay(dt) as ZonedDateTime).toAbsoluteString();
}

export function toZonedEndOfDayToUTC(dt: DateValue | string): string {
    if (isNil(dt)) {
        return dt;
    }
    if (isString(dt)) {
        return (endOfDay(parseAbsolute(dt, 'UTC')) as ZonedDateTime).toAbsoluteString();
    }
    return (endOfDay(dt) as ZonedDateTime).toAbsoluteString();
}

export function toZonedUTC(dt: DateValue): string {
    if (isNil(dt)) {
        return dt;
    }

    return toZoned(dt, 'UTC').toAbsoluteString();
}

export function toZonedLocal(dt: DateValue): string {
    if (isNil(dt)) {
        return dt;
    }

    return toZoned(dt, getLocalTimeZone()).toAbsoluteString();
}

export function toZonedLocalStartOfDay(dt: DateValue): string {
    if (isNil(dt)) {
        return dt;
    }

    const zoned = toZoned(dt, getLocalTimeZone());
    return (startOfDay(zoned) as ZonedDateTime).toAbsoluteString();
}

export function toZonedLocalEndOfDay(dt: DateValue): string {
    if (isNil(dt)) {
        return dt;
    }
    const zoned = toZoned(dt, getLocalTimeZone());
    return (endOfDay(zoned) as ZonedDateTime).toAbsoluteString();
}

export function toZonedUTCStartOfDay(dt: DateValue): string {
    if (isNil(dt)) {
        return dt;
    }

    const zoned = toZoned(dt, 'UTC');
    return (startOfDay(zoned) as ZonedDateTime).toAbsoluteString();
}

export function toZonedUTCEndOfDay(dt: DateValue): string {
    if (isNil(dt)) {
        return dt;
    }
    const zoned = toZoned(dt, 'UTC');
    return (endOfDay(zoned) as ZonedDateTime).toAbsoluteString();
}

export function zonedNow(): ZonedDateTime {
    return now(getLocalTimeZone());
}

export function zonedNowUTC(): ZonedDateTime {
    return now('UTC');
}

export function isBefore(a: DateValue, b: DateValue): boolean {
    return a.compare(b) < 0;
}

export function isAfter(a: DateValue, b: DateValue): boolean {
    return a.compare(b) > 0;
}

export function equals(a: DateValue, b: DateValue): boolean {
    return a.compare(b) === 0;
}

export function areDiff(a: DateValue | null, b: DateValue | null | string): boolean {
    if (isNull(a) && !isNull(b)) {
        return true;
    }

    if (!isNull(a) && isNull(b)) {
        return true;
    }

    if (
        !isNull(a) &&
        !isNull(b) &&
        !equals(a, parseZonedDateTimeOrString(b as ZonedDateTime | string))
    ) {
        return true;
    }

    return false;
}

export const convertCommunicationDates = (comm: CommDates): CommDates => {
    if (!comm) return comm;

    const out: CommDates = {
        ...comm,
        created_at: parseZonedOrStringToUTC(comm.created_at),
        begin_at: parseZonedOrStringToUTC(comm.begin_at),
        end_at: parseZonedOrStringToUTC(comm.end_at),
        reservation_begin_at: parseZonedOrStringToUTC(comm.reservation_begin_at),
        reservation_end_at: parseZonedOrStringToUTC(comm.reservation_end_at),
        canceled_at: parseZonedOrStringToUTC(comm.canceled_at),
        scheduled_at: parseZonedOrStringToUTC(comm.scheduled_at),
    };

    if (out.sending_started_at === '0001-01-01T00:00:00Z') {
        out.sending_started_at = null;
    }
    return out;
};

export function ensureRangeValue(
    startDate: DateValue | string | null,
    endDate: DateValue | string | null
): DateRange | null {
    const start = isNull(startDate)
        ? null
        : isString(startDate)
          ? parseAbsoluteToLocal(startDate)
          : startDate;

    const end = isNull(endDate)
        ? null
        : isString(endDate)
          ? parseAbsoluteToLocal(endDate)
          : endDate;

    if (start && end) {
        return {
            start,
            end,
        };
    }

    return null;
}

export function displayTimeFromString(time: string): string {
    const aux = time.split(':');

    return displayTime(parseInt(aux[0], 10), parseInt(aux[1], 10));
}

export function displayTime(hours: number, minutes: number): string {
    const amPm = hours >= 12 ? 'pm' : 'am';
    const h = hours % 12 === 0 ? 12 : hours % 12;
    const m = minutes < 10 ? `0${minutes}` : minutes;

    return `${h}:${m} ${amPm}`;
}

// the time param is a string formatted as hh:mm:a (a is am or pm)
export function format24hTime(time: string): string {
    const aux = time.split(' ');
    if (aux.length !== 2) {
        return time;
    }
    const [hours, minutes] = aux[0].split(':');
    const amPm = aux[1];
    const modifier = amPm === 'pm' ? 12 : 0;

    return `${parseInt(hours, 10) + modifier}:${parseInt(minutes, 10)}`;
}
