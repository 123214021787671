/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Icon,
    ModalActions,
    StyledModal,
    TextFieldStateless,
    Switch,
    TYPES,
    Alert,
} from 'sarsaparilla';
import { compileAddress } from '../../utilities/locations';
import TextHighlight from '../tableUtil/TextHighlight';
import SelectRowTable from '../tableUtil/SelectRowTable';
import CooperatorDetailsTableActions from './CooperatorDetailsTableActions';
import UserStatusToggle from '../UserStatusToggle';

const propTypes = {
    history: TYPES.HISTORY,
    gridData: PropTypes.array,
    cooperator: PropTypes.any,
    setCooperator: PropTypes.func,
    setEmployee: PropTypes.func,
    saveCooperators: PropTypes.func,
    deleteEmployees: PropTypes.func,
    errorText: PropTypes.string,
    updateUserStatus: PropTypes.func,
    setInitialValue: PropTypes.func,
    updateUserStatusSuccessful: PropTypes.bool,
    isShowingUsersLock: PropTypes.bool,
    selectedFlatRows: PropTypes.arrayOf(
        PropTypes.shape({
            original: PropTypes.object,
        })
    ),
};

export default function CooperatorDetails({
    history,
    gridData,
    cooperator,
    setCooperator,
    setEmployee,
    saveCooperators,
    updateUserStatus,
    deleteEmployees,
    errorText,
    setInitialValue,
    updateUserStatusSuccessful,
    isShowingUsersLock,
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalUsers, setModalUsers] = useState([]);
    const [statusToggleModalIsOpen, setStatusToggleModalIsOpen] = useState(false);
    const [statusToggleUser, setStatusToggleUser] = useState({});

    function closeModal() {
        setModalUsers([]);
        setModalOpen(false);
    }

    function doDelete() {
        deleteEmployees(modalUsers);
        closeModal();
    }

    const navEditEmployee = useCallback(
        (employee) => {
            setEmployee(employee || {});
            history.push('/internal/account/cooperator-employee-edit');
        },
        [setEmployee, history]
    );

    const navEditCooperator = useCallback(() => {
        history.push('/internal/account/cooperator-edit');
    }, [history]);

    const onChangeUserLock = useCallback(
        (user) => {
            setInitialValue();
            setStatusToggleModalIsOpen(true);
            setStatusToggleUser(user);
        },
        [setInitialValue, setStatusToggleModalIsOpen, setStatusToggleUser]
    );

    const onConfirmStatusToggleModal = (note) => {
        updateUserStatus(statusToggleUser, note);
        setStatusToggleModalIsOpen(false);
    };

    const renderEmployeeName = useCallback(
        (row, globalFilter) => {
            return (
                <Button
                    onClick={() => {
                        navEditEmployee(row.original);
                    }}
                    appearance="link"
                >
                    <TextHighlight
                        searchTerm={globalFilter || ''}
                        body={`${row.original.first_name} ${row.original.last_name}`}
                    />
                </Button>
            );
        },
        [navEditEmployee]
    );

    const renderEmail = useCallback((row, globalFilter) => {
        return (
            <TextHighlight searchTerm={globalFilter || ''} body={row.original.email} />
        );
    }, []);

    const renderActions = useCallback(
        (row) => {
            return (
                <ButtonGroup buttonMargin={1}>
                    <Button
                        className="edit-cooperator-button"
                        onClick={() => {
                            navEditEmployee(row.original);
                        }}
                        appearance="tertiary"
                        size="xs"
                    >
                        Edit
                    </Button>
                    {isShowingUsersLock && (
                        <Button
                            className="lock-unlock-cooperator-button"
                            onClick={() => onChangeUserLock(row.original)}
                            appearance="tertiary"
                            size="xs"
                        >
                            {row.original.locked ? 'Unlock' : 'Lock'}
                        </Button>
                    )}
                    <Button
                        className="remove-cooperator-button"
                        onClick={() => {
                            setModalUsers([row.original]);
                            setModalOpen(true);
                        }}
                        appearance="tertiary-danger"
                        size="xs"
                    >
                        Delete
                    </Button>
                </ButtonGroup>
            );
        },
        [isShowingUsersLock, navEditEmployee, onChangeUserLock]
    );

    const newTableColumns = useMemo(
        () => [
            {
                Header: 'Employee Name',
                accessor: (row) => `${row.first_name} ${row.last_name}`.toLowerCase(),
                id: 'employee_name',
                Cell: ({ row, state: { globalFilter } }) =>
                    renderEmployeeName(row, globalFilter),
                width: 70,
                minWidth: 70,
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ row, state: { globalFilter } }) =>
                    renderEmail(row, globalFilter),
                width: 110,
                minWidth: 110,
            },
            {
                Header: 'Actions',
                width: 40,
                minWidth: 40,
                sortable: false,
                disableGlobalFilter: true,
                Cell: ({ row }) => renderActions(row),
            },
        ],
        [renderEmployeeName, renderEmail, renderActions]
    );

    const selectActions = useCallback(
        (props) => (
            <CooperatorDetailsTableActions
                selectedFlatRows={props.selectedFlatRows}
                onDeleteClick={(selectedRows) => {
                    setModalOpen(true);
                    setModalUsers(selectedRows);
                }}
                onEditClick={navEditCooperator}
            />
        ),
        [navEditCooperator]
    );

    const filterUI = useCallback(
        ({ setGlobalFilter, globalFilter }) => (
            <TextFieldStateless
                label="Search items"
                isLabelVisible={false}
                placeholder="Search items"
                onChange={({ target: { value } }) => setGlobalFilter(value)}
                handleClearButtonClick={() => setGlobalFilter('')}
                hasClearButton
                value={globalFilter || ''}
                iconElement={<Icon iconName="search" />}
                id="filter-text-search"
            />
        ),
        []
    );

    if (!cooperator) {
        return (
            <Alert shouldFocusOnMount type="error" className="cooperators-error">
                Unable to find the cooperator you wish to edit.
            </Alert>
        );
    }

    const name = cooperator.cooperator_name
        ? cooperator.cooperator_name.replace(/([A-Za-z],)([A-Za-z])/, '$1 $2')
        : '<Unknown>';

    return (
        <div className="cooperator-details-wrapper">
            <div className="cooperators-header">
                <h2 className="cooperator-component-title h5">
                    <span className="header-main-title">Cooperator Details</span>
                    <span className="header-break" />
                    <span className="header-sub-title">
                        {name}
                        {cooperator.locked && <Icon iconName="lock" />}
                    </span>
                </h2>
                <div className="cooperator-controls">
                    <div id="updateCooperatorLabel" className="rec-label-toggle mt-1">
                        <Switch
                            id="cooperator-status"
                            isSelected={cooperator.locked}
                            onChange={() => {
                                const coop = {
                                    ...cooperator,
                                    locked: !cooperator.locked,
                                };
                                setCooperator(coop);
                                saveCooperators([coop]);
                            }}
                            label={cooperator.locked ? 'Locked' : 'Unlocked'}
                        />
                    </div>
                    <span className="header-break controls-break" />
                    <Button
                        className="ia-add-employee"
                        iconBeforeElement={<Icon iconName="add-circle" />}
                        onClick={() => navEditEmployee(null)}
                    >
                        Add Employee
                    </Button>
                    <Button
                        className="ia-edit-cooperator"
                        iconBeforeElement={<Icon iconName="edit" />}
                        onClick={navEditCooperator}
                    >
                        Edit Cooperator
                    </Button>
                </div>
            </div>

            <div className="cooperators-detail-box">
                <table>
                    <caption className="rec-sr-only">Cooperator Details</caption>
                    <thead>
                        <tr>
                            <th>Primary Contact</th>
                            <th>Secondary Contact</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Email Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {cooperator.designated_agent ? (
                                    cooperator.designated_agent.replace(
                                        /([A-Za-z],)([A-Za-z])/,
                                        '$1 $2'
                                    )
                                ) : (
                                    <i>- -</i>
                                )}
                            </td>
                            <td>
                                {cooperator.secondary_contact ? (
                                    cooperator.secondary_contact.replace(
                                        /([A-Za-z],)([A-Za-z])/,
                                        '$1 $2'
                                    )
                                ) : (
                                    <i>- -</i>
                                )}
                            </td>
                            <td>{compileAddress(cooperator.address) || <i>- -</i>}</td>
                            <td>{cooperator.phone_number || <i>- -</i>}</td>
                            <td>{cooperator.email_address || <i>- -</i>}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {updateUserStatusSuccessful && (
                <Alert shouldFocusOnMount type="success" className="mt-3">
                    User has been updated.
                </Alert>
            )}

            {errorText && (
                <Alert shouldFocusOnMount type="error" className="cooperators-error">
                    {errorText}
                </Alert>
            )}

            <div className="cooperators-body">
                <SelectRowTable
                    data={gridData}
                    defaultSortId="employee_name"
                    noDataText="No Employees found."
                    columns={newTableColumns}
                    selectActionComponent={selectActions}
                    filtersComponent={filterUI}
                    tableCaption="employee details"
                />

                <StyledModal
                    heading={`Delete User${modalUsers.length > 1 ? 's' : ''}`}
                    onRequestClose={closeModal}
                    isOpen={modalOpen}
                    size="md"
                >
                    <div className="delete-user-warning-modal-body">
                        <div className="modal-sub-title">
                            Would you like to delete the following:
                        </div>
                        <ul>
                            {modalUsers.map((obj, i) => (
                                <li key={`list_employee_${i}`}>
                                    {`${obj.first_name} ${obj.last_name}`}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <ModalActions>
                        <ButtonGroup>
                            <Button onClick={closeModal} appearance="tertiary">
                                Cancel
                            </Button>
                            <Button onClick={doDelete}>Confirm and Delete</Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
            </div>
            <UserStatusToggle
                isOpen={statusToggleModalIsOpen}
                selectedUser={statusToggleUser}
                cancel={() => setStatusToggleModalIsOpen(false)}
                confirm={onConfirmStatusToggleModal}
            />
        </div>
    );
}

CooperatorDetails.propTypes = propTypes;
