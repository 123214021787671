/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import moment from 'moment';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import * as types from '../constants/types';
import * as globals from '../constants/globals';
import { makeAuthorizedRequest } from './login';
import { doneLoading, startLoading } from './loading';
import { setConcessionaire } from './manageConcessionairesSet';
import { validateConcessionaire, validatePermit } from '../utilities/validation';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';

type ConcessionairesAction = {
    type: string;
    error?: Error;
    concessionaire?: types.Concessionaire;
    concessionaires?: types.Concessionaire[];
    permit?: types.Permit;
    permits?: types.Permit[];
};

const getActivePermits = (conc: types.Concessionaire): types.Permit[] => {
    return conc.permits.filter((permit: types.Permit) => {
        const startDays: number =
            -1 * moment({ hours: 0 }).diff(permit.permit_start_date, 'days');
        const endDays: number =
            -1 * moment({ hours: 0 }).diff(permit.permit_end_date, 'days');
        return startDays <= 0 && endDays > 0;
    });
};

const getExpiringPermits = (conc: types.Concessionaire): types.Permit[] => {
    return conc.permits.filter((permit: types.Permit) => {
        const days: number =
            -1 * moment({ hours: 0 }).diff(permit.permit_end_date, 'days');
        return days <= 60 && days > 0;
    });
};

// LOAD
const reloadManagedConcessionaireErrors = (): ConcessionairesAction => {
    return {
        type: types.RELOAD_MANAGE_CONCESSIONAIRE_ERRORS,
    };
};

const loadedManagedConcessionaires = (
    concessionaires: types.Concessionaire[]
): ConcessionairesAction => {
    return {
        concessionaires: concessionaires.map((conc: types.Concessionaire) =>
            Object.assign(conc, {
                activePermits: getActivePermits(conc),
                expiringPermits: getExpiringPermits(conc),
            })
        ),
        type: types.LOADING_MANAGED_CONCESSIONAIRES_SUCCESSFUL,
    };
};

const loadingManagedConcessionairesFailed = (error: Error): ConcessionairesAction => {
    return {
        error,
        type: types.LOADING_MANAGED_CONCESSIONAIRES_FAILED,
    };
};

const loadingConcessionairePermitsFailed = (error: Error): ConcessionairesAction => {
    return {
        error,
        type: types.LOADING_CONCESSIONAIRE_PERMITS_FAILED,
    };
};

export const loadManagedConcessionaires = () => {
    return async (dispatch: Function): Promise<void> => {
        dispatch(reloadManagedConcessionaireErrors());
        dispatch(startLoading('Loading Concessionaire Data'));

        try {
            const managedConcessionairesResponse = await makeAuthorizedRequest(
                `${globals.API_URL}/v2/concessionaire`,
                'GET',
                dispatch
            );
            const concessionaires = managedConcessionairesResponse.concessionaires;

            dispatch(loadedManagedConcessionaires(concessionaires));
        } catch (error: any) {
            dispatch(loadingManagedConcessionairesFailed(error));
            dispatch(loadingConcessionairePermitsFailed(error));
        } finally {
            dispatch(doneLoading());
        }
    };
};

const loadedConcessionairePermits = (permits: types.Permit[]): ConcessionairesAction => {
    return {
        permits,
        type: types.LOADING_CONCESSIONAIRE_PERMITS_SUCCESSFUL,
    };
};

export const loadConcessionairePermits = () => {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        dispatch(reloadManagedConcessionaireErrors());
        dispatch(startLoading('Loading Permits'));

        try {
            const state = getState();
            const concId =
                state.manageConcessionairesSet.managedConcessionaire.concessionaire_id;

            const concessionaireResponse = await makeAuthorizedRequest(
                `${globals.API_URL}/v2/concessionaire/${concId}`,
                'GET',
                dispatch
            );
            const permits = concessionaireResponse.concessionaire.permits;

            dispatch(loadedConcessionairePermits(permits));
        } catch (error: any) {
            dispatch(loadingConcessionairePermitsFailed(error));
        }

        dispatch(doneLoading());
    };
};

// SAVE
const saveConcessionaireSuccessful = (
    concessionaire: types.Concessionaire
): ConcessionairesAction => {
    return {
        concessionaire,
        type: types.SAVING_CONCESSIONAIRE_SUCCESSFUL,
    };
};

const saveConcessionaireFailed = (error: Error): ConcessionairesAction => {
    return {
        error,
        type: types.SAVING_CONCESSIONAIRE_FAILED,
    };
};

const savePermitConcessionaireFailed = (error: Error): ConcessionairesAction => {
    return {
        error,
        type: types.SAVING_CONCESSIONAIRE_PERMIT_FAILED,
    };
};

export const saveConcessionaire = (
    concessionaire: types.Concessionaire,
    add: boolean,
    history: RouteComponentProps['history']
) => {
    return async (dispatch: Function): Promise<void> => {
        dispatch(reloadManagedConcessionaireErrors());
        dispatch(startLoading('Saving...'));

        try {
            validateConcessionaire(concessionaire);

            const concId: string = concessionaire.concessionaire_id;
            const method: string = concId ? 'PUT' : 'POST';
            const url: string = concId ? `/${concId}` : '';
            const body: string = JSON.stringify({
                ...concessionaire,
                primary_contact_phone: concessionaire.primary_contact_phone
                    ? concessionaire.primary_contact_phone.replace(/[^0-9]/g, '')
                    : null,
                secondary_contact_phone: concessionaire.secondary_contact_phone
                    ? concessionaire.secondary_contact_phone.replace(/[^0-9]/g, '')
                    : null,
            });

            const concessionaireResponse = await makeAuthorizedRequest(
                `${globals.API_URL}/v2/concessionaire${url}`,
                method,
                dispatch,
                body
            );
            const updated = concessionaireResponse.concessionaire;

            dispatch(saveConcessionaireSuccessful(updated));

            if (add) {
                dispatch(setConcessionaire(updated));
                const navigate = makeCompatNavigate(history);
                navigate('/internal/account/concessionaire-details');
            }
        } catch (error: any) {
            dispatch(saveConcessionaireFailed(error));
            dispatch(savePermitConcessionaireFailed(error));
        }

        dispatch(doneLoading());
    };
};

const savePermitSuccessful = (permit: types.Permit): ConcessionairesAction => {
    return {
        permit,
        type: types.SAVING_CONCESSIONAIRE_PERMIT_SUCCESSFUL,
    };
};

const savePermitFailed = (error: Error): ConcessionairesAction => {
    return {
        error,
        type: types.SAVING_CONCESSIONAIRE_PERMIT_FAILED,
    };
};

export const savePermit = (
    permit: types.Permit,
    add: boolean,
    history: RouteComponentProps['history']
) => {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        dispatch(reloadManagedConcessionaireErrors());
        dispatch(startLoading('Saving...'));

        try {
            const state = getState();
            const concId =
                state.manageConcessionairesSet.managedConcessionaire.concessionaire_id;
            const sendPermit = {
                ...permit,
                concessionaire_id: concId,
                permit_effective_date: moment(permit.permit_start_date)
                    .utc()
                    .startOf('day'),
                permit_start_date: moment(permit.permit_start_date).utc().startOf('day'),
                permit_end_date: moment(permit.permit_end_date).utc().startOf('day'),
            };

            validatePermit(sendPermit);

            const permitId: string = sendPermit.permit_id;
            const method: string = permitId ? 'PUT' : 'POST';
            const url: string = permitId ? `/${permitId}` : '';
            const body: string = JSON.stringify({
                permit_effective_date: sendPermit.permit_effective_date,
                permit_start_date: sendPermit.permit_start_date,
                permit_end_date: sendPermit.permit_end_date,
                location_id: sendPermit.location_id,
                location_type: sendPermit.location_type,
                location_type_category: sendPermit.location_type_category,
                location_name: sendPermit.location_name,
                location_legacy_id: sendPermit.location_legacy_id,
                location_path: sendPermit.location_path,
                location_state_code: sendPermit.location_state_code,
            });

            const permitResponse = await makeAuthorizedRequest(
                `${globals.API_URL}/v2/concessionaire/${concId}/permit${url}`,
                method,
                dispatch,
                body
            );
            const updated = permitResponse.permit;

            dispatch(savePermitSuccessful(updated));

            if (add) {
                const navigate = makeCompatNavigate(history);
                navigate('/internal/account/concessionaire-details');
            }
        } catch (error: any) {
            dispatch(savePermitFailed(error));
        }

        dispatch(doneLoading());
    };
};

const deletePermitSuccessful = (permit: types.Permit): ConcessionairesAction => {
    return {
        permit,
        type: types.DELETE_CONCESSIONAIRE_PERMIT_SUCCESSFUL,
    };
};

const deletePermitFailed = (error: Error): ConcessionairesAction => {
    return {
        error,
        type: types.DELETING_MANAGED_COOPERATORS_FAILED,
    };
};

export const deletePermit = (
    permit: types.Permit,
    history: RouteComponentProps['history']
) => {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        dispatch(reloadManagedConcessionaireErrors());
        dispatch(startLoading('Deleting...'));

        try {
            const state = getState();
            const concId =
                state.manageConcessionairesSet.managedConcessionaire.concessionaire_id;

            const permitId: string = permit.permit_id;
            const method: string = 'DELETE';

            await makeAuthorizedRequest(
                `${globals.API_URL}/v2/concessionaire/${concId}/permit/${permitId}`,
                method,
                dispatch
            );

            dispatch(deletePermitSuccessful(permit));

            const navigate = makeCompatNavigate(history);
            navigate('/internal/account/concessionaire-details');
        } catch (error: any) {
            dispatch(deletePermitFailed(error));
        }

        dispatch(doneLoading());
    };
};

// FOR UM

const fetchAssignableConcessionairePermitsSuccess = (
    permits: types.Permit[]
): ConcessionairesAction => {
    return {
        permits,
        type: types.FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_SUCCESSFUL,
    };
};

const fetchAssignableConcessionairePermitsFailed = (
    error: Error
): ConcessionairesAction => {
    return {
        error,
        type: types.FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_FAILED,
    };
};

export const fetchAssignableConcessionairePermits = () => {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        dispatch(reloadManagedConcessionaireErrors());

        try {
            const state = getState();
            const concIds = get(state.updateUser, 'accountToSave.concessionaire_ids', []);

            if (concIds.length > 0) {
                const fetches = concIds.map((concId: string) => {
                    return new Promise((resolve, reject): void => {
                        makeAuthorizedRequest(
                            `${globals.API_URL}/v2/concessionaire/${concId}`,
                            'GET',
                            dispatch
                        )
                            .then((concessionaireResponse): void => {
                                const permits =
                                    concessionaireResponse.concessionaire.permits;
                                resolve(permits);
                            })
                            .catch((error): void => {
                                reject(error);
                            });
                    });
                });

                const permitsCollection = await Promise.all(fetches);
                dispatch(
                    fetchAssignableConcessionairePermitsSuccess([...permitsCollection])
                );
            }
        } catch (error: any) {
            dispatch(fetchAssignableConcessionairePermitsFailed(error));
        }
    };
};
