/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import * as globals from '../constants/globals';
import { setSelectedLocationRole } from './selectedLocationRole';
import { makeAuthorizedRequest } from './login';
import { getAccountWithToken } from '../utilities/internalSecurityUtil';
import { fetchLocationInfo } from './machineApi';

export function fetchChildLocations(location) {
    return async (dispatch) => {
        const apiUrl = globals.API_INVENTORY_URL;
        const endpoint = `${apiUrl}/location-children?location_id=${encodeURIComponent(
            location.location_id
        )}&location_type=${encodeURIComponent(location.location_type)}`;
        const result = await makeAuthorizedRequest(endpoint, 'GET', dispatch);
        return result.children;
    };
}

export const fetchDomainsForLocationFailure = (error) => ({
    type: types.FETCH_DOMAINS_FOR_LOCATION_ERROR,
    error,
});

export const fetchDomainsForLocationSuccess = (locationId, locDomains) => ({
    type: types.FETCH_DOMAINS_FOR_LOCATION_SUCCESS,
    locationId,
    locDomains,
    error: null,
});

const fetchDomainsForRolesSuccess = (domains) => {
    return {
        type: types.FETCH_DOMAINS_FOR_ROLES_SUCCESS,
        locDomains: domains,
    };
};

export const getDomainsForLocation = (loc, history) => {
    const locKey = `${loc?.location_id}_${loc?.location_type}`;
    return async (dispatch, getState) => {
        try {
            dispatch(fetchLocationInfo(loc));

            const state = getState();
            const user = state.login?.user;
            if (user === null) {
                return;
            }

            const url = `${globals.API_URL}/user/${user.user_id}/location/${loc.location_id}/domains`;
            const response = await makeAuthorizedRequest(url, 'GET', dispatch);

            const accountFromLocalStorage = getAccountWithToken();

            const accountUpdate = {
                ...accountFromLocalStorage,
                domains_by_location_id: {
                    ...accountFromLocalStorage.domains_by_location_id,
                    ...response.domains_by_location_id,
                },
            };
            window.localStorage.setItem(
                globals.TOKEN_LOCAL_STORAGE_KEY,
                JSON.stringify(accountUpdate)
            );

            const domains = response.domains_by_location_id[locKey]
                ? response.domains_by_location_id[locKey]
                : null;
            dispatch(fetchDomainsForRolesSuccess(accountUpdate.domains_by_location_id));
            dispatch(
                fetchDomainsForLocationSuccess(
                    domains.role.location.location_id,
                    response.domains_by_location_id
                )
            );
            dispatch(
                setSelectedLocationRole(
                    domains.role,
                    response.domains_by_location_id,
                    history
                )
            );
        } catch (error) {
            dispatch(fetchDomainsForLocationFailure(error));
        }
    };
};
