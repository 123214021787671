/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as errors from '../constants/errors';
import * as globals from '../constants/globals';
import * as types from '../constants/types';
import { doneLoading, startLoading } from './loading';
import { logOut, makeAuthorizedRequest, updateSession } from './login';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';

export const changePasswordFailed = (error) => {
    let errorMessage = error;

    if (error.message) {
        errorMessage = error.message;
    }

    return {
        type: types.CHANGE_PASSWORD_FAILED,
        error: errorMessage,
    };
};

export const changePasswordSuccessful = () => {
    return {
        type: types.CHANGE_PASSWORD_SUCCESSFUL,
    };
};

export const cancelChangePassword = (history, dispatch) => {
    const tokenString = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY);
    const tokenObject = tokenString ? JSON.parse(tokenString) : null;
    const noReset =
        tokenObject && tokenObject.user && !tokenObject.user.must_set_password;

    if (noReset) {
        history.goBack();
    } else {
        dispatch(logOut());
        const navigate = makeCompatNavigate(history);
        navigate(globals.LOGIN_PATH);
    }

    return {
        type: noReset
            ? types.CHANGE_PASSWORD_CANCELED
            : types.LOGGED_OUT_FOR_MUST_RESET_PASSWORD,
    };
};

export const changePassword = (
    currentPassword,
    newPassword,
    confirmedNewPassword,
    history,
    isConfirmEmailChange
) => {
    if (newPassword !== confirmedNewPassword) {
        return changePasswordFailed(errors.PASSWORDS_DO_NOT_MATCH);
    }

    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const state = getState();
            const loggedInUserId = state.login.account.user.user_id;

            const postData = {
                new_password: newPassword,
                old_password: currentPassword,
            };

            const url = `${globals.API_URL}/change-password/${loggedInUserId}`;
            await makeAuthorizedRequest(url, 'POST', dispatch, JSON.stringify(postData));

            dispatch(changePasswordSuccessful());

            const updatedUser = { ...state.login.account.user, must_set_password: false };
            dispatch(updateSession(updatedUser, history, isConfirmEmailChange));
        } catch (error) {
            dispatch(changePasswordFailed(error));
        }
        dispatch(doneLoading());
    };
};
