/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

import {
    loadManagedConcessionaires,
    saveConcessionaire,
    savePermit,
} from '../actions/manageConcessionaires';
import { setConcessionaire, setPermit } from '../actions/manageConcessionairesSet';

import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';
import ManageConcessionaires from '../components/concessionaire/ManageConcessionaires';
import ManageBlockingConcessionairePermits from '../components/concessionaire/ManageBlockingConcessionairePermits';

const navBackUrl = null;
const crumbs = [];

export class ManageConcessionairesContainer extends React.PureComponent {
    static propTypes = {
        history: TYPES.HISTORY,
        concessionaire: PropTypes.any,
        permit: PropTypes.any,
        concessionaires: PropTypes.array,
        loadingManagedConcessionairesError: PropTypes.any,
        loadManagedConcessionaires: PropTypes.func,
        setConcessionaire: PropTypes.func,
        setPermit: PropTypes.func,
        saveConcessionaire: PropTypes.func,
        savePermit: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };

        this.tabs = [
            {
                title: 'Concessionaires',
                onClick: () => {
                    this.setState({ currentTab: 0 });
                },
            },
            {
                title: 'Blocking Permits',
                onClick: () => {
                    this.setState({ currentTab: 1 });
                },
            },
        ];
    }

    componentDidMount() {
        this.props.loadManagedConcessionaires();
    }

    setConcessionaire = (concessionaire) => {
        this.props.setConcessionaire(concessionaire);
    };

    setPermit = (permit) => {
        this.props.setPermit(permit);
    };

    saveConcessionaire = (concessionaire, add) => {
        const conc = concessionaire || this.props.concessionaire;
        this.props.saveConcessionaire(conc, add);
    };

    savePermit = (permit, add) => {
        const perm = permit || this.props.permit;
        this.props.savePermit(perm, add);
    };

    render() {
        const {
            history,
            concessionaires,
            concessionaire,
            permit,
            loadingManagedConcessionairesError,
        } = this.props;

        const contentComponents = [
            <div id="ConcessionaireManagement" className="page-content wrapper">
                <ManageConcessionaires
                    history={history}
                    dataGrid={concessionaires}
                    concessionaire={concessionaire}
                    permit={permit}
                    setConcessionaire={this.setConcessionaire}
                    setPermit={this.setPermit}
                    saveConcessionaire={this.saveConcessionaire}
                    savePermit={this.savePermit}
                    error={loadingManagedConcessionairesError}
                />
            </div>,
            <div id="ConcessionaireManagement" className="page-content wrapper">
                <ManageBlockingConcessionairePermits
                    history={history}
                    dataGrid={concessionaires}
                    concessionaire={concessionaire}
                    permit={permit}
                    setConcessionaire={this.setConcessionaire}
                    setPermit={this.setPermit}
                    saveConcessionaire={this.saveConcessionaire}
                    savePermit={this.savePermit}
                    error={loadingManagedConcessionairesError}
                />
            </div>,
        ];

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Concessionaire Management"
                        tabs={this.tabs}
                        breadcrumbs={crumbs}
                        navBack={navBackUrl}
                        selectedTabIndex={this.state.currentTab}
                    />
                </div>
                {contentComponents[this.state.currentTab]}
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        concessionaire: state.manageConcessionairesSet.managedConcessionaire,
        permit: state.manageConcessionairesSet.concessionairePermit,
        concessionaires: state.manageConcessionaires.managedConcessionaires,
        loadingManagedConcessionairesError:
            state.manageConcessionaires.loadingManagedConcessionairesError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadManagedConcessionaires: () => dispatch(loadManagedConcessionaires()),
        setConcessionaire: (concessionaire) =>
            dispatch(setConcessionaire(concessionaire)),
        setPermit: (permit) => dispatch(setPermit(permit)),
        saveConcessionaire: (concessionaires, add) =>
            dispatch(saveConcessionaire(concessionaires, add)),
        savePermit: (permit, add) => dispatch(savePermit(permit, add)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageConcessionairesContainer);
