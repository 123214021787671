/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Icon,
    Spacer,
    TextFieldStateless,
    TYPES,
} from 'sarsaparilla';
import get from 'lodash/get';
import { errorForConcessionaires } from '../../utilities/errorMessages';
import ActionErrorDisplay from '../ActionErrorDisplay';
import TextHighlight from '../tableUtil/TextHighlight';
import ColumnFilterTable from '../tableUtil/ColumnFilterTable';

export default function ManageBlockingConcessionairePermits({
    history,
    error,
    dataGrid,
    setConcessionaire,
}) {
    function navConcessionaireDetails(conc) {
        setConcessionaire(conc);
        history.push('/internal/account/concessionaire-details');
    }

    function getConcessionaireNameForGridDisplay(conc) {
        const result = conc.concessionaire_name || '';
        return result.replaceAll(conc.concessionaire_id, ' ');
    }

    const tableData = React.useMemo(() => {
        const temp = {};

        // Go through all our Concessionaires and identify:
        // Locations which have expired permits and NO active permits

        // First, assemble a list of all permits by location
        dataGrid.forEach((a) => {
            a.permits.forEach((b) => {
                const c = get(temp, b.location_id, []);
                const q = {
                    concessionaire_name: a.concessionaire_name,
                    concessionaire_type: a.concessionaire_type,
                    concessionaire: a,
                    location_name: b.location_name,
                };
                c.push(q);
                temp[b.location_id] = c;
            });
        });

        // Delete those with active permits
        dataGrid.forEach((a) => {
            a.activePermits.forEach((b) => {
                delete temp[b.location_id];
            });
        });

        const td = [];
        // Consolidate by concessionaire ID
        for (const [key, value] of Object.entries(temp)) {
            const newEntries = {};

            value.forEach((a) => {
                const currEntry = get(newEntries, a.concessionaire_id, {
                    location_id: key,
                    location_name: a.location_name,
                    concessionaire_name: a.concessionaire_name,
                    concessionaire: a.concessionaire,
                    blocking_permits: 0,
                });

                currEntry.blocking_permits += 1;

                newEntries[a.concessionaire_id] = currEntry;
            });

            Object.values(newEntries).forEach((a) => {
                td.push(a);
            });
        }

        return td;
    }, [dataGrid]);

    const columns = React.useMemo(() => {
        const renderConcessionaireName = (row, value, globalFilter) => {
            return (
                <Button
                    onClick={() => navConcessionaireDetails(row.original.concessionaire)}
                    appearance="link"
                >
                    <TextHighlight searchTerm={globalFilter || ''} body={value} />
                </Button>
            );
        };

        const renderActions = (row) => {
            return (
                <ButtonGroup>
                    <Button
                        onClick={() =>
                            navConcessionaireDetails(row.original.concessionaire)
                        }
                        appearance="link"
                    >
                        Details
                    </Button>
                </ButtonGroup>
            );
        };

        return [
            {
                Header: 'Location ID',
                accessor: 'location_id',
                disableFilters: true,
                width: 100,
                maxWidth: 200,
                minWidth: 100,
            },
            {
                Header: 'Location Name',
                accessor: 'location_name',
                disableFilters: true,
                width: 100,
                maxWidth: 200,
                minWidth: 100,
            },
            {
                Header: 'Concessionaire Name',
                id: 'concessionaire_name',
                disableFilters: true,
                accessor: getConcessionaireNameForGridDisplay,
                Cell: ({ row, value, state: { globalFilter } }) =>
                    renderConcessionaireName(row, value, globalFilter),
                width: 80,
                minWidth: 80,
            },
            {
                Header: 'Blocking Permits',
                accessor: 'blocking_permits',
                disableGlobalFilter: true,
                width: 70,
                maxWidth: 70,
                minWidth: 70,
            },
            {
                Header: 'Actions',
                id: 'actions',
                disableSortBy: true,
                disableFilters: true,
                disableGlobalFilter: true,
                Cell: ({ row }) => renderActions(row),
                width: 50,
                maxWidth: 90,
                minWidth: 50,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialFilterState = [{ id: 'active_permits', value: false }];

    const filterUI = React.useCallback(
        ({ setGlobalFilter, globalFilter }) => (
            <TextFieldStateless
                label="Search for..."
                isLabelVisible={false}
                placeholder="Search for..."
                onChange={({ target: { value } }) => setGlobalFilter(value)}
                handleClearButtonClick={() => setGlobalFilter('')}
                hasClearButton
                value={globalFilter || ''}
                iconElement={<Icon iconName="search" />}
                id="filter-text-search"
            />
        ),
        []
    );

    return (
        <div className="manage-concessionaires-wrapper">
            <ActionErrorDisplay
                error={error}
                errorStringMapping={errorForConcessionaires}
            />

            <Spacer size="sm" />

            <ColumnFilterTable
                className="manage-concessionaires-table"
                data={tableData || []}
                columns={columns}
                noDataText={`No blocking permits found.`}
                filtersComponent={filterUI}
                initialFilterState={initialFilterState}
                defaultSortId="location_id"
            />
        </div>
    );
}

ManageBlockingConcessionairePermits.propTypes = {
    history: TYPES.HISTORY,
    error: PropTypes.any,
    dataGrid: PropTypes.array,
    setConcessionaire: PropTypes.func,
};
