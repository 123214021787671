/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSanitizedRedirectUrl, IncompatibleBrowserNotice } from 'sarsaparilla';
import { HubLoginTermsOfService } from 'site-kit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
    checkLoggedIn,
    doMfaValidate,
    logIn,
    loginFailed,
    MFAValidateFactors,
    setEmail,
} from '../actions/login';
import { discoverFactor } from '../actions/mfa';
import ConfirmEmailAlert from '../components/ConfirmEmailAlert';
import { LoginForm } from '../components/login/loginForm';
import { MfaValidateForm } from '../components/login/MfaValidateForm';
import MfaEnrollmentWizardModal from '../components/MfaEnrollmentWizardModal';
import { MfaValidateWebauthnForm } from '../components/login/MfaValidateWebauthnForm';
import * as globals from '../constants/globals';
import Loading from './Loading';

const PANE_LOGIN = 'LOGIN';
const PANE_MFA_VALIDATE = 'MFA_VALIDATE';
const PANE_MFA_VALIDATE_BACKUP = 'MFA_VALIDATE_BACKUP';
const PANE_MFA_VALIDATE_WEBAUTHN = 'MFA_VALIDATE_WEBAUTHN';
const PANE_MFA_ENROLL = 'MFA_ENROLL';

const isLogin = (state) => state === PANE_LOGIN;
const isMfaValidate = (state) =>
    state === PANE_MFA_VALIDATE || state === PANE_MFA_VALIDATE_BACKUP;
const isMfaValidateWebauthn = (state) => state === PANE_MFA_VALIDATE_WEBAUTHN;
const isMfaEnroll = (state) => state === PANE_MFA_ENROLL;

function MfaLoginPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        attempts,
        mfa_validate_required,
        mfa_enroll_required,
        mfa_opt_out_allowed,
        data,
        error,
        factors,
    } = useSelector((state) => state.login) || {};

    const [mfaLoginState, setMfaLoginState] = React.useState(PANE_LOGIN);
    const [emailState, setEmailState] = React.useState('');
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [canSkipMfa, setCanSkipMfa] = React.useState(false);
    const [showConfirmEmailChange, setShowConfirmEmailChange] = React.useState(
        !isEmpty(props.query?.token) && isEmpty(error)
    );
    const existMFAToken = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY) !== null;

    const searchParams = new URLSearchParams(window.location.search);
    const newConfirmFlow = props.query?.redirect?.includes('confirm-email-change');

    React.useEffect(() => {
        if (!existMFAToken) {
            // no token means start over
            setMfaLoginState(PANE_LOGIN);
            return;
        }

        if (mfa_validate_required) {
            if (factors?.length > 0) {
                // 	Factors_Mock        Factors = 0
                // 	Factors_TOTP        Factors = 1
                // 	Factors_BackupCodes Factors = 2
                // 	Factors_Webauthn    Factors = 3
                switch (factors[0]) {
                    case 1: // TOTP
                        setMfaLoginState(PANE_MFA_VALIDATE);
                        return;
                    case 2: // BackupCodes
                        setMfaLoginState(PANE_MFA_VALIDATE_BACKUP);
                        return;
                    case 3: // Webauthn
                        setMfaLoginState(PANE_MFA_VALIDATE_WEBAUTHN);
                        return;
                    default:
                        // TODO: Not sure what to do here. shouldn't ever happen. return back to start page?
                        setMfaLoginState(PANE_LOGIN);
                        return;
                }
            }
        }

        if (mfa_enroll_required) {
            setMfaLoginState(PANE_MFA_ENROLL);
        }
    }, [factors, mfa_validate_required, mfaLoginState, existMFAToken]);

    if (mfa_opt_out_allowed && !canSkipMfa) {
        setCanSkipMfa(true);
    }

    if (!isMfaEnroll(mfaLoginState)) {
        dispatch(checkLoggedIn(null, history));
    }

    if (redirectUrl === '') {
        const redirect = getSanitizedRedirectUrl() ?? props.query?.redirect;
        if (props.query?.token) {
            setRedirectUrl(`${redirect}?token=${props.query?.token}`);
        } else {
            setRedirectUrl(redirect);
        }
    }

    if (searchParams.has('email')) {
        const email = searchParams.get('email');
        setEmailState(email);
        dispatch(setEmail(email));
        searchParams.delete('email');
    }

    if (data?.email) {
        setEmailState(data.email);
        dispatch(setEmail(data.email));
    }

    const setEmailHandler = (email) => {
        setEmailState(email);
        dispatch(setEmail(email));
    };

    const logInHandler = (email, password) => {
        const url = redirectUrl?.includes('/internal/account/confirm-email?')
            ? null
            : redirectUrl;
        dispatch(logIn(email, password, url, history));
    };

    const mfaValidateHandler = (code) => {
        const payload = {
            token: code,
            factor: discoverFactor(code),
        };
        dispatch(doMfaValidate(payload, redirectUrl, history));
    };

    const mfaValidateWebauthnHandler = (assertionResponse) => {
        const payload = {
            factor: MFAValidateFactors.Webauthn,
            assertion_response_raw: assertionResponse,
        };
        try {
            dispatch(doMfaValidate(payload, redirectUrl, history));
        } catch (e) {
            dispatch(loginFailed(e.message));
        }
    };

    const toggleShowConfirmEmailChange = () => {
        setShowConfirmEmailChange(!showConfirmEmailChange);
    };

    return (
        <div>
            <ConfirmEmailAlert
                newConfirmFlow={newConfirmFlow}
                show={showConfirmEmailChange}
                setShow={toggleShowConfirmEmailChange}
            />

            <div className="logged-out-container">
                <Loading />

                {isLogin(mfaLoginState) && (
                    <LoginForm
                        loginFn={logInHandler}
                        setEmailFn={setEmailHandler}
                        initialEmail={emailState}
                        errorMessage={error}
                        attempts={attempts}
                        showConfirmEmailChange={showConfirmEmailChange}
                    />
                )}

                {isMfaValidate(mfaLoginState) && (
                    <MfaValidateForm
                        submitFn={mfaValidateHandler}
                        error={error}
                        showStartOver
                    />
                )}

                {isMfaValidateWebauthn(mfaLoginState) && (
                    <MfaValidateWebauthnForm
                        submitFn={mfaValidateWebauthnHandler}
                        codeSubmitFn={mfaValidateHandler}
                        error={error}
                        showStartOver
                    />
                )}

                {isMfaEnroll(mfaLoginState) && (
                    <MfaEnrollmentWizardModal canSkip={canSkipMfa} isEmbedded />
                )}
            </div>
            <HubLoginTermsOfService />
            <IncompatibleBrowserNotice />
        </div>
    );
}

MfaLoginPage.propTypes = {
    query: PropTypes.shape({
        redirect: PropTypes.string,
        token: PropTypes.string,
    }),
};

export default MfaLoginPage;
