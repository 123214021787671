/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import { InternalSecurityUtils } from '../../index';
import * as errors from '../constants/errors';
import * as globals from '../constants/globals';
import * as types from '../constants/types';
import { setAuthStorage } from '../utilities/internalSecurityUtil';
import { doneLoading, startLoading } from './loading';
import {
    checkLoggedIn,
    forceLogOut,
    JSONContentType,
    logOut,
    makeAuthorizedRequest,
    navigateLogin,
} from './login';
import { setSelectedLocationRole } from './selectedLocationRole';
import { CONFIRM_EMAIL_CHANGE_TOKEN } from '../constants/globals';

export const confirmEmailFailed = (errorMessage) => {
    return {
        type: types.CONFIRM_EMAIL_FAILED,
        error: errorMessage,
    };
};

export const confirmEmailSuccess = (token, user) => {
    return {
        type: types.CONFIRM_EMAIL_SUCCESS,
        token,
        user,
    };
};

export const cancelEmailChangeSuccessful = () => {
    return {
        type: types.CANCEL_EMAIL_CHANGE_SUCCESSFUL,
    };
};

export const cancelEmailChangeFailed = (error) => {
    return {
        type: types.CANCEL_EMAIL_CHANGE_FAILED,
        error,
    };
};

export const cancelEmailChangeReset = () => {
    return {
        type: types.CANCEL_EMAIL_CHANGE_RESET,
    };
};

export const confirmEmailChangeFailed = () => {
    return {
        type: types.CONFIRM_EMAIL_CHANGE_FAILED,
    };
};

export const confirmEmailChangeSuccess = () => {
    return { type: types.CONFIRM_EMAIL_CHANGE_SUCCESS };
};

export const confirmEmail = (token, history) => {
    return async (dispatch) => {
        dispatch(startLoading());

        try {
            const headers = new Headers();
            headers.append('Content-Type', JSONContentType);

            const body = { token };
            const response = await axios.post(`${globals.API_URL}/confirm-email`, body, {
                headers,
                withCredentials: true,
            });

            const rData = response?.data;
            if (response.status >= 200 && response.status < 300) {
                if (rData.user.must_set_password) {
                    setAuthStorage(rData);
                    dispatch(setSelectedLocationRole(null, null, history));
                    dispatch(confirmEmailSuccess(rData.access_token, rData.user));
                    dispatch(checkLoggedIn('/internal/account/confirm-email', history));
                } else {
                    dispatch(logOut());
                    navigateLogin('/internal/account/confirm-email');
                    dispatch(confirmEmailSuccess(null, rData.user));
                }
            } else {
                throw new Error(rData.error);
            }
        } catch (error) {
            dispatch(confirmEmailFailed(error));
        }

        dispatch(doneLoading());
    };
};

export const confirmEmailChange = (token) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const headers = { Authorization: InternalSecurityUtils.getAuthHeader() };
        const query = new URLSearchParams({ token });

        const resp = await axios.post(
            `${globals.API_URL}/confirm-email-change?${query.toString()}`,
            {},
            { headers }
        );
        if (resp.status === 200 || resp.status === 204) {
            dispatch(confirmEmailChangeSuccess());
        } else {
            dispatch(confirmEmailChangeFailed());
            dispatch(forceLogOut(errors.EMAIL_CHANGE_NOT_CONFIRMED));
        }
    } catch (e) {
        dispatch(confirmEmailChangeFailed());
        dispatch(forceLogOut(errors.EMAIL_CHANGE_NOT_CONFIRMED));
    } finally {
        dispatch(doneLoading());
        const confirmEmailChangeToken = localStorage.getItem(CONFIRM_EMAIL_CHANGE_TOKEN);
        if (confirmEmailChangeToken) {
            localStorage.removeItem(CONFIRM_EMAIL_CHANGE_TOKEN);
        }
    }
};

export const cancelEmailChange = (userId) => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const url = `${globals.API_URL}/user/${userId}/email-change`;
            await makeAuthorizedRequest(url, 'DELETE', dispatch);
            dispatch(cancelEmailChangeSuccessful());
        } catch (error) {
            dispatch(cancelEmailChangeFailed(error));
        }
        dispatch(doneLoading());
    };
};
