/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { FlexRow, Label, Stack, Text } from 'sarsaparilla';

export type MfaStatusProps = {
    userMFAStatus: string;
    key: string;
    additionalInfo?: {
        label: string;
        info: string;
    };
};

export default function MfaStatus(props: MfaStatusProps) {
    return (
        <FlexRow className="space-left">
            <Stack>
                <div key={props.key} className="mfa-enrollment-status">
                    {props.userMFAStatus}
                </div>
                {props.additionalInfo && (
                    <div className={'mt-0'}>
                        <Label htmlFor={`mfa-status-additional-info-${props.key}`}>
                            {props.additionalInfo.label}
                        </Label>
                        <Text className={'mt-1'}>{props.additionalInfo.info}</Text>
                    </div>
                )}
            </Stack>
        </FlexRow>
    );
}
