/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import * as types from '../constants/types';
import { makeAuthorizedRequest } from './login';
import * as globals from '../constants/globals';
import {
    CAMPGROUND,
    PERMIT,
    TICKET_FACILITY,
    TIMED_ENTRY,
    VEHICLE_PERMIT,
} from '../utilities/locationConstants';
import { doneLoading, startLoading } from './loading';

type UpdateFacilityNoteAction = {
    type: string;
    id: string | undefined;
    methodType: string;
    note: any;
};

type AddFacilityNoteErrorAction = {
    type: string;
    id: string | undefined;
    mandatory: boolean;
};

type ClearFacilityNoteErrorsAction = {
    type: string;
};

type SetFacilityPOCSuccessAction = {
    type: string;
    primaryUser: types.User;
    secondaryUser: types.User;
    additionalPOC: any;
};

type SetFacilityPOCErrorAction = {
    type: string;
    error: string;
};

type ClearFacilityPOCErrorAction = {
    type: string;
};

type SetShouldOpenModalAction = {
    type: string;
    shouldOpen: boolean;
};

type SetModalFetchStatusAction = {
    type: string;
    key: string;
    result: string[];
    success: boolean;
};

type AdditionalPOC = {
    name: string;
    role: string;
    email: string;
    phone: string;
};

export const fetchFacilityInfo = (
    location: types.Location,
    spin: boolean,
    useCache: boolean = true
) => {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        if (useCache) {
            const state = getState();
            const existingInfo = state.facilityInfo?.cache?.[location.location_id];

            if (existingInfo) {
                dispatch({
                    type: types.FETCH_FACILITY_INFO_SUCCESS,
                    info: existingInfo,
                    location_id: location.location_id,
                });
                return;
            }
        }

        if (spin) {
            dispatch(startLoading('Fetching Facility Information...'));
        }

        try {
            const url: string = `${globals.API_URL}/location/${location.location_type}/${location.location_id}/info`;
            const notesUrl: string = `${globals.API_INVENTORY_URL}/location-notes/location/${location.location_id}`;

            const [response, notes] = await Promise.all([
                makeAuthorizedRequest(url, 'GET', dispatch),
                makeAuthorizedRequest(notesUrl, 'GET', dispatch),
            ]);

            const info = response.location_info || {};

            info.location_notes = notes.location_notes;

            const action = {
                type: types.FETCH_FACILITY_INFO_SUCCESS,
                info,
                location_id: location.location_id,
            };
            dispatch(action);
        } catch (error) {
            dispatch({
                type: types.FETCH_FACILITY_INFO_ERROR,
                location,
                error,
            });
        } finally {
            dispatch(doneLoading());
        }
    };
};

const deleteLocationInfoCache = (locationId: string) => {
    return {
        type: types.DELETE_FACILITY_INFO_CACHE,
        location_id: locationId,
    };
};

const updateFacilityNote = (
    note: types.LocationNote,
    methodType: string,
    id: string | undefined
): UpdateFacilityNoteAction => {
    return {
        type: types.UPDATE_FACILITY_NOTE_SUCCESS,
        note,
        methodType,
        id,
    };
};

const addFacilityNoteError = (
    id: string | undefined,
    mandatory: boolean
): AddFacilityNoteErrorAction => {
    return {
        type: types.ADD_FACILITY_NOTE_ERROR,
        id,
        mandatory,
    };
};

export const clearFacilityNoteErrors = (): ClearFacilityNoteErrorsAction => {
    return {
        type: types.CLEAR_FACILITY_NOTE_ERRORS,
    };
};

const submitFacilityNoteInventoryService = (
    dispatch: Function,
    location: types.Location,
    note: types.LocationNote,
    methodType: string
) => {
    let url: string = `${globals.API_INVENTORY_URL}/location-notes`;
    let body: string = '';

    if (methodType === 'DELETE') {
        url += `/${note.note_id}`;
    } else {
        const payload = {
            ...note,
            location_id: location.location_id,
            note_id: note.note_id,
        };
        delete payload.submissionId;
        body = JSON.stringify({ location_note: payload });
    }

    return makeAuthorizedRequest(url, methodType, dispatch, body); //returning a promise to avoid multiple awaits.
};

const submitFacilityNote = (
    location: types.Location,
    note: types.LocationNote,
    methodType: string
) => {
    const submissionId = note.submissionId;

    return async (dispatch: Function): Promise<void> => {
        try {
            const response = await submitFacilityNoteInventoryService(
                dispatch,
                location,
                note,
                methodType
            );

            const update = response.location_note || note;
            dispatch(updateFacilityNote(update, methodType, submissionId));
            dispatch(deleteLocationInfoCache(location.location_id));
        } catch {
            dispatch(addFacilityNoteError(submissionId, note.mandatory));
        }
    };
};

export const submitFacilityNotes = (
    location: types.Location,
    update: types.LocationNote[],
    remove: types.LocationNote[],
    create: types.LocationNote[]
) => {
    return async (dispatch: Function): Promise<void> => {
        dispatch(startLoading('Submitting Facility Notes...'));

        try {
            const createRequests: Promise<void>[] = create.map(
                (note: types.LocationNote) =>
                    submitFacilityNote(location, note, 'POST')(dispatch)
            );
            const updateRequests: Promise<void>[] = update.map(
                (note: types.LocationNote) =>
                    submitFacilityNote(location, note, 'PUT')(dispatch)
            );
            const deleteRequests: Promise<void>[] = remove.map(
                (note: types.LocationNote) =>
                    submitFacilityNote(location, note, 'DELETE')(dispatch)
            );

            await Promise.all(
                createRequests.concat(updateRequests).concat(deleteRequests)
            );
        } catch {
            // Each request handles its own error
        }
        dispatch(deleteLocationInfoCache(location.location_id));
        dispatch(doneLoading());
    };
};

const setFacilityPOCSuccess = (
    primaryUser: types.User,
    secondaryUser: types.User,
    additionalPOC: AdditionalPOC
): SetFacilityPOCSuccessAction => {
    return {
        type: types.UPDATE_FACILITY_POC,
        primaryUser,
        secondaryUser,
        additionalPOC,
    };
};

const setFacilityPOCError = (error: Error): SetFacilityPOCErrorAction => {
    return {
        type: types.SET_FACILITY_POC_ERROR,
        error: error.message,
    };
};

export const clearFacilityPOCError = (): ClearFacilityPOCErrorAction => {
    return {
        type: types.CLEAR_FACILITY_POC_ERROR,
    };
};

export const setShouldOpenModal = (shouldOpen: boolean): SetShouldOpenModalAction => {
    return {
        type: types.SET_SHOULD_OPEN_MODAL,
        shouldOpen,
    };
};

export const submitFacilityPOC = (
    location: types.Location,
    primaryUser: types.User,
    secondaryUser: types.User,
    additionalPOC: AdditionalPOC
) => {
    return async (dispatch: Function): Promise<void> => {
        dispatch(startLoading('Submitting POC Details...'));

        try {
            const pocRequest: string = JSON.stringify({
                location_id: location.location_id,
                location_type: location.location_type,
                primary_user_id: primaryUser.user_id,
                secondary_user_id: secondaryUser.user_id,
                additional_name: additionalPOC.name,
                additional_role: additionalPOC.role,
                additional_email: additionalPOC.email,
                additional_phone: additionalPOC.phone,
            });

            const url: string = `${globals.API_URL}/location/${location.location_type}/${location.location_id}/poc`;
            await makeAuthorizedRequest(url, 'PUT', dispatch, pocRequest);

            dispatch(setFacilityPOCSuccess(primaryUser, secondaryUser, additionalPOC));
            dispatch(deleteLocationInfoCache(location.location_id));
        } catch (error: any) {
            dispatch(setFacilityPOCError(error));
        }

        dispatch(doneLoading());
    };
};

const setModalFetchStatus = (
    key: string,
    result: string[],
    success: boolean
): SetModalFetchStatusAction => {
    return {
        type: types.SET_FACILITY_MODAL_RESULT,
        key,
        result,
        success,
    };
};

export const typedEnvironmentMap = {
    [CAMPGROUND]: {
        api: process.env.API,
        path: 'camps/internal/campgrounds',
        data: {
            additional: 'campground.notices',
            seasons: 'seasons',
        },
    },
    [PERMIT]: {
        api: process.env.API,
        path: 'permits',
        data: {
            additional: 'payload.description.need_to_know_permit',
        },
    },
    [TICKET_FACILITY]: {
        api: process.env.API,
        path: 'ticket/facility',
        data: {
            additional: 'notes',
        },
    },
    [TIMED_ENTRY]: {
        api: process.env.API,
        path: 'timedentrycontent/facility',
        data: {
            additional: 'notes',
        },
    },
    [VEHICLE_PERMIT]: {
        api: process.env.API,
        path: 'vehiclepermits',
        data: {
            additional: 'payload.description.need_to_know_permit',
        },
    },
};

const fetchModalNotes = (location: types.Location) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            const response = await makeAuthorizedRequest(
                `${globals.API_INVENTORY_URL}/location-notes/location/${location.location_id}`,
                'GET',
                dispatch
            );

            const notes = get(response, 'location_notes', []);

            dispatch(
                setModalFetchStatus(
                    'mandatory',
                    notes.filter((note: types.LocationNote) => note.mandatory),
                    true
                )
            );
            dispatch(
                setModalFetchStatus(
                    'optional',
                    notes.filter((note: types.LocationNote) => !note.mandatory),
                    true
                )
            );
        } catch (error: any) {
            dispatch(setModalFetchStatus('mandatory', [error], false));
            dispatch(setModalFetchStatus('optional', [error], false));
        }
    };
};

const fetchModalInventoryInfo = (location: types.Location) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            const map = typedEnvironmentMap[location.location_type];

            const url: string = [map.api, map.path, location.location_id].join('/');
            const response = await makeAuthorizedRequest(url, 'GET', dispatch);

            const notices = get(response, map.data.additional, []);

            dispatch(setModalFetchStatus('additional', notices, true));
        } catch (error: any) {
            dispatch(setModalFetchStatus('additional', [error], false));
        }
    };
};

const fetchModalSeasonInfo = (location: types.Location) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            const map: any = typedEnvironmentMap[location.location_type];

            const url: string = [map.api, map.path, location.location_id, 'seasons'].join(
                '/'
            );
            const response = await makeAuthorizedRequest(url, 'GET', dispatch);

            const seasons = get(response, map.data.seasons, []);

            dispatch(setModalFetchStatus('seasons', seasons, true));
        } catch (error: any) {
            dispatch(setModalFetchStatus('seasons', [error], false));
        }
    };
};

const fetchModalBannerInfo = (location: types.Location) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            const url: string = `/api/communication/external/alert`;
            const query: string = `location_id=${encodeURIComponent(location.location_id)}&location_type=${encodeURIComponent(
                location.location_type
            )}`;
            const response = await makeAuthorizedRequest(
                `${url}?${query}`,
                'GET',
                dispatch
            );

            const alerts = get(response, 'alerts', []);

            dispatch(setModalFetchStatus('external', alerts, true));
        } catch (error: any) {
            dispatch(setModalFetchStatus('external', [error], false));
        }
    };
};

export const runFacilityModalFetches = (location: types.Location) => {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        try {
            const calls: Promise<void>[] = [
                fetchFacilityInfo(location, true)(dispatch, getState),
                fetchModalNotes(location)(dispatch),
                fetchModalInventoryInfo(location)(dispatch),
                fetchModalBannerInfo(location)(dispatch),
            ];

            if (location.location_type === CAMPGROUND) {
                calls.push(fetchModalSeasonInfo(location)(dispatch));
            }

            await Promise.all(calls);
        } catch {
            // Let each method handle it's own shit
        }
    };
};

export const setLocationFlag = (
    location: types.Location,
    flag: string,
    value: string
) => {
    return async (dispatch: Function): Promise<void> => {
        try {
            const inventoryURL: string = `${globals.API_INVENTORY_URL}/location/${location.location_type}/${location.location_id}/flags`;
            const values: any = {};
            values[flag] = value;
            const body = {
                values,
            };

            const result = await makeAuthorizedRequest(
                inventoryURL,
                'PUT',
                dispatch,
                body
            );
            dispatch({
                type: types.SET_FACILITY_FLAG_SUCCESS,
                payload: result.collects_mobile_fees,
            });
            dispatch(deleteLocationInfoCache(location.location_id));
        } catch (error) {
            dispatch({
                type: types.SET_FACILITY_FLAG_ERROR,
                error,
            });
        }
    };
};
