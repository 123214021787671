/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './HorizontalNav.scss';

import * as React from 'react';
import { NavLink, useLocation, type NavLinkProps } from 'react-router-dom-v5-compat';
import cx from 'classnames';

interface HorizontalNavProps extends React.HTMLAttributes<HTMLDivElement> {
    'aria-label': string;
}

export function HorizontalNav({ 'aria-label': ariaLabel, children }: HorizontalNavProps) {
    return (
        <div data-horizontal-nav>
            <nav aria-label={ariaLabel}>
                <ul>{children}</ul>
            </nav>
        </div>
    );
}

export function HorizontalNavLink({ children, ...rest }: NavLinkProps) {
    const ref = React.useRef<HTMLAnchorElement>(null);

    const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const link = ref.current;

        if (link) {
            const isActive = link.classList.contains('active');
            if (isActive) {
                event.preventDefault();
            }
        }
    };

    return (
        <li data-horizontal-nav-link>
            <NavLink ref={ref} onClick={onClick} {...rest}>
                {children}
            </NavLink>
        </li>
    );
}

export function HorizontalNavLinkExternal({
    href,
    children,
    className,
    ...rest
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    const location = useLocation();

    return (
        <li data-horizontal-nav-link>
            <a
                href={href}
                className={cx(className, {
                    active: href === location.pathname,
                })}
                {...rest}
            >
                {children}
            </a>
        </li>
    );
}
