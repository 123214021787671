/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
import {
    StyledModal,
    Text,
    Spacer,
    EmailField,
    ModalActions,
    ButtonGroup,
    Button,
} from 'sarsaparilla';

type NewEmailAddressModalProps = {
    isOpen: boolean;
    email: string;
    currentEmail: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onConfirm: () => void;
    onClose: () => void;
};

export default function NewEmailAddressModal({
    isOpen,
    email,
    currentEmail,
    onChange,
    onConfirm,
    onClose,
}: NewEmailAddressModalProps) {
    return (
        <StyledModal
            size="md"
            isOpen={isOpen}
            heading="New Email Address"
            onRequestClose={onClose}
        >
            <div className="modal-mfa-text-body">
                <Text>Enter the new email address to be used for this account.</Text>
                <Spacer size="sm" />
                <Text>
                    1. Please double-check to be sure the email address is correct.
                </Text>
                <Spacer size="sm" />
                <Text>
                    2. Click <b>Submit Change</b>.
                </Text>
                <Spacer size="sm" />
                <Text>
                    We will send a confirmation message to the new email address. The
                    account holder must click the link in that email in order to complete
                    the change.
                </Text>
                <Spacer size="sm" />
                <Text>
                    We will also send a notification to the old email address, to let the
                    account holder know about the requested change.
                </Text>
                <Spacer size="sm" />
                <Text>
                    <b>Current address: </b>
                    {currentEmail}
                </Text>
                <Spacer size="sm" />
                <EmailField
                    label="New Email Address"
                    value={email}
                    onChange={onChange}
                    isRequired
                    id="updateUserEmail"
                />
            </div>
            <ModalActions>
                <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                    <Button appearance="tertiary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        isDisabled={email.length <= 1 || email === currentEmail}
                    >
                        Submit Change
                    </Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}
