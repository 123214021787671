/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button, FlexRow, Heading, Stack, Text, TextField } from 'sarsaparilla';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getCredential } from '../../utilities/webauthn';
import { beginRegister, clearSession, finishRegister } from '../../actions/mfa';
import * as types from '../../constants/types';

type MfaEnrollmentPhysicalKeyProps = {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isLoading: boolean;
    onOptOut: () => void;
    startOver: () => void;
    onDone: (loginResponse: any) => void;
    setErrorMessage: (message: string) => void;
};

function MfaEnrollmentPhysicalKey({
    canSkip,
    hideStartOverButton,
    isLoading,
    onOptOut,
    startOver,
    onDone,
    setErrorMessage,
}: MfaEnrollmentPhysicalKeyProps) {
    const [nickname, setNickname] = React.useState<string>('Security key');

    const reduxDispatch = useDispatch();

    const handleError = () => {
        setErrorMessage(
            'There was an error while registering the physical security device'
        );
    };

    const doRegister = async (credentials: any) => {
        const creds = {
            authenticatorAttachment: credentials.authenticatorAttachment,
            id: credentials.id,
            rawId: credentials.rawId,
            type: credentials.type,
            response: {
                attestationObject: new Uint8Array(credentials.response.attestationObject),
                clientDataJSON: new Uint8Array(credentials.response.clientDataJSON),
                type: credentials.response.type,
                transports: credentials.response.getTransports(),
                authenticatorData: new Uint8Array(
                    credentials.response.getAuthenticatorData()
                ),
                publicKey: new Uint8Array(credentials.response.getPublicKey()),
                PublicKeyAlgorithm: credentials.response.getPublicKeyAlgorithm(),
            },
        };

        return await finishRegister(creds, nickname);
    };

    const onRegister = async () => {
        let regResponse: { options: PublicKeyCredentialCreationOptions } = {
            options: {} as PublicKeyCredentialCreationOptions,
        };
        try {
            regResponse = await beginRegister();
        } catch (e) {
            clearSession();
            handleError();
            throw e;
        }

        let credsResponse;
        try {
            credsResponse = await getCredential(regResponse?.options);
        } catch (e) {
            handleError();
            throw e;
        }

        if (!credsResponse) {
            handleError();
        }

        try {
            const registerResponse = await doRegister(credsResponse);
            onDone(registerResponse);
        } catch (e) {
            reduxDispatch({ type: types.LOGGED_OUT });
            handleError();
            throw e;
        }
    };

    return (
        <>
            <Heading
                headingLevel={1}
                appearance="h4"
                headingAlign="center"
                className="mb-3"
            >
                Connect Your Security Key
            </Heading>

            <FlexRow className={'mb-3 center'}>
                Use your physical security key to add an additional layer of protection to
                your account to preven unauthorized access.
                <Button appearance={'link'}>Learn more about security keys.</Button>
            </FlexRow>

            <FlexRow className={'mb-3'}>
                <Stack>
                    <Text fontWeight={'bold'}>1. Give it a nickname</Text>
                    <Text className={'ml-3'}>
                        If you have more than one security key, this will help you
                        remember which to use on this site.
                    </Text>
                    <TextField
                        className={'ml-3 mt-2'}
                        label={'Device nickname'}
                        id={'nickname'}
                        onChange={(e) => setNickname(e.target.value)}
                    />
                </Stack>
            </FlexRow>

            <FlexRow className={'mb-3'}>
                <Stack>
                    <Text fontWeight={'bold'}>
                        2. Insert a security key into your device
                    </Text>
                </Stack>
            </FlexRow>

            <FlexRow className={'mb-3'}>
                <Stack>
                    <Text fontWeight={'bold'}>3. Set up your security key</Text>
                    <Text className={'ml-3'}>
                        Click the &quot;Set Up Security Key&quot; button below and follow
                        your browser&apos;s instructions.
                    </Text>
                </Stack>
            </FlexRow>

            <FlexRow className={'mb-3 center'}>
                <Button onClick={onRegister} size={'lg'} className={'mr-2'}>
                    Set Up Security Key
                </Button>
            </FlexRow>

            {canSkip && (
                <div className="mt-3 center">
                    <Button
                        appearance="link"
                        onClick={onOptOut}
                        isDisabled={isLoading}
                        gaTrackingId="261677614215"
                    >
                        Skip for now
                    </Button>
                </div>
            )}

            {!hideStartOverButton && (
                <Text tag="div" className="center mt-5">
                    <Button
                        href="/internal/account/login"
                        appearance="link"
                        onClick={startOver}
                        rel="noreferrer"
                        gaTrackingId="261677614215"
                    >
                        Start over
                    </Button>
                    <span>&nbsp;</span>
                    <span>and return to login page</span>
                </Text>
            )}
        </>
    );
}

export default MfaEnrollmentPhysicalKey;
