/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    ModalActions,
    RadioGroupWithValidation,
    StyledModal,
    Text,
} from 'sarsaparilla';
import RadioWithLegendObj from 'ui-internal-camping/src/components/common/RadioWithLegendObj';

const propTypes = {
    isModalOpened: PropTypes.bool,
    toggleIsModalOpened: PropTypes.func,
    addresses: PropTypes.array,
    selectedAddressIndex: PropTypes.number,
    setSelectedAddressIndex: PropTypes.func,
    onConfirmAddress: PropTypes.func,
};

export default function CooperatorAddressConfirmationModal({
    isModalOpened,
    toggleIsModalOpened,
    addresses,
    selectedAddressIndex,
    setSelectedAddressIndex,
    onConfirmAddress,
}) {
    return (
        <StyledModal
            size="md"
            isOpen={isModalOpened}
            heading="Validate Address"
            onRequestClose={toggleIsModalOpened}
        >
            <Text>
                Please select an address among those provided below.
                <br />
            </Text>
            <RadioGroupWithValidation
                id="addresses"
                isLabelVisible={false}
                label=""
                name="addresses"
                onChange={setSelectedAddressIndex}
                value={`${selectedAddressIndex}`}
                isRequired
                role="radiogroup"
            >
                {addresses.map((address, index) => (
                    <RadioWithLegendObj
                        id={`address-${index}`}
                        key={index}
                        label={`Use ${index === 0 ? 'Original' : 'Suggested'} Address`}
                        legend={
                            <div id="address" className="address-detail">
                                {address.address1 && <div>{address.address1}</div>}
                                {address.address2 && <div>{address.address2}</div>}
                                {address.city && address.state && (
                                    <div>
                                        {address.city}, {address.state}
                                    </div>
                                )}
                                {address.zip_code && <div>{address.zip_code}</div>}
                            </div>
                        }
                        value={`${index}`}
                    />
                ))}
            </RadioGroupWithValidation>
            <ModalActions>
                <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                    <Button appearance="tertiary" onClick={toggleIsModalOpened}>
                        Cancel
                    </Button>
                    <Button onClick={onConfirmAddress}>Confirm</Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

CooperatorAddressConfirmationModal.propTypes = propTypes;
